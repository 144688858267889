.business-wrapper {
  .responsive-container {
    transform: rotate(180deg);
  }

  .business__subtitle {
    margin-top: 2.5rem;
  }

  .business__block-tiles {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 2.5rem;
    gap: 30px;

    .business__block-tiles__box {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .button-box {
        border-radius: 6px;
        background: #3095f2;
        padding: 10px 24px;
        min-width: 292px;
        white-space: nowrap;
        max-width: 350px;
      }

      .content-box {
        border-radius: 6px;
        background: #fff;
        padding: 30px;
        min-height: 200px;
        max-height: 200px;

        &__subtitle {
          margin-top: 1.5rem;
        }

        .medium16px {
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .business__block-tiles {
      .business__block-tiles__box {
        .content-box {
          min-height: 230px;
          max-height: 230px;
          h3 br {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .business__block-tiles {
      .business__block-tiles__box {
        .content-box {
          min-height: 250px;
          max-height: 250px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .business__subtitle {
      margin-top: 2.5rem;
    }

    .business__block-tiles {
      grid-template-columns: auto;
      margin-top: 4rem;
      gap: 2.5rem;

      .business__block-tiles__box {
        gap: 2.5rem;

        .button-box {
          width: 100%;
          max-width: 100%;
        }

        .content-box {
          min-height: auto;
          max-height: auto;
        }
      }
    }
  }
}

.footer-wrapper {
  padding: 0;

  .footer-content_box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 50px;

    .logoBox {
      font-family: 'Avenir-Roman-12', sans-serif;
      min-width: 224px;
      .logo_text {
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--footer-link-color);
      }
      .badges {
        margin-top: 24px;
        .badges_group {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          gap: 40px;
          justify-content: center;
          & > a {
            display: flex;
            justify-content: start;
            grid-column: span 2;
            &:nth-child(6) {
              margin-left: 14px;
            }
            &:nth-child(4) {
              // justify-content: center;
              margin-left: -12px;
            }
          }

          .clutch_badge {
            & > div > img {
              width: 74.55px;
              height: 80px;
            }
          }
          .goodfirm_badge {
            & > div > img {
              height: 80px;
              width: 92px;
            }
          }
          .appdevelopment_badge {
            & > div > img {
              width: 90px;
              height: 90px;
            }
          }
          .top_rated_mobile_badge {
            & > div > img {
              width: 84px;
              height: 84px;
            }
          }
          .top_firms_badge {
            & > div > img {
              width: 111.7px;
              height: 112px;
            }
          }
          .top_lviv_badge {
            & > div > img {
              width: 58.7px;
              height: 82px;
            }
          }
          .app_futura_badge {
            & > div > img {
              width: 105px;
              height: 95px;
            }
          }
          .revenuecat_badge {
            & > div > img {
              width: 90px;
              height: 90px;
            }
          }
        }
      }
    }

    .sectionBox {
      .sectionBox_title {
        font-family: 'Avenir-Heavy-05', sans-serif;
        font-weight: inherit;
        font-size: 1.125rem;
        color: var(--title-text-color);
      }
      .navLinksBox {
        align-items: center;

        .navLink {
          font-family: 'Avenir-Medium-09', sans-serif;
          padding: 24px 0 0 0;
          line-height: 140%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: var(--title-text-color);
          &:hover {
            color: #3095f2;
          }
        }

        .policyLine {
          display: none;

          @media screen and (max-width: 765px) {
            display: block;
          }
        }
      }

      .email-text {
        text-align: start;
        font-family: 'Avenir-Medium-09', sans-serif;
        font-size: 18px;
        line-height: 140%;
        font-weight: 500;
        margin-top: 24px;
        line-height: 34px;

        a {
          color: #3095f2;
          text-decoration: underline;
        }
      }

      .addressBox {
        margin-top: 24px;

        .title {
          font-family: 'Avenir-Heavy-05', sans-serif;
          font-size: 20px;
          line-height: 34px;
          color: var(--title-text-color);
        }

        .address {
          font-family: 'Avenir-Medium-09', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
          color: #7c7c87;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      gap: 60px 40px;
      br {
        display: none;
      }
      .logoBox {
        .badges {
          .badges_group {
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
            gap: 1.5rem;
            justify-content: center;
            & > a {
              display: flex;
              justify-content: center;
              grid-column: span 1;
              &:nth-child(6) {
                margin-left: 0;
              }
              &:nth-child(4) {
                margin-left: 0;
              }
            }
            .clutch_badge {
              & > div > img {
                width: 74.55px;
                height: 80px;
              }
            }
            .goodfirm_badge {
              & > div > img {
                height: 80px;
                width: 92px;
              }
            }
            .appdevelopment_badge {
              & > div > img {
                width: 90px;
                height: 90px;
              }
            }
            .top_rated_mobile_badge {
              & > div > img {
                width: 84px;
                height: 84px;
              }
            }
            .top_firms_badge {
              & > div > img {
                width: 111.7px;
                height: 112px;
              }
            }
            .top_lviv_badge {
              & > div > img {
                width: 58.7px;
                height: 82px;
              }
            }
            .app_futura_badge {
              & > div > img {
                width: 105px;
                height: 95px;
              }
            }
            .revenuecat_badge {
              & > div > img {
                width: 90px;
                height: 90px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 765px) {
      grid-template-columns: 1fr;
      .logoBox {
        min-width: auto;

        margin-right: auto;
        .logo_text {
          & > span > br {
            display: none;
          }
        }
        .badges {
          .badges_group {
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
            gap: 1.5rem;
            justify-content: center;
            & > a {
              display: flex;
              justify-content: center;
              grid-column: span 1;
            }
            .clutch_badge {
              & > div > img {
                width: 74.55px;
                height: 80px;
              }
            }
            .goodfirm_badge {
              & > div > img {
                height: 80px;
                width: 92px;
              }
            }
            .appdevelopment_badge {
              & > div > img {
                width: 90px;
                height: 90px;
              }
            }
            .top_rated_mobile_badge {
              & > div > img {
                width: 84px;
                height: 84px;
              }
            }
            .top_firms_badge {
              & > div > img {
                width: 111.7px;
                height: 112px;
              }
            }
            .top_lviv_badge {
              & > div > img {
                width: 58.7px;
                height: 82px;
              }
            }
            .app_futura_badge {
              & > div > img {
                width: 105px;
                height: 95px;
              }
            }
            .revenuecat_badge {
              & > div > img {
                width: 90px;
                height: 90px;
              }
            }
          }
        }
      }
      .sectionBox {
        .navLinksBox {
          margin-top: 24px;
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          .navLink {
            padding: 0;
          }
        }

        .navLinksBoxItem {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
    @media screen and (max-width: 375px) {
      .logoBox {
        .badges {
          .badges_group {
            gap: 0.5rem;
          }
        }
      }
    }
    @media screen and (max-width: 340px) {
      .logoBox {
        .badges {
          .badges_group {
            gap: 0rem;
          }
        }
      }
    }
  }

  .footer-socialBox {
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .allRights {
      font-family: 'Product Sans Regular', sans-serif;
      font-weight: 400;
      line-height: 140%;
      font-size: 18px;
      color: var(--footer-link-color);
    }

    @media screen and (max-width: 765px) {
      flex-direction: column-reverse;
      padding-bottom: 0;

      .socialLinks {
        .linkBox:last-child {
          margin-right: 0;
        }
      }

      .allRights {
        font-size: 14px;
        margin: 2.5rem auto;
      }
    }
    @media screen and (max-width: 320px) {
      .allRights {
        text-align: center;
      }
      .socialLinks {
        display: flex;

        .linkBox {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .policyLine {
    display: block;
    background-color: rgba(0, 0, 0, 0.14);
    height: 16px;
    width: 1px;
  }

  .footer-policyLinks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5rem 0 2rem;
    gap: 1rem;

    .privatePolicyWrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .privatePolicyBox {
      display: flex;
      align-items: center;

      & > a {
        font-family: 'Product Sans Regular', sans-serif;
        letter-spacing: normal;
        font-weight: 400;
        font-size: 12px;
        padding: 0;
        line-height: 140%;
        color: var(--footer-link-color);
        opacity: 0.6;
        line-height: 140%;

        @media screen and (min-width: 1500px) {
          font-size: 13px;
        }
      }
    }
  }

  @media screen and (max-width: 1430px) {
    .footer-policyLinks {
      justify-content: center;
      padding: 1.5rem 0.5rem 2rem;
    }
  }
}

.wrapper-dark .policyLine {
  background-color: rgba(255, 255, 255, 0.3);
}

.socialLinks {
  display: flex;

  .linkBox {
    margin-right: 2rem;
    & > a > img {
      height: 22px;
    }
  }
}

.black-char {
  fill: #000000;
}
.blue-char {
  fill: #3095f2;
}

.appDevelopmetBadge {
  width: 66px !important;

  @media screen and (max-width: 320px) {
    width: fit-content;
  }
}

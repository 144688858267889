.ai_base_header {
  text-align: center;
  margin-top: 3rem;

  h1 {
    font-family: 'Avenir-Black-03', sans-serif;
    font-size: 3.875rem;
    color: white;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
    line-height: 140%;
    @media screen and (min-width: 2560px) {
      font-size: 110px;
    }
    @media screen and (max-width: 875px) {
    }
    @media screen and (max-width: 575px) {
      font-size: 30px;
      
    }
    span {
      letter-spacing: 0.01em;
      background: linear-gradient(84.83deg, #3095F2 36.23%, #F4BBE1 109%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  p {
    font-size: 1.5rem;
    font-family: 'Avenir-Medium-09', sans-serif;
    color: white;
    line-height: 2.5rem;
    font-weight: 500;
    font-style: normal;
    @media screen and (min-width: 2560px) {
      font-size: 32px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 26px;
      width: 100%;
    }
    @media screen and (max-width: 650px) {
      br {
        display: none;
      }
    }
  }
  .link_button > button{
    margin-top: 4rem;

    @media screen and (max-width: 650px) {
      margin-top: 2rem;
    }
  }
}

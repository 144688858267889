.ourProjects {
  .our_project-slider-container {
    //   display: flex;
    .slick-list {
      margin-right: -30px;
    }
    .slick-track {
      display: flex !important;
      align-items: stretch !important;
      // gap: 30px;
      // flex-direction: column;
    }
    .slick-slide {
      height: auto !important;
      margin-right: 30px;
      & > div {
        height: 100% !important;
      }
    }

    .slick-dots {
      bottom: -50px;
      // left: 62%;

      li.slick-active {
        button {
          &::before {
            height: 19px;
            border-radius: 50%;
            color: #0085ff;
            background: rgba(48, 149, 242, 0.1);
            opacity: 1;
            font-size: 8px;
          }
        }
      }

      button {
        &::before {
          color: #0085ff;
          opacity: 1;
          font-size: 8px;
        }
      }
    }

    .work-slide {
      //   width: auto !important;
      //   margin-right: 30px;
      height: 100% !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .our_project-slider-container {
      .slick-list {
        margin-right: -24px;
      }
      .slick-slide {
        margin-right: 24px;
      }
    }
  }
  @media screen and (max-width: 675px) {
    .our_project-slider-container {
      .slick-list {
        margin-right: 0;
      }
      .slick-slide {
        margin-right: 0;
      }
    }
  }
}

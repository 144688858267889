.input_container {
  display: flex;
  flex-direction: column;

  .form-group {
    position: relative;
    background: white;
    overflow: hidden;
    border-radius: 8px;

    input {
      height: 50px;
      border: none;
      color: black;
      padding: 0 16px;
      background: transparent;
      width: 100%;
      position: relative;
      z-index: 10;

      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #97979d;
        font-family: "Avenir-Book-01", sans-serif;
      }

      &:focus {
        outline: none !important;
        border: none;
        box-shadow: none;
      }
    }
    label {
      position: absolute;
      left: 16px;
      top: 12px;
      z-index: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #97979d;
      font-family: "Avenir-Book-01", sans-serif;

      &::after {
        content: "*";
        color: #ff3535;
      }
    }
  }
  .error_box {
    font-size: 12px!important;
    color: red;
    font-size: 16px;
    line-height: 28px;
  }
}
.hidePlaceholder {
  input + label {
    display: none;
  }
}

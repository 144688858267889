.careers-description-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  color: #000;

  .careers-description-block {
    margin-bottom: 4rem;
  }

  .title {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 2rem;
  }

  .description,
  .list {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #7c7c87 !important;
  }

  .list {
    list-style: none;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.7rem;
    }
  }

  .list li::before {
    content: '\2022';
    color: #3095f2;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin: 0;
    margin: 0 0.5rem 0 -1.5rem;
  }
  @media screen and (min-width: 1800px) {
    .description,
    .list {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media screen and (min-width: 2000px) {
    .title {
      font-size: 34px;
      line-height: 33px;
    }
    .description,
    .list {
      font-size: 22px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: 1150px) {
    .title {
      font-size: 24px;
      line-height: 32px;
    }
    .description,
    .list {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media screen and (max-width: 991px) {
    .title {
      font-size: 24px;
      line-height: 32px;
    }
    .description,
    .list {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media screen and (max-width: 575px) {
    .title {
      font-size: 22px;
      line-height: 30px;
    }
    .description,
    .list {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.meest-wireframes-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  background: linear-gradient(
    360deg,
    rgba(11, 132, 230, 0.18) 0%,
    rgba(11, 132, 230, 0) 84.2%
  );
  margin-top: -1rem;

  .meest-wireframes-row {
    border-top: 2px dashed #0061af99;
    border-left: 2px dashed #0061af99;
    border-top-left-radius: 4rem;
    border-bottom-right-radius: 0;
    padding: 5rem 0 0 2rem;
    margin: 0.92rem 5.7rem 0 3rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      left: -10px;
      background-color: #ffffff;
      border: 2px solid #0061af;
      top: 50%;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .meest-wireframes-images {
    position: relative;
    height: 75vw;
    overflow: hidden;
    .slick-list {
      height: 100% !important;
    }
    .slick-slide:nth-child(3),
    .slick-slide:nth-child(6),
    .slick-slide:nth-child(9) {
      img {
        margin-bottom: 2rem;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .meest-wireframes-images-shadow {
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 10rem;
    top: 0;
    left: 0;
  }

  .meest-wireframes-title {
    font-weight: inherit;
    font-size: 50px;
    line-height: 140%;
    color: #232832;
  }

  .meest-wireframes-text {
    font-size: 18px;
    line-height: 160%;
    color: #656875;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 1800px) {
    .meest-wireframes-row {
      padding: 5rem 0 0 5rem;
      margin: 0.92rem 9.3rem 0 3rem;
    }
    .meest-wireframes-title {
      font-size: 4rem;
    }

    .meest-wireframes-text {
      font-size: 2.1rem;
    }
  }
  @media screen and (max-width: 911px) {
    .meest-wireframes-row {
      border-top: 2px dashed #0061af99;
      border-left: 2px dashed #0061af99;
      border-top-left-radius: 4rem;
      border-bottom-right-radius: 0;
      padding: 5rem 0 0 2rem;
      margin: 0.92rem 4rem 0 1.5rem;
    }
  }

  @media screen and (max-width: 575px) {
    .meest-wireframes-row {
      border: none;
      padding: 0;
      margin: 0;
      &:before,
      &:after {
        display: none;
      }
    }
    .meest-wireframes-title {
      font-size: 30px;
      line-height: 140%;
    }

    .meest-wireframes-text {
      font-size: 16px;
      line-height: 160%;
    }
    .meest-wireframes-images {
      height: 120vw;
      .slick-slide:nth-child(3),
      .slick-slide:nth-child(6),
      .slick-slide:nth-child(9) {
        img {
          margin-bottom: 0;
        }
      }
    }
  }
}

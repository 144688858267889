.multiplatform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  &__item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 2rem;
    background-color: white;
    // padding-right: 2rem;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.02) !important;
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      gap: 1rem;
      &__description {
        color: var(--p-text-color);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    &__item {
      padding: 1.5rem 1.5rem 1.5rem 0;
      // gap: 1rem;
      &__image {
        height: 100%;
        display: flex;
        align-items: center;
        img {
          width: 130px;
        }
      }

      &__content {
        padding: 0;
      }
    }
  }
}

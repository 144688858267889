.meest-product-features {
  font-family: 'Montserrat-Regular', sans-serif;
  color: var(--title-text-color);

  .meest-features-row {
    border-right: 2px dashed #0061af99;
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 3rem;
    padding: 5rem 0;
    margin: 0 3rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 327px;
      height: 100px;
      right: 0;
      border-top: 2px dashed #0061af99;
      border-top-right-radius: 4rem;
      top: -2px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      right: -10px;
      background-color: #ffffff;
      border: 2px solid #0061af;
      top: 47.3%;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .meest-product-features-title {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 30px;
    color: #232832;
    line-height: 140%;
  }
  .meest-product-features-text {
    font-size: 16px;
    line-height: 160%;
    color: var(--title-text-color);
  }
  @media screen and (min-width: 1800px) {
    .meest-features-row {
      padding: 7rem 0;
      margin: 0 7rem;
    }
    .meest-features-icon {
      width: 40%;
    }
    .meest-product-features-title {
      font-size: 2.7rem;
      line-height: 150%;
    }
    .meest-product-features-text {
      font-size: 1.5rem;
      line-height: 165%;
    }
  }

  @media screen and (max-width: 911px) {
    .meest-features-row {
      margin: 0 1.5rem;
    }
  }
  @media screen and (max-width: 700px) {
    .meest-features-row {
      margin: 0 1.5rem;
    }
  }
  @media screen and (max-width: 575px) {
    .meest-features-row {
      padding: 0 0 5rem;
      justify-content: center;
      margin: 0;
      &:after,
      &:before {
        display: none;
      }
      border: none;
    }
  }
}

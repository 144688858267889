.fullCycle-tools-box {
  margin: 8rem auto;
  @media screen and (max-width: 1024px) {
    margin: 6rem auto;
  }
}
@media screen and (max-width: 768px) {
  .full-cycle {
    .main_content_box {
      h1,
      p {
        text-align: center;
      }
    }

    .getInTouchBtn {
      align-self: center;
      justify-content: center;
      justify-self: center;
    }

    .responsive-page-head-top_bottom-gap .responsive-container {
      display: flex;
      flex-direction: column;
    }
  }
}

.list-wrapper {
  .list-container {
    border-bottom: 1px solid #393957;
    margin-top: 5rem;
  }

  .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--p-text-color);
    border-top: 1px solid #393957;
    padding: 3rem 0;

    h2 {
      margin-bottom: 0;
      text-align: left;
    }

    .list-number {
      flex: 0 0 13%;
      color: #3095f2;
      font-family: 'Avenir-Black-03', sans-serif;
      font-size: 2rem;
      font-style: normal;
      line-height: 140%;
    }

    .medium16px {
      opacity: 0.8;
      font-weight: 500;
    }
  }

  .list-item-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    width: 100%;

    & > div {
      flex: 1 0 45%;
    }
  }
}

@media screen and (max-width: 765px) {
  .list-wrapper {
    .list-item {
      align-items: flex-start;
      gap: 1rem;
    }
    .list-item-content {
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

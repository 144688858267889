.modal-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  color: var(--title-text-color);
  .response {
    font-family: 'Avenir-Medium-09', sans-serif;
    text-align: center;
    font-size: 20px;
    line-height: 165%;
  }
  .modal-content {
    position: relative;
    border-radius: 10px;

    .modal-header {
      border: none;
      padding: 1rem 1.5rem 0;

      .close {
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      padding-top: 0;
    }
  }
  .contact-form_careers {
    width: 80%;
  }
}

@media screen and (max-width: 375px) {
  .modal-wrapper {
    .modal-content {
      border-radius: 0;
    }
  }
}

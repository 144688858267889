.big-ticket-wrapper {
  .product-layout {
    height: 40rem;
    margin: 0;
    background-image: linear-gradient(58.4deg, rgba(191, 159, 122, 0.19) 14.68%, rgba(208, 137, 53, 0.21) 74.82%), url('https://perpetiostorage1.b-cdn.net/resources/images/big-ticket/layout-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;


    @media screen and (min-width: 1800px) {
      height: 50rem;
    }
    @media screen and (min-width: 2000px) {
      height: 60rem;
    }

    @media screen and (max-width: 575px) {
      height: 30rem;
      background-image: linear-gradient(35.84deg, rgba(208, 137, 53, 0.19) 24.06%, rgba(208, 137, 53, 0.21) 83.18%), url('https://perpetiostorage1.b-cdn.net/resources/images/big-ticket/mobile-layout-bg.jpg');
    }

  }
}
.benefit-wrapper {
  .benefit-cases-box {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;

    .benefit-case {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > h3 {
        margin-top: 2rem;
        margin-bottom: 7px;
      }
    }
  }
  .benefit-domain-wrapper {
    margin-top: 5rem;
    & > .domainContainer {
      transform: none;
      background: transparent;
    }
    & > .domainContainer > .max-width-container {
      max-width: 100%;
      margin: 0;
      .responsive-container {
        margin: 0;
      }
      .responsive-page-top_bottom-gap {
        padding: 0;
        .domain_content_box {
          transform: none;
          .domains_list_box {
            .row_title {
              background: #f1f7fc;
            }
            .row_content {
              background: #f1f7fc;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .benefit-domain-wrapper {
      & > .domainContainer > .max-width-container {
        .responsive-page-top_bottom-gap {
          .domain_content_box {
            .domains_list_box {
              margin-top: 1.5rem;
              .row_title {
                padding-top: 20px;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .benefit-cases-box {
      flex-direction: column;
      gap: 4rem;
    }
  }
}

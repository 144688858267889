.sliders-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    background: linear-gradient(145deg, #f3f2ff 0%, #e8fffe 55.21%, #f5f6ff 100%);
    & > div:first-child {
      .max-width-container {
        .responsive-container {
          .image-slider-wrapper {
            .image-slider-content-desktop {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
    & > div:last-child {
      .max-width-container {
        .responsive-page-top_bottom-gap {
          padding-top: 4rem;
        }
        .responsive-container {
          .image-slider-wrapper {
            .image-slider-content-mobile {
              margin-top: 0;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1300px) {
      gap: 0;
      & > div:first-child {
        .max-width-container {
          .responsive-container {
            .image-slider-wrapper {
              .image-slider-content-mobile {
                .image-slider-content-items {
                  .image-slider-content-item {
                    &:last-child {
                      border-bottom: 1px solid #d7d7d7;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .tech-stack-wrapper {
    margin-top: 4rem;
  }
  
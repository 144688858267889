.textImageContainer {
  margin-top: 7.5rem;

  .textImageBoxFade {
    display: flex;
    justify-content: center;
    .contentBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 1170px;
      .textContainer {
      }
      .textBox {
        & > div {
          font-family: 'Avenir-Heavy-05', sans-serif;
          color: #0a0a0a;
          font-style: normal;
          font-size: 32px;
          line-height: 140%;
          margin-bottom: 2rem;
        }
        & > span {
          font-family: 'Avenir-Book-01', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 160%;
          color: var(--title-text-color);
        }
      }
    }
    .imageContainer {
      margin-top: 5rem;
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      .imageBox {
        padding: 0;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .textImageContainer {
    padding: 0;

    .textImageBoxFade {
      justify-content: start;
      // width: 100%;
      .contentBox {
        margin-left: 1rem;
        margin-right: 1rem;
        width: -webkit-fill-available;
        .textContainer {
          margin: 0;
          .textBox > span {
            & > br {
              display: none;
            }
          }
          .textBox {
            padding: 0;
          }
        }

        .imageContainer {
          margin-top: 2.5rem;
          // width: 100%;
          height: auto;
          .imageBox {
            height: auto;
            // padding-right: 16px;
            // padding-left: 16px;
          }
          & .imageBox > img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .textImageContainer {
    margin-top: 6rem;
    .textImageBoxFade {
      .contentBox {
        .textContainer {
          .textBox > span {
            font-size: 16px;
            line-height: 160%;
            & > br {
              display: none;
            }
          }
          .textBox > div {
            font-size: 24px;
            line-height: 140%;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.productBox {
  .products-new .product-item_Box:nth-child(1) {
    margin: 0 !important;
  }
}
.cross-tools-box {
  margin: 8rem auto;

  @media screen and (max-width: 1024px) {
    margin: 6rem auto;
  }
}
.getInTouchBtn button {
  background-color: #3095f2;
  margin-top: 40px;
  padding: 12px 38px;
  font-size: 1.125rem;
  font-family: 'Avenir-Heavy-05', sans-serif;
  border: none;
}

.principles_container {
  .principles_content_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    .principles_items_box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;

      .principles_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
      }

      .principles_item_box {
        & > p > span {
          color: #3095f2;
        }
        & > h3 > span {
          color: #3095f2;
        }
      }
    }
  }
  @media screen and (max-width: 765px) {
    .principles_content_box {
      .principles_items_box {
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
      }
    }
    br {
      display: none;
    }
  }
  @media screen and (max-width: 575px) {
    .principles_content_box {
      .principles_items_box {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.shrink-wrapper {
  .product-overview {
    font-family: 'Avenir-Roman-12', sans-serif;
    padding: 0;
    margin: 5rem auto;
    justify-content: center;

    .margin {
      margin: auto 5rem;
    }

    .product-overview-row {
      justify-content: space-between;
      margin-bottom: 3rem;

      .product-overview-row2 {
        margin: 0;
        justify-content: flex-start;

        .product-feature-row {
          flex-direction: column;
          margin: 0;
          margin-top: 1rem;
          margin-right: 2rem;
          width: auto;
        }
      }

      .product-overview-name {
        font-size: 18px;
        font-weight: inherit;
        line-height: 140%;
      }
      .product-overview-text {
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 0;
      }

      .logo {
        display: block;
        height: 100px;
        max-width: 100%;
      }

      .product-overview-title {
        margin-bottom: 1.5rem;
        font-size: 30px;
        font-weight: inherit;
        line-height: 140%;
      }

      .product-main-row2-services-description {
        font-size: 18px;
        line-height: 160%;
      }

      .product-feature {
        height: 4rem;
      }

      .product-feature-col-2 {
        margin-top: 1rem;

        .product-feature-caption {
          font-family: 'Roboto-Medium', sans-serif;
          font-size: 16px;
          line-height: 128.91%;
          margin: 0;
        }
      }
      .list {
        list-style: none;
        padding: 0;
        font-size: 18px;
      }
    }

    @media screen and (min-width: 1800px) {
      .margin {
        margin-right: 9rem;
        margin-left: 9rem;
      }
    }

    @media screen and (min-width: 2000px) {
      .product-overview-row {
        .product-overview-name {
          font-size: 2rem;
        }
        .product-overview-text {
          font-size: 1.5rem;
          line-height: 140%;
        }

        .product-overview-title {
          font-size: 3rem;
        }

        .product-main-row2-services-description {
          font-size: 1.5rem;
        }

        .product-feature {
          height: 6rem;
        }

        .product-feature-col-2 {
          margin-top: 1rem;

          .product-feature-caption {
            font-size: 1.5rem;
          }
        }
        .list {
          font-size: 1.5rem;
        }
      }
    }

    @media screen and (max-width: 2000px) {
      .product-overview-row {
        .product-overview-row2 {
          max-width: 40rem;
        }
      }
    }

    @media screen and (max-width: 910px) {
      .margin {
        margin: auto calc(4rem - 15px);
      }
    }

    @media screen and (max-width: 700px) {
      .margin {
        margin: auto 2rem;
      }
      margin-bottom: 0;
      .product-overview-row {
        margin-top: 3rem;

        .product-overview-title {
          margin-bottom: 1rem;
        }

        .product-overview-row2 {
          margin: 0;
          justify-content: space-between;
        }

        .product-overview-column {
          padding: 0;
        }
      }
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 0;
      .product-overview-row {
        margin-top: 3rem;

        .product-feature {
          height: 3rem;
        }
        .product-overview-title {
          margin-bottom: 1rem;
        }

        .product-overview-row2 {
          margin: 0;
          justify-content: space-evenly;

          .product-feature-row {
            padding: 0.5rem;
            margin-right: 0;
          }
        }

        .product-overview-column {
          padding: 0;
        }
      }
    }
    @media screen and (max-width: 375px) {
      .product-overview-row {
        .product-overview-name {
          font-size: 15px;
        }
        .product-overview-text {
          font-size: 13px;
        }
      }
    }
    @media screen and (max-width: 350px) {
      .product-overview-row {
        .product-overview-row2 {
          .product-feature-row {
            padding: 0.7rem;
          }
        }
      }
    }
  }
}

.aob-wrapper {
  .product-description {
    font-family: 'Avenir-Book-01', sans-serif;
    background: linear-gradient(247.81deg, #29a3c1 12.38%, #0d5472 85.16%);
    .product-description-row {
      align-items: center;
      justify-content: space-around;
      padding: 5rem 4.3rem;

      .description,
      .title {
        color: #ffffff;
      }
      .description {
        font-size: 18px;
        line-height: 140%;
      }

      .product-description-img-aob {
        width: 100%;
        height: 100%;
      }

      .title {
        font-family: 'Avenir-Heavy-05', sans-serif;
        font-size: 30px;
        line-height: 140%;
        font-weight: inherit;
      }
    }

    @media screen and (min-width: 1600px) {
      .product-description-row {
        .product-description-img-aob {
          width: 90%;
        }
      }
    }

    @media screen and (min-width: 1800px) {
      .product-description-row {
        padding: 5rem 7.5rem;
        .description {
          font-size: 28px;
        }
        .title {
          font-size: 45px;
        }

        .product-description-img-aob {
          width: 80%;
        }
      }
    }
    @media screen and (min-width: 2000px) {
      .product-description-row {
        padding: 5rem 7rem;
        .description {
          font-size: 2rem;
        }
        .title {
          font-size: 4rem;
        }
        .product-description-img-aob {
          width: 90%;
        }
      }
    }
    @media screen and (min-width: 2200px) {
      .product-description-row {
        .product-description-img-aob {
          width: 75%;
        }
      }
    }

    @media screen and (max-width: 1250px) {
      .product-description-row {
        padding: 5rem 4.7rem;
      }
    }

    @media screen and (max-width: 911px) {
      .product-description-row {
        padding: 5rem 3rem;
      }
    }

    @media screen and (max-width: 700px) {
      .product-description-row {
        padding: 5rem 1rem;
      }
    }

    @media screen and (max-width: 575px) {
      .product-description-row {
        padding: 5rem 2rem;

        .description {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.development {
  font-family: 'Avenir-Roman-12', sans-serif;
  .development-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 60px 90px;
    .item {
      text-align: center;

      h3 {
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 60px 0;
      .item:nth-child(4) {
        grid-column: initial;
      }
    }
    @media screen and (max-width: 765px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.team-wrapper {
  h2 {
    margin-bottom: 3.75rem;
  }
}

.team-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 30px;

  .team-item__text-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.team-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2.5rem;
  gap: 2.5rem;
  min-width: 30rem;

  flex: 1 1 40%;

  background: linear-gradient(
    261.45deg,
    #f3f2ff 0%,
    #e8fffe 46.93%,
    #f5f6ff 100%
  );
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .team-item {
    max-width: 100%;
    min-width: 100%;
    gap: 1rem;
    padding: 1rem;

    img {
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  }
}

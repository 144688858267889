.ai-based {
  .dark-wrapper {
    background: linear-gradient(
      167.66deg,
      #01011f 14.65%,
      #0a386a 34.18%,
      #570861 52.47%,
      #01011f 78.25%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .responsive-page-head-top_bottom-gap {
    padding-bottom: 5rem;
  }

  .key-stats {
    padding-top: 3.5rem;

    @media screen and (max-width: 1024px) {
      padding-top: 4rem;
    }
  }

  .cases-wrapper {
    background: #020220;
    h2 {
      color: var(--white-text-color);
    }
    .allCaseBtn_flex > h3 {
      color: var(--white-text-color) !important;
      font-size: 1rem;
    }
  }

  .multiplatform-wrapper {
    background: linear-gradient(
      110deg,
      #f3f2ff 12.17%,
      #e8fffe 62.49%,
      #f5f6ff 103.31%
    );
    transform: rotate(-180deg);
  }

  .form_content_box {
    h2 {
      color: var(--white-text-color);
    }
    .promoted-checkbox > p {
      color: var(--white-text-color);
    }
    .form_box .form_inputs_box .submitting_text {
      color: #97979d;
    }
    .file_box > p {
      color: white;
    }
  }

  .black-wrapper {
    background: linear-gradient(
      168deg,
      #01011f 14.6%,
      #002160 35.08%,
      #1f0861 48.31%,
      #01011f 76.78%
    );
  }
}

.three-columns {
  margin: 7rem 0;
  .commit-block-title {
    margin: auto 5rem 5.7rem;
    font-family: 'Avenir-Roman-12';
    font-weight: inherit;
    font-size: 80px;
    color: #e7e7e7;
  }
  .commit-block-row {
    margin: auto 5rem;
    .three-columns-column {
      img {
        width: 100%;
      }
    }
  }
}

.registration-block {
  .commit-block-title {
    color: #e7e7e7;
  }
  .three-columns-column {
    img {
      padding-left: 4vw;
    }
    &:first-child {
      img {
        padding: 0;
      }
    }
  }
}

.community-block {
  margin: 0;
  .commit-block-title {
    color: #cbebc3;
  }
  .three-columns-column {
    img {
      padding-right: 4vw;
    }
    &:last-child {
      img {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 1800px) {
  .three-columns {
    .commit-block-title {
      margin: auto 8rem 5.7rem;
    }
    .commit-block-row {
      margin: auto 8rem;
    }
  }
}

@media screen and (min-width: 2000px) {
  .three-columns {
    .commit-block-title {
      font-size: 120px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .three-columns.registration-block {
    .three-columns-column {
      img {
        width: 90%;
        &.phone {
          width: 80%;
        }
      }
    }
  }
  .three-columns.community-block {
    .three-columns-column {
      img {
        width: 90%;
        &.phone {
          width: 80%;
        }
      }
    }
  }
}
@media screen and (max-width: 911px) {
  .three-columns {
    .commit-block-title {
      margin: auto 3rem 5.7rem;
    }
    .commit-block-row {
      margin: auto 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .three-columns {
    .commit-block-title,
    .commit-block-row {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
}

@media screen and (max-width: 575px) {
  .three-columns.registration-block {
    .three-columns-column {
      img {
        width: 100%;
        &.phone {
          width: 100%;
        }
      }
    }
  }
  .three-columns.community-block {
    .three-columns-column {
      img {
        width: 100%;
        &.phone {
          width: 100%;
        }
      }
    }
  }
  .three-columns {
    margin: 5rem 0;
    .commit-block-title {
      margin: auto 0 4rem;
      font-size: 40px;
    }
    .commit-block-row {
      margin: auto 0;
    }
  }
  .registration-block {
    .three-columns-column {
      padding: 0;
      img {
        padding-left: 4vw;
      }
      &:first-child {
        img {
          padding: 0;
        }
      }
    }
  }
  .community-block {
    margin: 0;
    .three-columns-column {
      padding: 0;
      img {
        padding-right: 4vw;
      }
      &:last-child {
        img {
          padding: 0;
        }
      }
    }
  }
}

.domainContainer {
  --bg-color: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  --box-color: #fff;
  --title-text-color: #333333;
  --p-text-color: #4b4b4b;

  background: var(--bg-color);
  transform: rotate(-180deg);

  .domain_content_box {
    transform: rotate(-180deg);
    display: flex;
    flex-direction: column;
    align-items: center;

    .domain_content_title_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }
    .domains_list_box {
      display: flex;
      flex-direction: column;
      text-transform: capitalize;
      gap: 30px;
      margin-top: 60px;
      width: 100%;
      .row_box {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 3fr;
        min-height: 124px;
        width: 100%;
        .row_title {
          background: var(--box-color);
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 40px;
          padding-right: 40px;
        }
        .row_content {
          background: var(--box-color);
          border-radius: 6px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 24px 32px;
          gap: 24px 90px;

          .row_content_item {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 26%;
            .row_dot {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #3095f2;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1550px) {
    .domain_content_box {
      .domains_list_box {
        .row_box {
          .row_content {
            gap: 24px 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .domain_content_box {
      .domains_list_box {
        .row_box {
          .row_content {
            gap: 24px 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1350px) {
    .domain_content_box {
      .domains_list_box {
        gap: 40px;
        .row_box {
          grid-template-columns: 1fr;
          gap: 12px;

          .row_title {
            padding-top: 20px;
            padding-bottom: 20px;
          }
          .row_content {
            // grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 815px) {
    .domain_content_box {
      .domains_list_box {
        .row_box {
          .row_title {
            background: none !important;
            h3 {
              text-align: center;
              font-size: 1.375rem;
              font-style: normal;
              font-weight: inherit;
              line-height: 140%;
            }
          }
          .row_content {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .domain_content_box {
      .domains_list_box {
        .row_box {
          .row_content {
            grid-template-columns: 1fr;
          }
        }
      }
    }
    br {
      display: none;
    }
  }
}
.domainContainerWhite {
  --bg-color: #fff;
  --box-color: #f1f7fc;
}

.pink-gradient-container {
  --title-text-color: #333333;
  --p-text-color: #4b4b4b;

  background: linear-gradient(
    290deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );

  h2 span {
    color: #3095f2;
  }
}

.pink-gradient-container-horizontal {
  .pink-gradient-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.88rem;
  }

  .pink-gradient-item {
    flex-direction: column;
    padding: 1.5rem;
    .pink-gradient-content {
      margin-left: 0;
    }
  }
  .pink-gradient-image {
    flex: 0 0 25%;
    margin-bottom: 1rem;
    justify-content: flex-start;
    align-self: flex-start;
  }
}

.pink-gradient-subtitle {
  margin-top: 1.5rem;
}

.pink-gradient-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 4rem;
}

.pink-gradient-item {
  display: flex;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 2.5rem;
  align-items: center;

  .pink-gradient-content {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    flex: 1 0 66%;
    gap: 1rem;
  }
}

.pink-gradient-image {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 0 25%;
}

.pink-gradient-items-circles {
  display: flex;
  gap: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 5.5rem;

  .pink-gradient-item {
    display: flex;
    align-items: center;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
    border-radius: 50%;
    border: 1px solid #f2f2f2;
    aspect-ratio: 1/1;
    height: 100%;
    padding: 2rem;
    max-width: 310px;

    .pink-gradient-content {
      margin-left: 0;
    }
  }

  h3 {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 1.125rem;
    font-weight: inherit;
    line-height: 26px;
    letter-spacing: 0em;
    margin-bottom: 8px;
    text-align: center;
    color: #3095f2;
    padding: 0 0.5rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000b2;
  }
}

@media screen and (max-width: 1420px) {
  .pink-gradient-items-circles .pink-gradient-item {
    width: 100%;
    max-width: 400px;
    align-items: center;
    padding-top: 0;
  }

  .pink-gradient-container-horizontal {
    .pink-gradient-items {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 1100px) {
  .pink-gradient-items-circles .pink-gradient-item {
    max-width: 330px;
  }

  .pink-gradient-items {
    grid-template-columns: 1fr;
  }

  .pink-gradient-item {
    max-width: 100%;
  }
}
@media screen and (max-width: 910px) {
  .pink-gradient-subtitle {
    margin-top: 1rem;

    p {
      text-align: center;
    }
  }

  .pink-gradient-item {
    padding: 20px;
    .pink-gradient-content {
      gap: 10px;
    }
  }

  .pink-gradient-image {
    width: 100px;
    height: 100px;
    & > div > img {
      width: 100%;
      height: 100%;
    }
  }
}
.mobile_image_near_title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .lazyload-wrapper > img {
    width: 80px;
  }
}
.mobile_image_near_title__head {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .pink-gradient-content {
    .medium16px {
      font-size: 14px;
    }
  }

  .pink-gradient-items-circles {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .pink-gradient-item:nth-child(2n-1) {
      margin-right: 10vw;
    }
    .pink-gradient-item:nth-child(2n) {
      margin-left: 10vw;
    }

    .pink-gradient-item {
      padding-top: 15px;
      min-height: 280px;
      max-width: 280px;
      aspect-ratio: 1/1;
      width: unset;
    }
  }

  .pink-gradient-container-horizontal {
    .pink-gradient-items {
      grid-template-columns: 1fr;
    }

    .mobile_image_near_title__head {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 400px) {
  .pink-gradient-items-circles {
    .pink-gradient-item {
      align-items: center;
      height: 90vw;
      width: 90vw;
      min-height: unset;
      max-width: unset;
      margin-left: 0;
      margin-right: 0;
    }
    .pink-gradient-item:nth-child(2n-1) {
      margin-right: 5vw;
    }
    .pink-gradient-item:nth-child(2n) {
      margin-left: 5vw;
    }
  }
}

@media screen and (max-width: 350px) {
  .pink-gradient-items-circles {
    .pink-gradient-item {
      height: 95vw;
      width: 95vw;
    }
    .pink-gradient-item:nth-child(2n-1) {
      margin-right: 0;
    }
    .pink-gradient-item:nth-child(2n) {
      margin-left: 0;
    }
  }
}

.product-description {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0;
  margin: 4rem auto 0;
  .margin {
    margin: auto 5rem;
  }
  .description {
    font-size: 1.4rem;
  }

  .wireframes {
    background: linear-gradient(255.47deg, #ba48f4 0%, #449beb 99.52%);
    padding: 5rem 0;

    .description,
    .title {
      color: #ffffff;
    }
  }

  .title {
    color: var(--title-text-color);
    font-size: 30px;
    font-weight: inherit;
    line-height: 140%;
  }
  .screens {
    margin: 0 12rem;
    justify-content: space-between;

    .product-screens-col {
      img {
        width: 75%;
        height: 95%;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .description {
      font-size: 2rem;
    }
    .wireframes {
      padding: 7rem 0;
    }
    .title {
      font-size: 4rem;
    }
    .screens {
      margin: 0 25rem;
      justify-content: space-between;

      .product-screens-col {
        img {
          width: 80%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .screens {
      margin: 0 10rem;
    }
  }

  @media screen and (max-width: 1300px) {
    .screens {
      margin: 0 8rem;
    }
  }
  @media screen and (max-width: 750px) {
    .wireframes {
      padding: 3rem 0;
    }
  }

  @media screen and (max-width: 575px) {
    margin: 0 auto;
    .margin {
      margin: auto 2rem;
    }

    .screens {
      .product-screens-col {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .product-screens-col {
      padding: 0;
    }

    .description {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}

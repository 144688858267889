.key-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  text-align: center;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 1rem;
    position: relative;
    &:not(:last-child)::before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 57px;
      background-color: rgba(255, 255, 255, 0.4);
      right: 0;
    }

    & > p {
      max-width: 225px;
      color: var(--light-gray-color);
      margin-top: 1rem;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  }

  @media screen and (max-width: 1250px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__item {
      width: 100%;
      height: 100%;
      justify-content: start;

      &:nth-child(2)::before {
        display: none;
      }

      &:not(:last-child)::before {
        top: 40%;
      }
    }
  }

  @media screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;

    &__item {
      width: 100%;
      height: 100%;
      justify-content: start;

      &:not(:first-child)::before {
        content: '';
        display: block;
        position: absolute;
        width: 57px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        left: 50%;
        transform: translateX(-50%);
        top: -2rem;
      }
    }
  }
}

.clover-wrapper {
  .clover-titleBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
  }

  p,
  .medium24px {
    margin-top: 1rem;
  }
}

.clover-container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12rem;
}

.three-leaf-clover {
  display: flex;
  min-height: 70vh;

  .clover-leaf:nth-child(1) {
    position: absolute;
    top: 30%;
  }
  .clover-leaf:nth-child(2) {
    position: absolute;
    right: 0;
    top: 5%;
  }
  .clover-leaf:nth-child(3) {
    position: absolute;
    right: 20%;
    bottom: -5%;
    border-radius: 0 22px 22px 22px;
  }
}

.clover-leaf {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  border: 2px solid #f2f2f2;
  border-radius: 22px 22px 0px 22px;
  background-color: #ffffff;
  padding: 24px;
  max-width: 30vw;
  min-width: 25vw;

  &:nth-child(2) {
    border-radius: 22px 22px 22px 0px;
  }
  &:nth-child(3) {
    border-radius: 22px 0 22px 22px;
  }

  &:nth-child(4) {
    align-self: flex-end;
    border-radius: 0 22px 22px 22px;
  }

  &.leaf-centered {
    align-items: center;
  }
}

.clover-image {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
  z-index: -1;
  display: flex;
  justify-content: center;
  max-width: 368px;
  max-height: 369px;
}

@media screen and (max-width: 1200px) {
  .clover-container {
    gap: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .clover-wrapper {
    .clover-titleBox {
      margin-bottom: 40px;
    }
  }

  .clover-container {
    justify-content: center;
    gap: 2rem;
  }

  .clover-leaf {
    max-width: 100%;

    &:nth-child(n + 3) {
      order: 3;
    }
  }

  .clover-image {
    position: relative;
    width: 100%;
    transform: unset;
    top: unset;
    right: unset;
    order: 2;
  }

  .three-leaf-clover {
    height: 100%;

    .clover-leaf.clover-leaf {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
    }
  }
}

@media screen and (max-width: 1024px) {
  .clover-image img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .clover-leaf.clover-leaf {
    min-width: unset;
    width: 100%;
  }
}

.tech-stack {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }

  img {
    width: auto;
    height: 64px;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
}
@media screen and (max-width: 1300px) {
  .tech-stack {
    & > div {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 450px) {
  .tech-stack {
    gap: 3.75rem;

    h3 {
      margin-bottom: 3rem;
    }

    img {
      height: 48px;
      width: auto;
      aspect-ratio: 1/1;
    }

    & > div {
      gap: 2.5rem;
    }
  }
}

.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
@media screen and (max-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
}

.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
@media screen and (max-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
}

.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}

@media screen and (max-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}

.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}

.text-sm-right {
  text-align: right !important;
}
.text-sm-left {
  text-align: left !important;
}

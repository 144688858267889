.work-process {
  h2 {
    margin-bottom: 3.75rem;
  }
}

.work-process-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.work-process-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
  gap: 20px;
  row-gap: 2rem;
  position: relative;

  .heavy20px {
    padding-top: 5px;
  }
}

.work-process-container:nth-child(1)::after {
  content: '';
  position: absolute;
  top: 90px;
  right: -20px;
  width: 20px;
  height: 4px;
  background-color: #f1f7fc;
}

.work-process-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 90px;
    right: -20px;
    width: 20px;
    height: 4px;
    background-color: #f1f7fc;
  }

  &:last-child::after {
    display: none;
  }

  .work-process-line {
    width: 0;
    height: 1.5rem;
    border: 2px dashed #d9d9d9;
  }

  span {
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #f1f7fc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #3095f2;
    font-size: 900;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 1.25rem;
  }

  & > div {
    font-size: 900;
    font-family: 'Avenir-Heavy-05', sans-serif;
    color: #0d2842;
    font-size: 1.25rem;
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 1450px) {
  .work-process-item {
    img {
      width: 9rem;
    }

    &::after {
      top: 4.5rem;
    }
  }

  .work-process-container:nth-child(1)::after {
    top: 4.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .work-process-wrapper {
    flex-wrap: wrap;
  }
  .work-process-container:nth-child(1)::after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .work-process {
    .work-process-item {
      &::after {
        top: 50px;
      }

      img {
        width: 6rem;
      }

      span {
        height: 1.6rem;
        width: 1.6rem;
        font-size: 0.8rem;
      }

      & > div {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .work-process-container {
    width: 100%;

    .work-process-item {
      &::after {
        display: none;
      }
    }
  }
}

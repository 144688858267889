.positions {
  font-family: 'Avenir-Heavy-05', sans-serif;
  padding: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #828282;

  .positions-row,
  .block-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    .position-animate {
      .position-block {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        padding: 1.2rem 1.5rem;
        outline: 1px solid #e1e8ed;
        border-radius: 6px;
        height: 200px !important;
        min-width: 280px;
        cursor: pointer;
        transition: outline 0.7s ease;

        &:hover {
          outline-width: 3px;
        }

        .position-title {
          font-family: 'Avenir-Heavy-05';
          color: var(--title-text-color);
          font-style: normal;
          font-size: 24px !important;
          line-height: 34px;
        }

        .position-count {
          font-style: normal;
          font-weight: 500;
          font-size: 16px !important;
          line-height: 22px;
          color: #6b6f70;
        }
        .position-block__description {
          display: flex;
          margin-top: auto !important;
          gap: 1rem;

          .position-description {
            width: auto;
            padding: 0;
            font-weight: inherit;
            font-size: 16px;
            line-height: 22px;
            color: var(--title-text-color);
            display: flex;
            align-items: center;
            gap: 0.5rem;
            & > p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .block-row {
    margin: 0;
  }

  .title_block {
    .positions-title {
      margin-top: 20px !important;
      margin-bottom: 60px !important;
      text-align: left;
    }
    .positions-category {
      font-style: normal;
      font-weight: inherit;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #3095f2;
      margin-bottom: 20px;
    }
  }

  .block-title {
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Product Sans Regular', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 2rem;
    color: var(--title-text-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 1.5rem;
    line-height: 1.9rem;
    .positions-category {
      font-size: 20px;
    }
    .positions-title {
      font-size: 50px;
      line-height: 63px;
    }

    .block-title {
      font-size: 44px;
      line-height: 50px;
    }
    .positions-row,
    .block-row {
      .position-animate {
        .position-block {
          height: 300px;

          .position-title {
            font-size: 30px;
            line-height: 39px;
          }

          .position-count {
            font-size: 24px;
            line-height: 31px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .block-title {
      font-size: 35px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 1250px) {
    font-size: 1rem;
    line-height: 1.4rem;
    .positions-row,
    .block-row {
      .position-animate {
        .position-block {
          .position-title {
            font-size: 21px;
            line-height: 30px;
          }

          .position-count {
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 810px) {
    .positions-row,
    .block-row {
      .position-animate {
        .position-block {
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
    .positions-row,
    .block-row {
      .position-animate {
        .position-block {
          height: 300px;
          .position-title {
            font-size: 30px;
            line-height: 40px;
          }

          .position-count {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .positions-row,
    .block-row {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 700px) {
    .positions-row,
    .block-row {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }
  @media screen and (max-width: 575px) {
    .positions-row {
      grid-template-columns: repeat(auto-fill, 100%);
    }
    .block-row {
      grid-template-columns: repeat(auto-fill, 100%);
    }
    font-size: 16px;
    line-height: 22px;
    .positions-category {
      font-size: 14px;
    }
    .positions-title {
      font-size: 22px !important;
    }
    .positions-row,
    .block-row {
      .position-animate {
        .position-block {
          height: 200px;

          .position-title {
            font-size: 22px !important;
            line-height: 27px;
          }

          .position-count {
            font-size: 14px !important;
            line-height: 22px;
          }
        }
      }
    }
    .positions-title {
      font-family: 'Product Sans Bold', sans-serif;
      font-size: 28px;
      line-height: 34px;
    }
    .position-description {
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 360px) {
    .positions-row,
    .block-row {
      font-size: 12px;
      line-height: 18px;
      .position-animate {
        .position-block {
          margin-bottom: 1rem;

          .position-title {
            font-size: 18px;
            line-height: 25px;
          }

          .position-count {
            font-size: 14px;
            line-height: 20px;
          }
          .position-description:first-child {
            padding: 0.35rem 0.75rem;
          }
        }
      }
    }
  }
}

.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  margin-bottom: 7.5rem;
  &__title {
    text-align: center;
    color: var(--title-text-color);
    span {
      color: var(--main--blue-color);
    }
  }

  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    max-width: 1200px;

    &__wave {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    &__head {
      display: flex;
      gap: 3rem;
      justify-content: center;
      right: 5rem;
      position: relative;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;
        max-width: 306px;
        position: relative;

        &__content {
          &__title {
            margin-top: 1rem;
          }

          &__description {
            color: var(--medium-gray-color);
          }
        }
      }
    }

    &__bottom {
      display: flex;
      gap: 3rem;
      justify-content: center;
      left: 6rem;
      position: relative;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;
        max-width: 306px;
        position: relative;

        &__image {
          &__connector-line {
            display: none;
          }
        }

        &__content {
          &__title {
            margin-top: 1rem;
          }

          &__description {
            color: var(--medium-gray-color);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1250px) {
    gap: 4rem;

    &__title {
      br {
        display: none;
      }
    }

    &__items {
      gap: 4rem;
      &__head,
      &__bottom {
        flex-direction: column;
        align-items: center;
        left: 0;
        right: 0;
        gap: 5rem;
        &__item {
          flex-direction: row;
          text-align: start;
          align-items: start;

          &__content {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &__title {
              margin-top: 0;
            }
            // &__description {
            // max-width: 300px;
            // }
          }

          &__image {
            position: relative;
            &__connector-line {
              left: 50%;
              display: block;
              position: absolute;
              border: 0.8px solid transparent;
              border-image: repeating-linear-gradient(
                  to bottom,
                  #c9d1e3 0,
                  #c9d1e3 7px,
                  transparent 5px,
                  transparent 13px
                )
                1;
            }
            &__vector {
              position: absolute;
              width: 1.5rem;
              height: 1.5rem;
              border-radius: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(50%);
              background: url('/src/assets/blue-vector-down.svg') no-repeat
                center var(--light-blue-color);
              position: absolute;
              z-index: 1;
            }
          }
          &__image > img {
            width: 80px;
            height: 80px;
          }
        }
      }
      &__wave {
        display: none;
      }
    }
  }

  @media screen and (max-width: 750px) {
    padding-left: 1rem;
    padding-right: 1rem;
    &__items {
      &__head,
      &__bottom {
        gap: 4rem;
        align-items: start;
        &__item {
          max-width: 100%;
          &__content {
            gap: 0.5rem;
          }
          &__image > img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

.services-cases_container {
  position: relative;

  .cases_title_box {
    display: flex;
    justify-content: center;
  }

  .study_cases_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    margin-top: 60px;
  }

  .study_cases_box_fadeup {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    height: 500px;
    width: 370px;
    display: flex;
    justify-content: center;
  }

  .meest-case .lazyload-wrapper img {
    margin-bottom: -4.5rem;
  }

  .case_box {
    display: flex;
    max-height: 500px;
    max-width: 370px;
    min-height: 500px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease-in-out;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    gap: 24px;
    width: 370px;
    height: 500px;

    &:hover {
      transform: scale(1.01);
    }

    .lazyload-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    & > div {
      width: 100%;
    }

    * {
      z-index: 1;
    }

    h3 {
      align-self: flex-start;
      text-align: left;
      padding-left: 1.5rem;
      padding-right: 1.2rem;
    }
    p {
      align-self: flex-start;
      text-align: left;
      padding-left: 1.5rem;
      padding-right: 1.2rem;
    }
    .medium12px {
      font-size: 1rem;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.01em;
    }
  }

  .study_cases_box_fadeup .not-full-width .lazyload-wrapper {
    max-width: calc(100% - 4.5rem);
    margin-bottom: 1rem;
  }

  .logo_box {
    position: absolute;
    height: fit-content;
    padding: 0;
    inset: 0;
    z-index: 0;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      inset: 0;
      z-index: -1;
    }
    @media screen and (max-width: 575px) {
      p > br {
        display: none;
      }
    }
  }

  .allCaseBtn {
    margin-top: 40px;
    .allCaseBtn_flex {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.5rem;
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-style: normal;

      line-height: 140%;
    }
  }

  @media screen and (max-width: 1100px) {
    .study_cases_box {
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    .study_cases_box {
      flex-wrap: wrap;
      gap: 40px;
    }

    .responsive-page-top_bottom-gap {
      padding-bottom: 4rem;
    }
  }
}

.three-cases_container {
  .study_cases_box_fadeup:nth-child(2) .case_box .lazyload-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .study_cases_box_fadeup:nth-child(3) .case_box .lazyload-wrapper {
    display: flex;
    align-items: flex-end;
    max-width: calc(100% - 2.75rem);
    height: 100%;
    img {
      height: auto;
    }
  }
}

.services-cases-rounded {
  .study_cases_box .study_cases_box_fadeup.study_cases_box_fadeup {
    border-radius: 12px;
  }
}

.services-cases-text-centered {
  .case_box {
    gap: 1rem;
    min-height: unset;
  }

  .case_box p,
  .case_box h3 {
    text-align: center;
    align-self: center;
  }

  .case_box .medium12px {
    font-size: 12px;
  }
}

.services-cases_container.services-cases-square-size {
  .case_box,
  .study_cases_box_fadeup {
    height: 575px;
    width: 575px;
  }

  .study_cases_box_fadeup .case_box .lazyload-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .services-cases_container {
    .case_box,
    .study_cases_box_fadeup {
      width: 100%;
      height: 100%;
    }
    .logo_box img {
      height: 575px;
    }
  }

  .services-cases_container .meest-case .lazyload-wrapper img {
    margin-bottom: -2.5rem;
  }

  .services-cases_container.services-cases-square-size {
    .case_box,
    .study_cases_box_fadeup {
      height: 575px;
      max-width: 343px;
      width: auto;

      img {
        margin-bottom: 0 !important;
        transform: scale(1.5);
      }
    }
  }

  .services-cases_container {
    .allCaseBtn {
      margin-top: 1rem;
    }
  }
}

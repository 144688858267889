.upload-btn-container {
  cursor: pointer;
  font-family: 'Avenir-Heavy-05', sans-serif;

  .btn-upload {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0;
    border-radius: 8px;
    color: var(--title-text-color);
    font-size: 18px;
    line-height: 140%;
    & > span {
      color: #3095f2;
      margin-right: 5px;
      text-decoration: underline;
    }
  }

  input[type='file'] {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  ::-webkit-file-upload-button {
    cursor: pointer;
  }

  .error {
    color: red;
  }
}

.drop {
  cursor: pointer;
}

.file-name {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-size: 18px;
  line-height: 140%;
  color: var(--title-text-color);
  word-break: break-all;
}

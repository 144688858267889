.promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.promoted-checkbox {
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  gap: 16px;
  input:checked {
    background-color: blue;
  }
  input:checked + label > svg {
    height: 24px;
  }
  label:active::after {
    background-color: #e6e6e6;
  }
  input[type='checkbox']:checked + label {
    &:after {
      background-color: #3095f2;
      border: none;
      overflow: hidden;
    }
  }
  label {
    margin-top: 4px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    &:after {
      content: '';
      height: 24px;
      width: 24px;
      float: left;
      border: 2px solid #e2e2e2;
      border-radius: 3px;
      transition: 0.15s all ease-out;
    }
  }
  svg {
    fill: white;
    height: 0; //Firefox fix
    width: 24px;
    position: absolute;
    left: 2px;
    top: 1px;
  }
}


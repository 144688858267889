
@media screen and (max-width: 1200px) {
  .margin {
    margin: auto 4rem;
  }
}

@media screen and (max-width: 700px) {
  .margin {
    margin: auto 2rem;
  }
}

@media screen and (max-width: 575px) {
  .margin {
    margin: auto 1rem;
  }
}
.center {
  max-width: 1400px;
}
.eargymContainer{
  margin-bottom: 5rem;
  width: 100%;
  background-color: #ffff;
}
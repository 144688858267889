.faq_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .faqs_box {
    margin-top: 60px;
    max-width: 770px;
    width: 100%;
    .faq_box {
      padding: 24px 0;
      border-top: 1px solid #c9d1e3;
      &:first-child {
        border-top: none;
      }

      .faq_title_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 24px;
      }

      .faq_text_box {
        color: var(--p-text-color);
        box-sizing: border-box;
        transition: all 0.35s ease-in-out;
      }
      .hideText {
        overflow: hidden;
        max-height: 0;
      }
      .showText {
        max-height: 250px;
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 910px) {
    .faqs_box {
      .faq_box {
        padding: 16px 0;
      }
    }
  }
}
.faq_container-dark {
  background: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  --title-text-color: #333;
  --p-text-color: #4b4b4b;
}
.plusminus_active {
  &:before {
    transform: translatey(-50%) rotate(-90deg);
    opacity: 0;
  }
  &:after {
    transform: translatey(-50%) rotate(0);
  }
}
.plusminus_inactive {
  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
}
.plusminus {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  min-width: 14px;
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #3095f2;
    position: absolute;
    top: 50%;
    left: 0;
    transition: all 0.35s ease-in-out;
    width: 100%;
    height: 2px;
    border-radius: 2px;
  }
}

.career,
.modal-wrapper {
  .contact-form_careers {
    font-family: 'Avenir-Medium-09', sans-serif;
    margin: 1rem 0;
    .contact-form-margin {
      position: relative;
    }
    .contact-form-group {
      border: 1px solid #d6dde7;
      border-radius: 5px;
      padding: 0 0.7rem;

      &.error {
        background: #fff6f7;
        border-color: #ff4547;
      }

      .message {
        padding: 0.7rem 0.3rem;
        resize: none;
      }

      &:focus-within {
        border-color: #0085ff;
      }
    }
    .contact-form-textarea {
      background-color: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;
      padding: 0;
      color: var(--title-text-color);

      &::placeholder {
        font-family: 'Avenir-Roman-12', sans-serif;
        color: #b9c1cd;
      }
      &:focus {
        box-shadow: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
    .contact-form-input {
      background-color: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;
      padding: 0;
      color: var(--title-text-color);
      height: calc(1.5em + 0.75rem + 2px);

      &::placeholder {
        font-family: 'Avenir-Roman-12', sans-serif;
        color: #b9c1cd;
      }
      &:focus {
        box-shadow: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }
  .submit-btn {
    text-align: center;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    background: #3095f2;
    border: none;
    border-radius: 6px;
    padding: 0.9rem 0;
    width: 100%;

    &:hover {
      background: #1f7cd1;
    }

    &:focus {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      opacity: 0.65;
      color: #ffffff;
      cursor: not-allowed;
    }
  }
  .cancel-btn {
    font-size: 1rem;
    color: #f94006;
    box-shadow: none;

    &:active,
    &:focus {
      outline: none;
    }
  }

  .loading-icon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .send-approved {
    margin: 0;
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #209b25;
  }
  .send-dismissed {
    margin: 0;
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #d11f1f;
  }
  .file-invalid {
    right: unset;
  }
}

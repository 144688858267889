.product-main-shrink {
  font-family: 'Avenir-Black-03', sans-serif;
  margin: 5rem auto;
  padding: 5rem 0 3rem;
  background-position: bottom !important;
  background-size: contain !important;
  @media screen and (max-width: 1120px) {
    padding: 3rem 0;
  }
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }
  @media screen and (max-width: 767px) {
    background: none !important;
  }
  .product-main-title-shrink {
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 5rem;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: 1120px) {
      font-size: 2.5vw;
      line-height: 3.5vw;
      margin-top: 7vw;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 50px;
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
  .product-main-row-shrink {
    margin: auto 5rem;

    @media screen and (min-width: 1800px) {
      margin: auto calc(10rem - 15px);
    }
    @media screen and (max-width: 767px) {
      margin: auto 2rem;
    }
    .product-main-img-shrink {
      width: 100%;
    }
  }
  .mobile-wrapper {
    background-size: 0 0 !important;
    @media screen and (max-width: 767px) {
      backface-visibility: visible;
      background-size: 150% !important;
      background-position: -40vw center !important;
    }
  }
}

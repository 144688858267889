.product-screens {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0;
  margin: 0;
  position: relative;
  .margin {
    margin: auto 5rem;
  }
  .margin3 {
    margin: auto 3rem;
  }
  .position {
    margin: 5rem 5rem 0 0;
  }
  .screens {
    margin: 0 calc(6rem - 15px);
    @media screen and (min-width: 1800px) {
      margin: 0 calc(10rem - 15px);
    }
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 15px;
    &.screens-wrapper {
      padding: 0;
    }
  }
  .description {
    font-size: 1.4rem;
  }
  .product-screens-bg {
    position: absolute;
    width: 100vw;
    z-index: -1;
  }
  .product-screens-row1 {
    img {
      height: 100%;
      width: 100%;
    }
  }
  .product-screens-row2 {
    img {
      height: 100%;
      width: 85%;
    }
  }
  .title {
    font-family: 'Product Sans Regular', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: var(--title-text-color);
  }
  hr.hLine {
    border-top: 2px solid #e0e0e0;
  }

  @media screen and (min-width: 2000px) {
    .description {
      font-size: 2rem;
    }
    .position {
      margin: 5rem 10rem 0 0;
    }
    .title {
      font-size: 4rem;
    }
    .screens {
      justify-content: space-between;
    }
    .product-screens-row2 {
      img {
        width: 90%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .product-screens-row2 {
      img {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 910px) {
    .screens {
      margin: 0 3rem;
    }
    .margin {
      margin: auto 3rem;
    }
    .margin3 {
      margin: auto 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .product-screens-row2 {
      img {
        width: 70%;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .screens {
      justify-content: center;
    }
  }

  @media screen and (max-width: 575px) {
    margin: auto auto 0;
    .screens {
      margin: 0 1rem;

      .product-screens-row1,
      .product-screens-row2 {
        padding: 0;
      }
    }
    .margin {
      margin: auto 1rem;
    }
  }

  @media screen and (max-width: 320px) {
    .screens {
      .title {
        font-size: 25px;
      }
    }
  }
}

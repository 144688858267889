.privacy-policy {
  table,
  th,
  td {
    border: 1px solid black;
  }

  td {
    padding: 10px;
  }

  .privacy-policy_header {
    max-width: 1000px;
    p {
      color: var(--p-text-color);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }
  .description {
    max-width: 1000px;
    ol {
      font-family: 'Avenir-Medium-09', sans-serif;
      color: var(--p-text-color);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
    }
    ol:not(.numbered-list) {
      padding-left: 0;
    }
    ol:not(.numbered-list) > li {
      list-style-position: inside;
      color: var(--title-text-color);
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 32px;
      font-style: normal;
      line-height: normal;
      margin-top: 40px;
      margin-bottom: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
    ul {
      list-style-type: disc;
    }
    ul > li {
      color: var(--p-text-color);
      font-family: 'Avenir-Medium-09', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    p {
      color: var(--p-text-color);
      font-family: 'Avenir-Medium-09', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
    }
    h2 {
      color: #333;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 32px;
    }
    h3 {
      color: #333;
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 24px;
      font-style: normal;
      line-height: 40px;
      margin-top: 1.5rem;
    }
    b {
      font-family: 'Avenir-Heavy-05', sans-serif;
      color: #000;
    }
    .last-update {
      color: var(--p-text-color);
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 18px;
      font-style: normal;
      line-height: 32px;
    }
    @media screen and (max-width: 575px) {
      ol li {
        font-size: 16px;
        line-height: 28px;
      }
      ol:not(.numbered-list) > li {
        font-size: 24px;
      }
      ul > li {
        font-size: 16px;
      }
      h3 {
        font-size: 18px;
        line-height: normal;
      }
      p {
        font-size: 16px;
        line-height: 28px;
      }
      .last-update {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

.our-services-block {
  font-family: 'Avenir-Roman-12', sans-serif;

  .our-services-subtitle {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 32px;
    margin-bottom: 4rem;
  }

  .our-services-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px 90px;

    .our-service-item {
      max-width: 300px;
      text-align: center;

      h3 {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: 1200px) {
      gap: 60px 0;
    }
  }
}

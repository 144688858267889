.link_button button {
    background-color: #3095f2;
    border-radius: 6px;
    padding: 14px 34px;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Avenir-Heavy-05', sans-serif;
    border: none;

    @media screen and (max-width: 650px) {
        font-size: 1rem;
    }
  }


.career-contact-wrapper {
  color: var(--title-text-color);
  .title {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 24px;
    line-height: 36px;
  }

  .email-text {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    margin-top: 2rem;

    a {
      color: #0085ff;
    }
  }
  .recruiter {
    margin: 0 0 2rem;
    font-weight: 500;

    a {
      color: #0085ff;
    }
    .recruiter-photo {
      width: 120px;
      height: 120px;
      padding: 0;
    }
    .recruiter-name {
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 22px;
      line-height: 29px;
    }
    .recruiter-col {
      font-size: 16px;
      line-height: 22px;

      & > * {
        display: block;
      }
    }
  }
  @media screen and (min-width: 2000px) {
    .title {
      font-size: 30px;
      line-height: 36px;
    }
    .recruiter-col {
      font-size: 18px !important;
      line-height: 24px !important;
    }
    .recruiter-name {
      font-size: 26px !important;
    }
    .contact-form {
      .submit-btn {
        margin: 0 auto;
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .title {
      font-size: 26px;
      line-height: 32px;
    }
    .recruiter-col {
      padding: 0;
      padding-left: 0.7rem;
    }
    .recruiter {
      .recruiter-photo {
        width: 100px;
        height: 100px;
      }
      .recruiter-col,
      .email-text {
        font-size: 16px;
        line-height: 23px;
      }
      .recruiter-name {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    // margin-bottom: 5rem;
    .title {
      font-size: 30px;
      line-height: 36px;
    }
    .recruiter {
      .recruiter-col {
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        line-height: 25px;
      }
      .email-text {
        font-size: 18px;
        line-height: 140%;
      }
      .recruiter-photo {
        width: 120px;
        height: 120px;
      }
      .recruiter-name {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .title {
      font-size: 28px;
      line-height: 34px;
    }
    .recruiter {
      .recruiter-col {
        padding: 0;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        line-height: 22px;
      }
      .email-text {
        font-size: 18px;
        line-height: 140%;
      }
      .recruiter-photo {
        width: 100px;
        height: 100px;
      }
      .recruiter-name {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .title {
      font-size: 28px;
      line-height: 34px;
    }
    .recruiter {
      .recruiter-col {
        font-size: 14px;
        line-height: 20px;
      }
      .recruiter-photo {
        width: 90px;
        height: 90px;
      }
      .recruiter-name {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  @media screen and (max-width: 365px) {
    .recruiter {
      .recruiter-col {
        font-size: 11px;
        line-height: 17px;
      }
      .recruiter-photo {
        width: 75px;
        height: 75px;
      }
      .recruiter-name {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}

.loading-icon {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

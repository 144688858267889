.commit-wrapper-bg-1 {
  background: linear-gradient(180deg, #FFFCFC 9.93%, #BFF9B4 87.31%);
  mix-blend-mode: normal;
}

.commit-wrapper-bg-2 {
  background: linear-gradient(to top, #E2FCFB 9.93%, #BFF9B4 87.31%);
  mix-blend-mode: normal;
}


@media screen and (max-width: 575px) {
  .commit-wrapper-bg-1 {
    background: none;
  }
  
  .commit-wrapper-bg-2 {
    background: none;
  }
}

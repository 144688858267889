.aob-slider {
  margin-bottom: 5rem;
  .slick-track {
    display: flex;
    align-items: center;
  }
  img {
    height: 35vw;
  }

  @media screen and (max-width: 575px) {
    img {
      height: 100% !important;
      width: 100%;
    }
  }
}
.specialists-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 8rem;
  margin-top: 3.5rem;
}

.specialists-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.75rem;

  h3 {
    font-size: 1.5rem;
    text-align: center;
    line-height: 140%;
  }
}

@media screen and (max-width: 768px) {
  .specialists-wrapper {
    flex-direction: column;
    gap: 3rem;
  }
}

.services_container {
  background: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  transform: rotate(-180deg);

  .services_content_box {
    display: flex;
    gap: 3rem;
    transform: rotate(180deg);

    .content_title {
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-style: normal;
      font-size: 28px;
      line-height: 140%;
      color: var(--title-text-color);
      flex-basis: 50%;
      height: fit-content;

      h2 {
        text-align: left;
      }
    }

    .content_buttons {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .service_button {
        display: block;
        background: #ffffff;
        border-radius: 6px;
        padding: 2rem;
        transition: all 0.2s ease-in-out;

        .button_title {
          font-style: normal;
          font-size: 24px;
          line-height: 140%;
          display: flex;
          color: var(--title-text-color);
          align-items: center;
          display: flex;
          gap: 12px;

          & > div > span {
            color: #3095f2;
          }
        }
        .button_text {
          font-family: 'Avenir-Medium-09', sans-serif;
          margin: 1rem 0 0 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--p-text-color);
        }
        &:hover {
          transform: scale(1.01);
        }
      }
    }
  }

  @media screen and (max-width: 910px) {
    .services_content_box {
      flex-direction: column;

      .content_title {
        font-size: 22px;
        line-height: 140%;
        text-align: left;
      }
      .content_buttons {
        .service_button {
          padding: 1.5rem;

          .button_title {
            font-size: 22px;
            line-height: 140%;
          }
          .button_text {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.product-slider {
  .product-slider-img {
    height: 40vw;
    @media screen and (max-width: 575px) {
      height: unset;
      width: 90%;
    }
  }

  .slick-list {
    width: 100vw;
  }
}

.shrink-wrapper {
  .product-layout {
    height: 105rem;
    margin-bottom: 2rem;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 500px) {
      height: 90rem;
    }

    @media screen and (max-width: 400px) {
      height: 70rem;
    }
  }
}
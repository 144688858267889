.facts {
  .article-box {
    width: 100%;
    .multidisc-box-article {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25vw;
      line-height: 189%;
      color: #7c7c87;
    }
  }

  .facts-block-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 3rem;
    .fact-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem 0;
      background: #ffffff;
      border: 1px solid #e1e8ed;
      box-sizing: border-box;
      border-radius: 6px;

      & > span {
        font-size: 3vw;
        line-height: 57px;
        color: #3095f2;
        font-family: "Avenir-Heavy-05", sans-serif;
      }

      & > p {
        margin-top: 1rem;
        text-align: center;
        font-size: 1vw;
        line-height: 140%;
        font-weight: 500;
      }
    }
  }
  @media screen and (min-width: 2560px) {
    .article-box {
      .multidisc-box-article {
        font-size: 32px;
      }
    }
    .facts-block-container {
      .fact-block {
        & > span {
          font-size: 62px;
        }

        & > p {
          font-size: 26px;
        }
      }
    }
  }
  @media screen and ( max-width:1650px ) {
    .article-box {
    }
  }
  @media screen and ( max-width:1024px ) {
    .article-box {
      .multidisc-box-article {
        font-size: 14px;
      }
    }
    .facts-block-container {
      grid-template-columns: 1fr 1fr;
      .fact-block {
        & > span {
          font-size: 42px;
        }

        & > p {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and ( max-width:575px ) {
    .article-box {
      .multidisc-box-article {
        br {
          display: none;
        }
      }
    }
    .facts-block-container {
      grid-template-columns: 1fr;
    }
  }
}

.textdropzone_container {
  
  .textarea_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Avenir-Book-01', sans-serif;
    padding: 0.75rem 1rem;
    padding-right: 0.5rem;
    background: #ffffff;
    border-radius: 0.5rem;
    // position: relative;

    textarea {
      width: 100%;
      background: #ffffff;
      min-height: 100px;
      border: none;
      resize: none;
      overflow: auto;
      &::placeholder {
        font-family: 'Avenir-Book-01', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #97979d;
      }
    }

    .upload_btn {
      width: fit-content;
      cursor: pointer;
      bottom: 10px;
      left: 1rem;
      z-index: 1;
      & > svg {
        transform: rotate(-45deg);
      }
    }
  }

  input[type='file'] {
    display: none;
  }

  .file_box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px;

    .delete_btn_box {
      cursor: pointer;
    }
  }
}

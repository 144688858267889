.wrapper {
  padding: 0;
  position: relative;
  overflow: hidden;
  z-index: 100;

  .main-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .main-bg-dark {
    position: absolute;
    width: 100vw;
    aspect-ratio: 16/12.5;
    z-index: -1;
    background: linear-gradient(
      168deg,
      #01011f 14.65%,
      #0a2b6a 34.18%,
      #1f0861 52.47%,
      #01011f 78.25%
    );
  }

  @media screen and (max-width: 768px) {
    .main-bg-dark {
      background: linear-gradient(
        168deg,
        #01011f 14.65%,
        #0a2b6a 34.18%,
        #1f0861 52.47%,
        #01011f 78.25%
      );
      height: 90rem;
    }
  }

  .footer-bg {
    background: linear-gradient(
      -100deg,
      #f3f2ff 10.96%,
      #e8fffe 64.07%,
      #f5f6ff 104.31%
    );
  }

  .home-footer-bg {
    position: absolute;
    bottom: -3px;
    z-index: 1;
    width: 101%;

    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      width: 100%;
    }

    .mobile {
      z-index: 0;
    }
  }

  .footer {
    .responsive-page-top_bottom-gap {
      padding-bottom: 0px !important;
    }
    .contact-wrapper {
      @media screen and (min-width: 575px) {
        padding: 0;
      }
      .contact-form {
        .services-title {
          font-size: 1rem;
          line-height: 1.5rem;
          color: #7c7c87;
          font-weight: 500;
          margin-bottom: 16px;
        }
        ul.ks-cboxtags {
          list-style: none;
          margin-bottom: 50px;
          padding: 0;
          li {
            display: inline;
            margin-right: 1rem;
            label {
              display: inline-block;
              border: 1px solid #7c7c87;
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.5rem;
              border-radius: 28px;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -webkit-tap-highlight-color: transparent;
              transition: all 0.2s;
              padding: 0.5rem 1.5rem;
              cursor: pointer;

              &.checked {
                background-color: #3095f2;
                border-color: #3095f2;
                color: #ffffff;
                transition: all 0.2s;
              }
              &.unchecked {
                background-color: transparent;
                color: #7c7c87;
              }
            }
            input[type='checkbox'] {
              display: absolute;
            }
            input[type='checkbox'] {
              position: absolute;
              opacity: 0;
            }
          }
        }
        .contact-form-group {
          // border-bottom: 1px solid #7C7C87;
          // textarea {
          //   height: 2rem;
          // }
          .icon {
            display: flex;
            align-items: center;
          }
          .contact-form-input {
            color: #000;
            &::placeholder {
              color: #7c7c87;
            }
          }
        }
        @media screen and (max-width: 575px) {
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .home-footer-bg {
      bottom: -20rem;
      &.contact {
        bottom: -10rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .home-footer-bg {
      bottom: -15rem;
    }
  }

  @media screen and (max-width: 575px) {
    .footer {
      margin: auto 0;
    }
    .home-footer-bg {
      bottom: -15rem;
      &.contact {
        bottom: -5rem;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .home-footer-bg {
      bottom: -5rem;
      &.contact {
        bottom: 0;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .home-footer-bg {
      bottom: -3rem;
    }
  }
}

.footer-dark-bg {
  background: linear-gradient(
    168deg,
    #01011f 14.6%,
    #002160 35.08%,
    #1f0861 48.31%,
    #01011f 76.78%
  );
}

.wrapper-dark {
  --title-text-color: #fff;
  --p-text-color: #fff;
  --footer-link-color: rgba(255, 255, 255, 0.6);

  .footer-bg {
    background: linear-gradient(
      168deg,
      #01011f 14.6%,
      #002160 35.08%,
      #1f0861 48.31%,
      #01011f 76.78%
    );
  }
}

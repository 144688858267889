.show-more {
  .show-more-btn {
    color: #3095f2;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-weight: inherit;
    cursor: pointer;

    &:hover {
      color: #3095f2;
    }
  }
}

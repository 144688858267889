.product-main-aob {
  font-family: 'Avenir-Book-01', sans-serif;
  margin: 5rem auto;
  margin-top: -3rem;
  padding: 13rem 0 0;
  background: linear-gradient(60.37deg, #0d5472 11.3%, #29a3c1 96.34%);
  color: #ffffff;
  .product-main-wrapper-aob {
    background-position: bottom !important;
    background-size: contain !important;

    @media screen and (max-width: 767px) {
      background: none !important;
    }
  }
  @media screen and (max-width: 1120px) {
    padding: 10rem 0 0;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    padding: 1rem 0 0;
  }
  .product-main-col-desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .product-main-title-aob {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 5rem;
    &.mobile {
      display: none;
      text-align: center;
    }
    @media screen and (max-width: 1120px) {
      font-size: 3vw;
      line-height: 4vw;
      margin-top: 5vw;
    }
    @media screen and (max-width: 767px) {
      font-size: 50px;
      line-height: 50px;
      &.mobile {
        display: block;
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .product-main-text-aob {
    font-size: 20px;
    line-height: 140%;
    margin-top: 1rem;
    width: 32.8rem;
    &.mobile {
      display: none;
      text-align: center;
    }
    @media screen and (max-width: 1120px) {
      font-size: 2vw;
      line-height: 3vw;
      margin-top: 3vw;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.8vw;
      line-height: 2.8vw;
      width: 25rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 50px;
      &.mobile {
        display: block;
        width: auto;
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 14px;
      line-height: 140%;
    }
  }
  .product-main-row-aob {
    margin: auto 5rem;

    @media screen and (min-width: 1800px) {
      margin: auto calc(10rem - 15px);
    }
    @media screen and (max-width: 767px) {
      margin: 7rem 4rem 0;
    }
    @media screen and (max-width: 700px) {
      margin: 5rem 2rem 0;
    }
    .product-main-col-aob {
      padding-bottom: 3rem;
      margin: 0 auto;

      @media screen and (max-width: 1120px) {
        padding-bottom: 5rem;
      }
    }

    .product-main-img-aob {
      width: 100%;
    }
  }
  .mobile-wrapper {
    background-size: 0 0 !important;
    @media screen and (max-width: 767px) {
      backface-visibility: visible;
      background-size: 100% !important;
      background-position: bottom center !important;
    }
  }

  @media screen and (min-width: 1800px) {
    .product-main-title-aob {
      font-size: 4.5vw;
      line-height: 4vw;
    }
    .product-main-text-aob {
      font-size: 2rem;
      width: 50rem;
      margin-top: 2rem;
    }
  }
}

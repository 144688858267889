.product-compare {
    background: #F5F7F8;
    .product-compare-row {
        margin: auto 2rem;
        // @media screen and (max-width: 768px) {
        //     margin: auto 0;
        // }
        @media screen and (min-width: 1800px) {
            margin: auto 6rem;
        }
        padding: 5rem 0;
        .product-compare-col {
            @media screen and (max-width: 768px) {
                margin-bottom: 50px;
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
            @media screen and (max-width: 575px) {
                
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-last-child(2) {
                    margin-bottom: 50px;
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        display: none;
    }
}


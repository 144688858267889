.product-main-big-ticket {
  font-family: 'Avenir-Book-01', sans-serif;
  margin: 0 auto 5rem;
  padding: 0;
  background: #ee9329;
  color: #ffffff;
  .product-main-wrapper-big-ticket {
    background-position: top !important;
    background-size: 100% 50% !important;

    @media screen and (max-width: 767px) {
      background: none !important;
    }
  }
  .product-main-col-desktop {
    margin-top: 5rem;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .product-main-title-big-ticket {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 3vw;
    line-height: 4vw;
    margin-top: 5rem;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: 1120px) {
      font-size: 3vw;
      line-height: 4vw;
      margin-top: 5vw;
    }
    @media screen and (max-width: 767px) {
      font-size: 50px;
      line-height: 50px;
      &.mobile {
        display: block;
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .product-main-text-big-ticket {
    font-size: 20px;
    line-height: 140%;
    margin-top: 1rem;
    width: 32.8rem;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: 1120px) {
      font-size: 2vw;
      line-height: 3vw;
      margin-top: 2vw;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.8vw;
      line-height: 2.8vw;
      width: 20rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 50px;
      &.mobile {
        display: block;
        width: auto;
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 14px;
      line-height: 140%;
    }
  }
  .product-main-row-big-ticket {
    margin: auto 5rem;
    padding-top: 7rem;

    @media screen and (min-width: 1800px) {
      margin: auto calc(10rem - 15px);
    }
    @media screen and (max-width: 1300px) {
      // padding-top: 5rem;
    }
    @media screen and (max-width: 767px) {
      margin: 0 4rem 0;
      text-align: center;
      padding: 5rem 0;
    }
    @media screen and (max-width: 700px) {
      margin: 0 2rem;
    }
    @media screen and (max-width: 575px) {
      padding: 10rem 0 5rem;
    }
    .product-main-col-big-ticket {
      padding-bottom: 3rem;
      margin: 0 auto;

      @media screen and (max-width: 1120px) {
        padding-bottom: 5rem;
      }
    }

    .product-main-img-big-ticket {
      width: 100%;
    }
  }
  .mobile-product-main-row-big-ticket {
    @media screen and (max-width: 767px) {
      margin: 0 2rem 0;
      text-align: center;
      padding: 15rem 0 5rem;
    }

    @media screen and (max-width: 375px) {
      padding: 20rem 0 5rem;
    }
  }
  .mobile-wrapper {
    background-size: 0 0 !important;
    @media screen and (max-width: 767px) {
      backface-visibility: visible;
      background-size: 100% !important;
      background-position: top center !important;

      .product-main-img-big-ticket {
        width: 100%;
      }
      .product-main-col-big-ticket {
        margin-bottom: -20rem;
        padding: 0;
      }
    }
    @media screen and (max-width: 575px) {
      .product-main-img-big-ticket {
        width: 80%;
      }
      .product-main-col-big-ticket {
        margin-bottom: -20rem;
      }
    }
    @media screen and (max-width: 375px) {
      .product-main-img-big-ticket {
        width: 100%;
      }
      .product-main-col-big-ticket {
        margin-bottom: -25rem;
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .product-main-title-big-ticket {
      font-size: 4.5vw;
      line-height: 4vw;
    }
    .product-main-text-big-ticket {
      font-size: 2rem;
      width: 52rem;
      margin-top: 2rem;
    }
    .product-main-col-desktop {
      margin-top: 15rem;
    }
  }
}

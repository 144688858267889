.reverseContainer {
  margin-top: 8rem;
  .reverseBoxFade {
    display: flex;
    justify-content: center;
    .contentBox {
      display: flex;
      align-items: center;
      width: 1170px;
      .imageBox {
        height: 570px;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .textBox {
        & > div {
          font-family: 'Avenir-Heavy-05', sans-serif;
          color: #0a0a0a;
          font-style: normal;
          font-size: 32px;
          line-height: 140%;
          margin-bottom: 2rem;
        }
        & > span {
          font-family: 'Avenir-Book-01', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 160%;
          color: var(--title-text-color);
        }
      }
    }
  }
  .reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 1200px) {
  .reverseContainer {
    padding: 0;

    .reverseBoxFade {
      justify-content: start;
      // width: 100%;
      .contentBox {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 100%;
        flex-direction: column-reverse;
        .textBox > span {
          & > br {
            display: none;
          }
        }
        .textBox {
          padding: 0;
        }
        .imageBox {
          margin-top: 2.5rem;
          width: 100%;
          padding: 0;
          height: auto;
          & > img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .reverseContainer {
    margin-top: 6rem;
    .reverseBoxFade {
      .contentBox {
        .textBox > span {
          font-size: 16px;
          line-height: 160%;
          & > br {
            display: none;
          }
        }
        .textBox > div {
          font-size: 24px;
          line-height: 140%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.careers-info {
  font-family: 'Avenir-Medium-09', sans-serif;
  color: var(--title-text-color);
  font-size: 18px;
  line-height: 140%;

  .title-box {
    .title {
      font-style: normal;
      font-weight: inherit;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #3095f2;
    }
    .text {
      margin-top: 20px;

      font-family: 'Avenir-Heavy-05';
      font-style: normal;
      font-size: 32px;
      line-height: 140%;
      /* or 45px */

      color: var(--title-text-color);
    }
  }

  .email-text {
    margin: 0;
    margin-left: 1rem;
    // font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--title-text-color);

    a {
      margin-left: 0.75rem;
    }
  }

  .description {
    font-family: 'Avenir-Light-07', sans-serif;
    font-size: 24px;
    line-height: 150%;
  }
  .careers-info__cv {
    display: flex;
    margin-top: 50px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    align-items: center;
  }
  .careers-info-btn {
    font-family: 'Avenir-Heavy-05', sans-serif;
    background: #3095f2;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    line-height: 25px;
    padding: 14px 31px;
    color: #ffffff !important;
    cursor: pointer;

    &:hover {
      background: #1f7cd1;
    }

    &:focus {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    }
  }

  @media screen and (min-width: 2000px) {
    .title {
      font-size: 20px;
      line-height: 63px;
    }
    .text {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 1700) {
    .careers-info_Box {
      margin: 0 4rem;
    }
  }

  @media screen and (max-width: 575px) {
    .title {
      font-family: 'Product Sans Bold', sans-serif;
      font-size: 14px !important;
      line-height: 20px !important;
    }
    .text {
      font-size: 22px !important;
      line-height: 31px !important;
    }
    .careers-info__cv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .careers-info-btn {
        width: 100%;
        margin-bottom: 24px;
      }
      .email-text {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        & > a {
          margin-top: 20px;
        }
      }
    }
    .careers-info-btn,
    .email-text {
      margin-bottom: 2rem;
      font-size: 16px;
      line-height: 23px;
    }
    .description {
      font-size: 18px;
      line-height: 150%;
    }
  }
  @media screen and (max-width: 430px) {
    br {
      display: none;
    }
  }
}

.steps-container {
  min-height: 100vh;
  background: linear-gradient(145deg, #f3f2ff 0%, #e8fffe 55.21%, #f5f6ff 100%);

  h2 {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 32px;
    margin-bottom: 1.2rem;
  }

  .steps-item {
    display: flex;
    gap: 27px;
    align-items: center;
    padding: 15px 0;
    font-size: 24px;
    font-family: 'Avenir-Roman-12', sans-serif;

    span {
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 32px;
      color: #3095f2;
      background-color: #ffffff;
      border-radius: 50%;
      text-align: center;
      width: 96px;
      min-width: 96px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

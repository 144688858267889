.support-container {
  background: linear-gradient(
    145deg,
    hsl(245, 100%, 97%) 0%,
    #e8fffe 55.21%,
    #f5f6ff 100%
  );
  transform: rotate(180deg);
}

.support-container-dark {
  background: #020220;
}

.support-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 4rem;
  transform: rotate(180deg);

  .support-block-text {
    flex: 1 0 47%;
    max-width: 570px;

    h2 {
      text-align: left;
      margin-bottom: 1.5rem;
    }
  }

  img {
    max-width: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .support-block {
    flex-wrap: wrap;
  }

  .support-block img {
    width: 100%;
    max-width: 80vw;
  }

  .support-container {
    .support-block-text h2 {
      text-align: center;
    }
    .medium18px {
      text-align: center;
    }
  }
}

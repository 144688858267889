.cases_container {
  position: relative;
  --case-block-width: 575px;
  --case-block-height: 575px;

  .cases_title_box {
    display: flex;
    justify-content: center;
  }

  .study_cases_box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 60px;
  }

  .study_cases_box_fadeup {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    width: var(--case-block-width);
    height: var(--case-block-height);

    &:nth-child(1) {
      & > div {
        margin-top: -50px;
      }
    }

    &:nth-child(2) {
      & > .case_box > .lazyload-wrapper {
        margin-top: -165px;
        height: 130%;

        img {
          object-fit: cover;
        }
      }
    }

    &:nth-child(2) .lazyload-wrapper img {
      width: auto;
    }

    &:nth-child(4) {
      & > .case_box > div {
        padding-left: 37px;
        padding-right: 37px;
      }
    }

    .case_box {
      background-repeat: repeat-x !important;
      overflow: hidden;
      display: flex;
      transition: all 0.2s ease-in-out;
      flex-direction: column;
      align-items: center;
      padding-top: 1.5rem;
      gap: 24px;
      height: var(--case-block-height);
      width: var(--case-block-width);

      &:hover {
        transform: scale(1.01);
      }

      // &:last-child {
      //   background-size: 50% !important;
      // }

      .lazyload-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .heavy16px {
      text-align: center;
    }
    .medium12px {
      text-align: center;
    }
  }

  .allCaseBtn {
    margin-top: 40px;
    .allCaseBtn_flex {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.5rem;
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-style: normal;
      line-height: 140%;
      height: 16px
    }
  }
  .logo_box {
    position: absolute;
    height: var(--case-block-height);
    width: var(--case-block-width);
    padding: 0 !important;
    // left: -17.72px;
    top: 0;
    & > img {
      // width: auto !important;
      // height: auto !important;
      height: var(--case-block-height);
      width: var(--case-block-width);
      opacity: 0.1;
      position: relative !important;
      // object-fit: cover;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0%, 0%) !important;
    }
    @media screen and (max-width: 575px) {
      & > img {
        width: 100% !important;
        height: auto !important;
      }
      & > p > br {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .study_cases_box {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      margin-top: 40px;
      .study_cases_box_fadeup {
        .heavy16px {
          z-index: 10;
          width: 100%;
          font-family: 'Avenir-Medium-09', sans-serif;
        }
        .medium12px {
          z-index: 10;
          width: 100%;
        }

        .heavy24px {
          font-family: 'Avenir-Heavy-05', sans-serif;
        }

        &:nth-child(3) {
          & > .case_box > div {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        &:nth-child(3) {
          display: none;
        }
        .case_box {
          border-radius: 4px;
          gap: 16px;
          padding-top: 24px;
          position: relative;
          align-items: start;
          & > div {
            z-index: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: 710px) {
    & {
      --case-block-width: 100%;
      --case-block-height: 500px;
    }

    .study_cases_box {
      .study_cases_box_fadeup {
        &:nth-child(4) {
          & > .case_box > div {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        .heavy16px {
          padding-left: 24px;
          padding-right: 24px;
          text-align: start;
        }
        .medium12px {
          padding-left: 24px;
          padding-right: 24px;
          text-align: start;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .cases_container {
      --case-block-width: 100%;
      --case-block-height: 500px;
    }
    .study_cases_box {
      .study_cases_box_fadeup {
        height: 500px;
        max-width: 343px;
        width: 100%;

        &.burn-case .lazyload-wrapper img {
          width: 130%;
        }
        &.todo-case .lazyload-wrapper img {
          width: 140%;
        }

        .case_box {
          height: var(--case-block-height);
          & > p > br {
            display: none;
          }
        }
      }
    }
  }
}

.three-cases_container {
  .study_cases_box {
    gap: 1.5rem;

    .study_cases_box_fadeup,
    .case_box {
      border-radius: 0;
    }
  }
}

.about-faq-container {
  background: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  transform: rotate(-180deg) !important;
  width: 100%;
  .about-faq {
    .list-box {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .margin {
        margin: auto 6rem;

        @media screen and (max-width: 911px) {
          margin: auto 4rem;
        }
      }
      .loadMoreBox {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        & > div {
          position: relative;
          font-family: 'Avenir-Heavy-05', sans-serif;
          font-style: normal;
          font-weight: inherit;
          font-size: 1.1vw;
          line-height: 140%;
          color: var(--title-text-color);
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: -2px;
            left: 0;
            background: #3095f2;
            transform-origin: bottom center;
            transition: transform 0.25s ease-out;
          }
          &:hover:after {
            transform: scaleX(1);
            transform-origin: bottom center;
          }
        }
        @media screen and (min-width: 2560px) {
          & > div {
            font-size: 28px;
          }
        }
        @media screen and (max-width: 1600px) {
          & > div {
            font-size: 18px;
          }
        }
        @media screen and (max-width: 1024px) {
          & > div {
            &::after {
              transform: scaleX(1);
            }
          }
        }
      }
      .list-item-box {
        max-width: 730px;
        width: 100%;
        .item-head {
          width: 100%;
          margin-bottom: 1.5rem;
          cursor: pointer;
          display: flex;
          border-bottom: 1px solid #c9d1e3;

          .list-item-number {
            font-family: 'Avenir-Black-03', sans-serif;
            font-size: 1.5vw;
            color: #7c7c87;
            line-height: 150%;
          }

          .list-item-mark {
            right: 0;
            //   position: absolute;
            color: #3095f2;
            font-size: 32px;
          }
          .item-text-box {
            margin-bottom: 1.5rem;
            position: relative;
            padding-right: 2rem;
            margin-left: 2.5rem;
            width: 100%;
            .list-item-title {
              line-height: 150%;
            }
            .active {
              color: #3095f2;
            }
            & > p {
              margin-top: 1rem;
              font-size: 1.1vw;
              line-height: 150%;
              color: #7c7c87;
            }
            .hidden {
              width: 100%;
              -webkit-transition: max-height 0.4s;
              -moz-transition: max-height 0.4s;
              -ms-transition: max-height 0.4s;
              -o-transition: max-height 0.4s;
              transition: max-height 0.4s;
              max-height: 0;
              overflow: hidden;
              margin: 0;
            }
            .slide {
              margin: 1rem 0 0 0;
              transition: max-height 0.8s;
              max-height: 500px;
            }
            .item-list-box {
              transition: max-height 0.8s;
              width: 100%;
              margin-top: 1rem;
              max-height: 500px;
            }
          }
        }
      }
    }
    transform: rotate(-180deg);
  }
  @media screen and (min-width: 2560px) {
    .about-faq {
      .list-box {
        .list-item-box {
          max-width: 1240px;
          .item-head {
            .list-item-number {
              font-size: 38.4px;
            }
            .item-text-box {
              .list-item-title {
                font-size: 38.4px;
              }
              & > p {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
    .item-list-box-title {
      font-size: 26px;
    }
    .item-list-elem {
      font-size: 26px;
    }
  }
  @media screen and (max-width: 1024px) {
    .about-faq {
      .loadMoreBox {
        margin-top: 40px !important;
      }
      display: block;
      .list-box {
        margin-top: 3rem;
        .list-item-box {
          .item-head {
            .list-item-number {
              font-size: 16px;
            }
            .list-item-mark {
              font-size: 16px;
            }
            .item-text-box {
              margin-left: 1.4rem;
              .list-item-title {
                font-size: 16px;
              }
              & > p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .item-list-box-title {
      font-size: 14px;
    }
    .item-list-elem {
      font-size: 14px;
    }
  }
}
.item-list-box-title {
  font-size: 1.1vw;
  line-height: 150%;
  color: #7c7c87;
}
.item-list-elem {
  margin-top: 1rem;
  font-size: 1.1vw;
  line-height: 150%;
  color: #7c7c87;
}

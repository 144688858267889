.our-services {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .medium16px {
    color: var(--p-text-color);
  }
  h3 {
    color: #3095f2;
  }
}

.our-services-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 30px;
}

.our-services-item {
  display: flex;
  flex-direction: column;
  width: inherit;
  // width: 370px;
  // min-width: 270px;
  height: auto;
  padding: 1.5rem;
  gap: 0.5rem;
  background: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
}

.wrapper-dark .our-services-item {
  background-color: transparent;
  border: 1px solid #393957;

  .medium16px {
    opacity: 0.8;
  }
  h3 {
    color: var(--title-text-color);
  }
}

@media screen and (max-width: 450px) {
  .our-services-item {
    min-width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  .our-services-item {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .our-services-wrapper {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .our-services-wrapper {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.perpetio-title {
  font-weight: inherit;
  font-size: 1.1rem;
  line-height: 140%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3095f2;
  margin-bottom: 1.4rem;
  font-family: 'Avenir-Roman-12', sans-serif;
  @media screen and (min-width: 2560px) {
    font-size: 28px;
    margin-bottom: 35px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.perpetio-subtitle {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-size: 32px;
  line-height: 140%;
  color: var(--title-text-color);
  margin-bottom: 3.5vw;

  @media screen and (min-width: 2560px) {
    font-size: 56px;
    margin-bottom: 89px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
    margin-bottom: 50px;
    width: 100%;
    br {
      display: none;
    }
  }
}

.meest-onboarding-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  color: #ffffff;
  padding: 8rem 5rem 0;
  background: #ce2846;
  border-bottom-left-radius: 10rem;

  .meest-onboarding-title {
    font-weight: inherit;
    font-size: 50px;
    line-height: 140%;
  }

  .meest-onboarding-text {
    font-size: 18px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.8);
  }

  .meest-onboarding-row {
    align-items: center;
    justify-content: space-between;
    padding: 5rem 2rem;
    padding-left: 0;
    margin-left: 1.5rem;
    border-right: 2px dashed #ffffff;
    border-top: 2px dashed #ffffff;
    border-top-right-radius: 4rem;
    position: relative;

    div {
      margin-left: -1.5rem;
    }

    &:before {
      content: '';
      position: absolute;
      width: 50px;
      height: 125px;
      left: -2.6rem;
      border-left: 2px dashed #ffffff;
      border-bottom-left-radius: 4rem;
      top: -7.8rem;
    }

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      right: -10px;
      top: 54.7%;
      background-color: #ce2846;
      border: 2px solid #ffffff;
      border-radius: 50%;
      z-index: 1;
    }

    .meest-onboarding-col {
      flex: 0 0 30%;
      max-width: 30%;
      &:nth-child(2) {
        margin-left: -2.5rem;
        margin-top: 5rem;
      }
      &:nth-child(3) {
        margin-top: 10rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (min-width: 1800px) {
    .meest-onboarding-title {
      font-size: 4rem;
    }

    .meest-onboarding-text {
      font-size: 2.1rem;
    }
    .meest-onboarding-row {
      padding: 5rem 4rem;
      .meest-onboarding-col {
        flex: 0 0 27%;
        max-width: 27%;
      }
    }
  }
  @media screen and (max-width: 911px) {
    padding: 8rem 3.5rem 0;
  }
  @media screen and (max-width: 767px) {
    .meest-onboarding-row {
      .meest-onboarding-col {
        padding: 0;

        &:nth-child(2) {
          margin-left: -1.5rem;
        }
      }
    }
  }
}

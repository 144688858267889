.recent-posts {
  margin: 8.5rem;

  .posts-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.8rem;
    margin-top: 3rem;
  }

  .published-date {
    color: #7c7c87;
    font-size: 1rem;
    font-style: normal;
    line-height: 150%;
  }

  .post {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .post-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: linear-gradient(84deg, #d2f4ff 1.05%, #f1e5ff 88.24%);
    padding: 3rem 0 3rem;
    flex: 1 0 50%;
    color: #000;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    height: 100%;
    overflow: hidden;

    img {
      max-width: 35rem;
      object-fit: cover;
      height: 15rem;
      border-radius: 30px;
    }
  }

  .read-more-link {
    align-self: center;
    font-size: 18px;
    font-family: 'Avenir-Medium-09', sans-serif;
    font-weight: inherit;
    padding-left: 2rem;

    svg {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 768px) {
  .recent-posts {
    margin: 6.25rem 1rem 0;

    .post-content {
      padding: 0.5rem 0;
      img {
        width: 120%;
        object-fit: contain;
        margin-left: -2rem;
      }
    }
  }
}

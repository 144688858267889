.reviews-block {
  padding: 7.5rem 0 7.5rem;

  h2 {
    margin-bottom: 3.5rem;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    height: 400px;
    margin-bottom: 3rem;

    .review-wrapper {
      display: flex;
      align-items: stretch;
      height: 100%;
    }

    .review {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
    }
  }
}

.reviews-block-bg {
  background: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  --title-text-color: #333333;
  --p-text-color: #4b4b4b;

  .review {
    background-color: #ffffff;
    border: none;
  }
}

.review {
  border: 1px solid #bebebe;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 2rem 1.25rem 0.5rem;
  position: relative;

  .review-quotes {
    position: absolute;
    right: 1.5rem;
    top: -1.5rem;
  }
}

.reviews-block-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.reviews-block-button {
  border: none;
  height: 44px;
  width: 44px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  padding: 0;
  &:active {
    background-color: #d9ecff;
  }
  &:hover {
    box-shadow: 0 0 0 0.5px #3095f2;
  }
}

.reviews-block-page {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  font-family: 'Avenir-Medium-09', sans-serif;
  color: #7c7c87;

  & > span {
    color: #3095f2;
  }
}

.review-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .review-author-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: var(--title-text-color);
    font-size: 1.125rem;
    line-height: normal;
    font-family: 'Avenir-Medium-09', sans-serif;

    .review-author-name {
      font-family: 'Avenir-Black-03', sans-serif;
    }

    .review-author-position {
      font-size: 1rem;
    }
  }

  .review-author-img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}

.review-text {
  color: var(--p-text-color);
  font-size: 1rem;
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
}

.review-logo {
  margin-top: 1.5rem;
  justify-content: flex-end;
  display: flex;
  height: 100%;
  flex-direction: column;
  min-height: 1.5rem;

  img {
    max-width: 6rem;
    max-height: 3rem;
  }
}

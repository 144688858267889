:root {
  --title-text-color: #333333;
  --p-text-color: #4b4b4b;
  --footer-link-color: rgba(51, 51, 51, 0.6);
  --dark-theme-bg-color: #020220;
  --white-text-color: #ffffff;
  --main--blue-color: #4aa8ff;
  --light-gray-color: #f1f1f1;
  --medium-gray-color: #7c7c87;
  --dark-gray-color: #393957;
  --light-blue-color: #ECF1FA;
}

html {
  scroll-behavior: auto !important;
}
body {
  // max-width: 2560px;
  scroll-behavior: auto !important;
  margin: 0 auto;
  padding: 0;
  a:hover {
    text-decoration: none !important;
  }

  .text-black-32px {
    font-family: 'Avenir-Black-03', sans-serif;
    font-style: normal;
    font-size: 32px;
    line-height: 140%;
    @media screen and (max-width: 910px) {
      font-size: 22px;
      line-height: normal;
    }
  }

  .text-black-24px {
    font-family: 'Avenir-Black-03', sans-serif;
    font-style: normal;
    font-size: 24px;
    line-height: 140%;
    @media screen and (max-width: 910px) {
      font-size: 20px;
    }
  }

  .text-heavy-32px {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 32px;
    line-height: 140%;
    margin-bottom: 0;
    @media screen and (max-width: 910px) {
      font-size: 22px;
      line-height: normal;
    }
    @media screen and (min-width: 2000px) {
      font-size: 36px;
    }
  }

  .text-heavy-24px {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 0;
    @media screen and (max-width: 910px) {
      font-size: 20px;
    }
    @media screen and (min-width: 2000px) {
      font-size: 28px;
    }
  }
  .text-heavy-20px {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 140%;
    @media screen and (max-width: 910px) {
      font-size: 16px;
    }
  }
  .text-heavy-16px {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 120%;
    @media screen and (max-width: 910px) {
      font-weight: 500;
      line-height: 140%;
    }
    @media screen and (min-width: 1800px) {
      font-size: 18px;
    }
  }
  .text-medium-12px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    @media screen and (max-width: 910px) {
      font-size: 16px;
      line-height: 140%;
    }
    @media screen and (min-width: 1800px) {
      font-size: 16px;
    }
  }

  .text-medium-14px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    @media screen and (max-width: 910px) {
      font-size: 14px;
      line-height: 140%;
    }
    @media screen and (min-width: 1800px) {
      font-size: 16px;
    }
  }
  .text-medium-32px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 140%;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
      line-height: normal;

      & > br {
        display: none;
      }
    }
  }

  .text-medium-28px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    @media screen and (max-width: 910px) {
      font-size: 22px;
      line-height: 130%;
    }
    @media screen and (min-width: 1800px) {
      font-size: 30px;
    }
  }

  .text-medium-24px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    @media screen and (max-width: 1300px) {
      & > br {
        display: none;
      }
    }
    @media screen and (max-width: 910px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .text-medium-22px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    @media screen and (max-width: 1300px) {
      & > br {
        display: none;
      }
    }
    @media screen and (max-width: 910px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .text-medium-52px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    @media screen and (max-width: 910px) {
      font-size: 42px;
      line-height: normal;
    }
  }
  .text-medium-18px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    @media screen and (max-width: 910px) {
      font-size: 18px;
      line-height: normal;
    }
  }

  .text-medium-16px {
    font-family: 'Avenir-Medium-09', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    @media screen and (max-width: 910px) {
      & > br {
        display: none;
      }
    }
  }
  .text-mixed-13px {
    font-family: 'Avenir-Roman-12', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    @media screen and (max-width: 910px) {
    }
  }
  .text-blue-uppercase {
    font-family: 'Avenir-Medium-09', sans-serif;
    color: #3095f2;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 1.2rem;
  }
}
.max-width-container {
  max-width: 2560px;
  margin-right: auto;
  margin-left: auto;
}
.responsive-page-head-top_bottom-gap {
  padding-top: 10rem;
  padding-bottom: 7.5rem;

  @media screen and (max-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 4rem;
  }
}
.responsive-page-top_bottom-gap {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  @media screen and (max-width: 910px) {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}
.responsive-container {
  margin: auto 8.4rem;
  @media screen and (min-width: 1800px) {
    margin: auto 9rem;
  }
  @media screen and (max-width: 910px) {
    margin: auto 3rem;
  }
  @media screen and (max-width: 700px) {
    margin: auto 2rem;
  }
  @media screen and (max-width: 575px) {
    margin: auto 1rem;
  }
}
.margin-top {
  margin-top: 0rem;
  @media only screen and (max-width: 1799px) {
    margin-top: 0rem;
  }
  @media only screen and (max-width: 1023px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0rem;
  }
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background: none;
}
.positions .toggle:checked + label .positions-chevron-icon-color {
  fill: #3095f2;
}
nav.fixed-top {
  z-index: 999;
}
@font-face {
  font-family: 'Product Sans Regular';
  src: url(../fonts/Product_Sans/Product_Sans-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans Bold';
  src: url(../fonts/Product_Sans/Product_Sans-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans Italic';
  src: url(../fonts/Product_Sans/Product_Sans-Italic.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans Bold-Italic';
  src: url(../fonts/Product_Sans/Product_Sans-Bold-Italic.ttf)
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Black-03';
  src: url(../fonts/Avenir/Avenir-Black-03.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Book-01';
  src: url(../fonts/Avenir/Avenir-Book-01.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Roman-12';
  src: url(../fonts/Avenir/Avenir-Roman-12.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Medium-09';
  src: url(../fonts/Avenir/Avenir-Medium-09.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Heavy-05';
  src: url(../fonts/Avenir/Avenir-Heavy-05.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Book-01';
  src: url(../fonts/Avenir/Avenir-Book-01.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir-Light-07';
  src: url(../fonts/Avenir/Avenir-Light-07.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url(../fonts/Roboto/Roboto-Medium.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
  font-display: swap;
}
*:focus {
  outline: none !important;
}
.parent {
  position: relative;
}
.button {
  position: absolute;
  background: #ffffff;
  color: #000000;
  font-size: 2.5rem;
  padding: 0 1.2rem;
  box-shadow: 0px 4px 15px rgba(0, 87, 167, 0.15);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  &.opened {
    fill: #3095f2;
  }
  &.closed {
    fill: #ffffff;
  }
}
.cross {
  cursor: pointer;
  animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  transform-origin: center center;
  -ms-transform-origin: center center;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-box: fill-box;
  -ms-transform-box: fill-box;
  -webkit-transform-box: fill-box;
  -moz-transform-box: fill-box;
  -o-transform-box: fill-box;
  // position: absolute;
  &.opened {
    fill: #ffffff;
    animation-name: rotate1;
  }
  @keyframes rotate1 {
    0% {
      -moz-transform: rotate(-120deg);
      -ms-transform: rotate(-120deg);
      -webkit-transform: rotate(-120deg);
      -o-transform: rotate(-120deg);
      transform: rotate(-120deg);
    }
  }
  @-moz-keyframes rotate1 {
    0% {
      transform: rotate(-120deg);
      -moz-transform: rotate(-120deg);
    }
  }
  @-webkit-keyframes rotate1 {
    0% {
      -webkit-transform: rotate(-120deg);
      transform: rotate(-120deg);
    }
  }
  &.closed {
    fill: #000000;
    animation-name: rotate2;
    animation-direction: alternate;
    -moz-animation-direction: alternate;
    -webkit-animation-direction: alternate;
  }
  @keyframes rotate2 {
    100% {
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
  @-moz-keyframes rotate2 {
    100% {
      transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
    }
  }
  @-webkit-keyframes rotate2 {
    100% {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}

.ReactModal__Body--open .ril__outer {
  background-color: rgba(0, 0, 0, 0.8);
}
.ReactModal__Body--open .ril__toolbar {
  background-color: transparent;
}
.ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem {
  display: none;
  background-color: #000;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 2.3em;
  margin-top: 20px;
}
.ReactModal__Body--open .ril-toolbar {
  background-color: transparent;
}
.ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem:last-child {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  /* my temporary solution
    .ReactModal__Body--open .ril__image{
      transform: none !important;
    }
    */
  .ReactModal__Body--open .ril-toolbar-right .ril__toolbarItem {
    width: 40px;
    height: 40px;
    line-height: 1.8em;
  }
}
@media screen and (max-width: 768px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos='fade-up'] {
    transform: none !important;
  }
}
.margin-right-left {
  @media screen and (min-width: 1800px) {
    margin: auto 10rem;
  }
  @media screen and (max-width: 1799px) {
    margin: auto 6rem;
  }
  @media screen and (max-width: 911px) {
    margin: auto 4rem;
  }
  @media screen and (max-width: 700px) {
    margin: auto 2.9rem;
  }
  @media screen and (max-width: 575px) {
    margin: auto 1.5rem;
  }
}
@media screen and (max-width: 330px) {
  .huww-flag-wrapper {
    display: none;
  }
}
.grecaptcha-badge {
  z-index: 100;
  display: none;
}
.btn-close {
  background-image: url('../assets/close_btn.svg');
  width: 22px;
  height: 22px;
  border: none;
  background-color: #ffffff;
}

a {
  text-decoration: unset;
}
.main-navbar > .container,
.main-navbar > .container-fluid {
  padding: 0;
}

@import './bootstrapFix.scss';

.project-case-wrapper {
  display: flex;
  flex-direction: column;
  gap: 7.5rem;
  max-width: 1170px;
  margin: 8.5rem auto 8.5rem;

  h4 {
    color: #0a0a0a;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 2rem;
    font-style: normal;
    line-height: 140%;
    margin-bottom: 1.5rem;
  }

  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Avenir-Medium-09', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    line-height: 160%;
  }
}

.projects-hero__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .projects-hero__container__logo {
    margin-bottom: 1.5rem;
  }

  .projects-hero__container__text p {
    color: #333;
    text-align: center;
    font-family: 'Avenir-Light-07', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    line-height: 140%;
    max-width: 865px;
  }

  .description-mobile {
    display: none;
    font-size: 22px;
  }

  .projects-hero__container__text__buttons {
    display: flex;
    flex-direction: row;
    gap: 1.69rem;
    justify-content: center;
    margin-top: 1.5rem;

    a,
    img {
      height: 2.75rem;
    }
  }

  .projects-hero__container__image {
    margin-top: 5rem;
  }

  .projects-hero__container__image,
  .projects-hero__container__image img {
    max-width: 900px;
    border-radius: 3.125rem;
  }
}

.case-client {
  display: flex;
  flex-direction: row;
  gap: 5.5rem;

  .case-client-col {
    flex: 1 0 50%;

    img {
      margin-bottom: 1.5rem;
    }

    .client-logo {
      width: 172px;
    }
  }

  p {
    color: #0a0a0a;
    font-family: 'Avenir-Light-07', sans-serif;
    font-size: 1.125rem;
    line-height: 160%;
  }

  p > span {
    font-family: 'Avenir-Heavy-05', sans-serif;
  }

  .case-client-text {
    margin-bottom: 1.2rem;
    line-height: 150%;
    max-width: 35rem;

    b {
      font-family: 'Avenir-Heavy-05', sans-serif;
      color: #0a0a0a;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
    }
  }

  .case-client-services {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-bottom: 1rem;
      max-height: 3.5rem;
      max-width: 3.5rem;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        color: #000;
        text-align: center;
        font-family: 'Avenir-Heavy-05', sans-serif;
        font-size: 1rem;
      }
    }
  }

  .case-client-services-block {
    margin-top: 2.69rem;
    max-width: 21rem;
  }

  .case-client-text-wrapper {
    display: flex;
    gap: 1.5rem;
  }
}

.rabl-case {
  .projects-hero__container__image,
  .projects-hero__container__image img {
    width: 1170px;
    max-width: 90vw;
  }
  .case-client-text-wrapper {
    margin-bottom: 5.5rem;
  }
  .case-client-services-block {
    margin-top: 7.5rem;
  }
  .case-client .case-client-col .client-logo {
    width: 100px;
  }
}

.instructme-case {
  .projects-hero__container__image,
  .projects-hero__container__image img {
    width: 1170px;
    max-width: 90vw;
  }
}

.case-challenge {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.88rem;

  .case-challenge-image,
  .case-challenge-image img {
    border-radius: 3.125rem;
    max-width: 35rem;
  }

  .case-challenge-image.reverse-order {
    order: -1;
  }
}

.case-result {
  p {
    max-width: 45.5rem;
    margin-bottom: 2.5rem;
  }

  img {
    max-width: 100%;
    border-radius: 3.125rem;
  }
}

.case-technical-details {
  .case-technical-details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.8rem;
    row-gap: 1.25rem;
    font-size: 18px;
  }

  .case-technical-details-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  b {
    color: #333;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 160%;
    font-weight: 500;
  }
}

@media screen and (max-width: 1439px) {
  .project-case-wrapper {
    margin: 8.5rem 5rem 8.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .project-case-wrapper {
    margin: 6.5rem 5rem 6.5rem;
    gap: 3.5rem;
  }

  .projects-hero__container .projects-hero__container__image {
    max-width: 100%;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    img {
      max-width: 100%;
    }
  }

  .case-client {
    flex-direction: column;
    gap: 3.5rem;

    .case-client-col {
      flex: 1 0 100%;
    }
  }

  .case-challenge {
    flex-direction: column;
    gap: 2.5rem;

    .case-challenge-image.reverse-order {
      order: 1;
    }
  }

  .case-technical-details {
    .case-technical-details-grid {
      grid-template-columns: 1fr 1fr;

      div:empty {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .project-case-wrapper {
    margin: 6.5rem 1.5rem 6.5rem;
    gap: 1.5rem;

    h4 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.875rem;
    }
  }

  .projects-hero__container {
    .projects-hero__container__image {
      margin-top: 2.5rem;
      margin-bottom: 4rem;
      margin-right: 0;
      margin-left: 0;
    }
    .projects-hero__container__image img {
      border-radius: 0.875rem;
    }
    .projects-hero__container__logo img {
      max-width: 12.25rem;
    }
    .projects-hero__container__text p {
      font-size: 1.375rem;
    }
    .projects-hero__container__text__buttons {
      margin-bottom: 2rem;
      margin-top: 1.5rem;
    }

    .description-desktop {
      display: none;
    }

    .description-mobile {
      display: block;
    }
  }

  .instructme-case {
    .projects-hero__container__logo img {
      max-width: 92px;
    }
    .projects-hero__container .description-mobile {
      font-size: 16px;
    }
  }

  .case-client .case-client-services {
    gap: 2rem;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .case-challenge {
    margin-top: 4.75rem;

    .case-challenge-image,
    .case-challenge-image img {
      max-width: 100%;
      border-radius: 0.875rem;
    }
  }

  .case-result {
    margin-top: 4.75rem;
    img {
      max-width: 100%;
      border-radius: 0.875rem;
    }
  }

  .case-technical-details {
    margin-top: 4.75rem;

    .case-technical-details-grid {
      grid-template-columns: 1fr;
      gap: 2.5rem;
      font-size: 1rem;
    }
  }
  .rabl-case {
    .case-client-text-wrapper {
      margin-bottom: 3.5rem;
    }
    .case-client-services-block {
      margin-top: 1.5rem;
    }
    .projects-hero__container .description-mobile {
      font-size: 16px;
    }
    .case-client .case-client-col .client-logo {
      width: 80px;
    }
  }
  .parkworks-case {
    .case-client-services {
      margin-top: 3rem;
    }
  }
}

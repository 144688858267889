.commit-features {
  padding: 7rem 0 5rem;
  font-family: 'Avenir-Black-03';
  .commit-features-title {
    margin: auto 6rem 5.7rem;
    font-family: 'Avenir-Roman-12';
    font-size: 80px;
    color: #b9dfcf;
    opacity: 0.75;
  }
  .commit-features-row {
    margin: auto 6rem 5.7rem;
    justify-content: space-between;

    .commit-features-font {
      font-size: 28px;
      line-height: 33px;
      color: #478f39;
    }
    .commit-features-font-regular,
    .commit-features-font-medium,
    .commit-features-font-demi {
      font-family: 'Avenir-Black-03';
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 4px;
      color: #383838;
    }
    .commit-features-font-medium {
      font-family: 'Avenir-Heavy-05';
    }
    .commit-features-font-demi {
      font-family: 'Avenir-Heavy-05';
      font-size: 20px;
      line-height: 27px;
    }
    .commit-features-color-row {
      align-items: center;
      justify-content: flex-end;
      .commit-features-color {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
        margin: 0.5rem;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;

        &:last-child {
          color: #000000;
        }

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .commit-features-title {
      margin: auto 9rem 5.7rem;
    }
    .commit-features-row {
      margin: auto 9rem 5.7rem;
    }
  }

  @media screen and (min-width: 2000px) {
    .commit-features-title {
      font-size: 120px;
    }
    .commit-features-row {
      .commit-features-font {
        font-size: 32px;
        line-height: 35px;
      }
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 25px;
        line-height: 27px;
      }
      .commit-features-color-row {
        .commit-features-color {
          height: 12rem;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  @media screen and (min-width: 2300px) {
    .commit-features-row {
      .commit-features-font {
        font-size: 40px;
        line-height: 45px;
      }
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 30px;
        line-height: 33px;
      }
      .commit-features-color-row {
        .commit-features-color {
          height: 15rem;
          font-size: 30px;
          line-height: 33px;
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .commit-features-row {
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 16px;
        line-height: 18px;
      }
      .commit-features-color-row {
        .commit-features-color {
          height: 8rem;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  @media screen and (max-width: 911px) {
    .commit-features-title {
      margin: auto 3.5rem 4.5rem;
    }
    .commit-features-row {
      margin: auto 3rem;
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 14px;
        line-height: 16px;
      }
      .commit-features-color-row {
        .commit-features-color {
          height: 7rem;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .commit-features-row {
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 12px;
        line-height: 14px;
      }
      .commit-features-color-row {
        .commit-features-color {
          height: 6rem;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 0 3rem;
    .commit-features-title {
      margin: auto 1rem 3.5rem;
    }
    .commit-features-row {
      margin: auto 1rem;
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 16px;
        line-height: 19px;
      }
      .commit-features-color-row {
        padding: 0 1rem;
        justify-content: flex-start;
        .commit-features-color {
          height: 8rem;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    margin: 5rem 0;
    .commit-features-title {
      margin: auto 1rem 2rem;
      font-size: 40px;
      line-height: 55px;
    }
    .commit-features-row {
      margin: 0;
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 14px;
        line-height: 16px;
      }
      .commit-features-color-row {
        justify-content: space-between;
        .commit-features-color {
          flex: 0 0 47%;
          max-width: 47%;
          height: 8rem;
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 0;
    .commit-features-row {
      .commit-features-color-row {
        .commit-features-color {
          height: 6rem;
        }
      }
    }
  }

  @media screen and (max-width: 374px) {
    .commit-features-row {
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @media screen and (max-width: 335px) {
    .commit-features-row {
      .commit-features-font-regular,
      .commit-features-font-medium,
      .commit-features-font-demi {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
}

.clients-love-container {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.clients-love-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;
  width: 100%;
}

.wrapper-dark .clients-love-item .medium16px {
  opacity: 0.8;
}

.clients-love-item {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      border-radius: 5px;
    }
  }

  h3 {
    span {
      color: #3095f2;
    }
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .clients-love-wrapper {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 700px) {
  .clients-love-wrapper {
    .clients-love-item {
      & > div > img {
        width: 56px;
      }
    }
  }
}

.parallax {
  display: inline-block;
  height: auto;
  position: relative;
  width: auto;

  .parallax-content {
    box-shadow: 0 1rem 0.5rem rgba(0, 0, 0, .15);
    height: auto;
    -webkit-transform: perspective(50rem);
    transform: perspective(50rem);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all .4s ease;
    width: 100%;
    border-radius: 1.5rem;
    
    &::before {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .parallax-top-left {
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 99;
  }
  .parallax-top-left:hover ~ .parallax-content {
    -webkit-transform: perspective(50rem) rotateX(7deg) rotateY(-7deg);
    transform: perspective(50rem) rotateX(7deg) rotateY(-7deg);
  }
  .parallax-top-left:hover ~ .parallax-content::before {
    background: linear-gradient(135deg, rgba(255, 255, 255, .25) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 1rem;
  }
  .parallax-top-right {
    height: 50%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    z-index: 99;
  }
  .parallax-top-right:hover ~ .parallax-content {
    -webkit-transform: perspective(50rem) rotateX(7deg) rotateY(7deg);
    transform: perspective(50rem) rotateX(7deg) rotateY(7deg);
  }
  .parallax-top-right:hover ~ .parallax-content::before {
    background: linear-gradient(-135deg, rgba(255, 255, 255, .25) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 1rem;
  }
  .parallax-bottom-left {
    bottom: 0;
    height: 50%;
    left: 0;
    position: absolute;
    width: 50%;
    z-index: 99;
  }
  .parallax-bottom-left:hover ~ .parallax-content {
    -webkit-transform: perspective(50rem) rotateX(-7deg) rotateY(-7deg);
    transform: perspective(50rem) rotateX(-7deg) rotateY(-7deg);
  }
  .parallax-bottom-left:hover ~ .parallax-content::before {
    background: linear-gradient(45deg, rgba(255, 255, 255, .25) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 1rem;
  }
  .parallax-bottom-right {
    bottom: 0;
    height: 50%;
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 99;
  }
  .parallax-bottom-right:hover ~ .parallax-content {
    -webkit-transform: perspective(50rem) rotateX(-7deg) rotateY(7deg);
    transform: perspective(50rem) rotateX(-7deg) rotateY(7deg);
  }
  .parallax-bottom-right:hover ~ .parallax-content::before {
    background: linear-gradient(-45deg, rgba(255, 255, 255, .25) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 1rem;
  }
}
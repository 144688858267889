.commit-flow {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 7rem 0 5rem;

  .commit-flow-title {
    margin: auto 6rem 5.7rem;
    font-weight: inherit;
    font-size: 80px;
    color: #b5e7ac;
  }

  .commit-flow-img {
    width: 100%;
  }
  @media screen and (min-width: 1800px) {
    .commit-flow-title {
      margin: auto 9rem 5.7rem;
    }
  }
  @media screen and (min-width: 2000px) {
    .commit-flow-title {
      font-size: 120px;
    }
  }

  @media screen and (max-width: 768px) {
    .commit-flow-title {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 575px) {
    .commit-flow-title {
      margin: auto 1rem 4rem;
      font-size: 40px;
    }
  }
}

.aob-wrapper {
  .product-collaboration {
    font-family: 'Avenir-Roman-12', sans-serif;
    padding: 0;
    margin: 3rem auto;
    margin-bottom: 20rem;
    .margin {
      margin: auto 6rem;
      flex-wrap: wrap;
      @media screen and (min-width: 1800px) {
        margin: auto 10rem;
      }
    }
    .list-title {
      font-family: 'Product Sans Regular', sans-serif;
      font-weight: bold;
      font-size: 30px;
      color: var(--title-text-color);
      @media screen and (min-width: 1800px) {
        font-size: 45px;
      }
    }
    .list {
      list-style: none;
      padding: 0;
      font-size: 18px;
      word-wrap: break-word;
      height: 100%;
      @media screen and (min-width: 1800px) {
        font-size: 1.5rem;
      }
      @media screen and (min-width: 2000px) {
        font-size: 2rem;
      }
      li:nth-child(2n) {
        padding-bottom: 10px;
      }
    }
    .product-collaboration-row {
      .product-collaboration-lists {
        width: 100%;
      }
    }
    hr.hLine {
      border-top: 2px solid #e0e0e0;
    }

    @media screen and (min-width: 2000px) {
      .list-title {
        font-size: 4rem;
      }
    }

    @media screen and (max-width: 910px) {
      margin-bottom: 10rem;
      .margin {
        margin: auto 4rem;
      }
    }

    @media screen and (max-width: 700px) {
      margin-bottom: 22rem;
      .margin {
        margin: auto 2rem;
      }
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 25rem;
      margin: 3rem auto;
      text-align: start;
      .margin {
        margin: auto 1rem;
      }
      .product-collaboration-lists {
        margin-left: 0;
        div:nth-child(2n + 1) {
          padding-right: 15px !important;
        }
      }
      .list-title {
        font-size: 1.5rem;
        font-weight: inherit;
      }
    }
  }
}

.meest-slider {
  margin-bottom: 0rem;
  padding: 5rem 0;

  &.onboarding {
    background: #ce2846;
    border-bottom-left-radius: 4rem;

    .meest-title,
    .meest-text {
      color: #ffffff;
    }
  }
  &.shipments {
    background: #0061af;
    border-bottom-right-radius: 4rem;

    .meest-title,
    .meest-text {
      color: #ffffff;
    }
  }
  .meest-title {
    font-family: 'Avenir-Heavy-05', sans-serif;
    padding: 0 2rem;
    font-weight: inherit;
    font-size: 30px;
    line-height: 140%;
    color: var(--title-text-color);
  }
  .meest-text {
    font-family: 'Avenir-Book-01', sans-serif;
    padding: 0 2rem;
    font-size: 16px;
    line-height: 160%;
    color: #656875;
  }
  .slick-track {
    display: flex;
    align-items: center;
    img {
      width: 90%;
      height: 100%;
    }
  }
}

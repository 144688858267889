.segments-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding: 7.5rem 8.4rem 7.5rem;
  background-color: #3095f2;
  width: 100%;
  height: 100%;
  gap: 3.75rem;

  h1,
  h2 {
    text-align: left;
  }
}

.segments-block-container {
  display: flex;
  gap: 1.75rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.segment {
  background-color: #ffffff;
  padding: 1.5rem;
  flex: 1 0 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 6px;

  a {
    color: initial;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }

  h3 {
    white-space: nowrap;
    position: relative;
  }

  svg {
    height: 1rem;
  }

  .segments-arrow {
    margin-left: 0.5rem;
    font-size: 1.5rem;
    font-weight: 100;
    font-family: 'Product Sans Regular', sans-serif;
    line-height: 0;
    padding-bottom: 0.5rem;
    max-height: 1rem;
    position: absolute;
    bottom: 0.5rem;
  }
}

.segments-block-centered {
  display: flex;
  flex-direction: column;

  & h1,
  & h2 {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .segments-block {
    padding: 6.25rem 1rem 6.25rem;
  }

  .segment {
    flex: 1 0 100%;

    .segments-arrow {
      bottom: 0.42rem;
    }

    h3 {
      white-space: normal;
    }
  }
}

@media screen and (max-width: 1214px) {
  .segments-block {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1215px) {
  .segments-block-container {
    width: 33vw;
  }
  .segments-block h1,
  .segments-block h2 {
    width: 33vw;
  }
  .segments-block-centered {
    & .segments-block-container {
      width: 100%;
    }
    & h1,
    & h2 {
      width: 100%;
    }
  }
}

.product-layout {
  height: 160rem;
  margin-bottom: -20rem;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 500px) {
    height: 130rem;
  }

  @media screen and (max-width: 400px) {
    height: 110rem;
  }
}
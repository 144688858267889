.uiux-dark {
  .max-width-container > .responsive-container {
    & > .tech-stack {
      margin-top: 5rem;
      @media screen and (max-width: 575px) {
        margin-top: 4rem;
      }
    }
  }
  .clients-love-item {
    & > div > img {
      border-radius: 0;
    }
    h3 {
      line-height: 140%;
    }
  }

  .study_cases_box .case_box {
    .heavy24px {
      font-size: 2rem;
    }
    .medium16px {
      font-size: 1rem;
      font-family: 'Avenir-Heavy-05', sans-serif;
    }
    .medium12px {
      font-size: 0.875rem;
    }
    p {
      text-align: center !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .uiux-dark {
    .clients-love-item {
      & > div > img {
        width: 80px;
      }

      h3 {
        font-size: 24px;
        padding-right: 3rem;
      }
    }

    .domains_list_box {
      h3 {
        font-size: 22px;
      }
    }

    .our-services-item {
      h3 {
        font-size: 22px;
      }
    }

    .responsive-page-top_bottom-gap {
      padding-bottom: 3rem;
    }

    .support-container .responsive-page-top_bottom-gap {
      padding-top: 0;
    }

    .support-block {
      .support-block-text {
        margin-bottom: 6.25rem;
      }

      .lazyload-wrapper {
        order: -1;
      }

      img {
        max-width: 95vw;
      }
    }

    .tech-stack .tech-stack-content {
      justify-content: space-between;
    }

    .tech-stack .tech-stack-item {
      flex: 0 0 20%;
      display: flex;
      justify-content: center;

      img {
        height: 64px;
      }
    }

    .study_cases_box .case_box {
      .heavy24px {
        font-size: 1.5rem;
      }
    }
  }
}

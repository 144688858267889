.main {
  font-family: 'Avenir-Medium-09', sans-serif;
  .main_content_box {
    margin-top: 3rem;
  }

  h1 {
    font-family: 'Avenir-Black-03', sans-serif;
    font-size: 3.875rem;
    color: var(--title-text-color);
    letter-spacing: 0.01em;
    margin-bottom: 20px;
    line-height: 140%;
    @media screen and (min-width: 2560px) {
      font-size: 110px;
    }
    @media screen and (max-width: 875px) {
      br {
        display: none;
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
    span {
      color: #3095f2;
    }
  }
  p {
    font-size: 1.5rem;
    font-family: 'Avenir-Medium-09', sans-serif;
    color: var(--p-text-color);
    line-height: 2.5rem;
    font-weight: 500;
    font-style: normal;
    @media screen and (min-width: 2560px) {
      font-size: 32px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 26px;
      width: 100%;
    }
    @media screen and (max-width: 650px) {
      br {
        display: none;
      }
    }
  }
}

.perpetio-container {
  margin: auto 5rem;
  @media screen and (min-width: 1800px) {
    margin: auto 9rem;
  }
  @media screen and (max-width: 910px) {
    margin: auto 3rem;
  }
  @media screen and (max-width: 700px) {
    margin: auto 2rem;
  }
  @media screen and (max-width: 575px) {
    margin: auto 0.5rem;
    // margin-top:2rem!important
  }
}

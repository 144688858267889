.portfolio {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0;
  .portfolio_header {
    & > .main > .my-5 {
      margin-bottom: 0 !important;
    }
  }
  .portfolio-title {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 4.3vw;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: var(--title-text-color);
    & > span {
      color: #3095f2;
    }
    @media screen and (max-width: 350px) {
      br {
        display: none;
      }
    }
    @media screen and (max-width: 570px) {
      font-size: 30px;
    }
    @media screen and (max-width: 800px) {
      font-size: 30px;
    }
    @media screen and (min-width: 2560px) {
      font-size: 110px;
    }
  }
  .portfolio-subtitle {
    margin-top: 20px;
    font-family: 'Avenir-Roman-12' sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25vw;
    line-height: 190%;
    color: #7c7c87;
    @media screen and (max-width: 570px) {
      font-size: 14px;
    }
    @media screen and (max-width: 800px) {
      font-size: 14px;
      line-height: 24px;
    }
    @media screen and (min-width: 2560px) {
      font-size: 30px;
    }
  }
  ul.ks-cboxtags {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0;
    height: 49px;
    margin-bottom: 0;
    li {
      display: inline;
      // margin: 1.5rem 3.75rem;
      margin-right: 3.75rem;
      .checked {
        font-style: normal;
        // font-weight: inherit;
        font-size: 18px;
        line-height: 25px;
        color: var(--title-text-color);
        display: inline-block;
        vertical-align: top;
        position: relative;
        &::before {
          margin-bottom: -24px;
          transform: translateX(-50%);
          border-radius: 100%;
          position: absolute;
          background: #3095f2;
          bottom: 10px;
          height: 6px;
          content: '';
          width: 6px;
          left: 50%;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .unchecked {
        font-style: normal;
        // font-weight: inherit;
        font-size: 18px;
        line-height: 25px;
        color: #7c7c87;
        &:hover {
          cursor: pointer;
        }
      }
    }
    @media screen and (min-width: 2560px) {
      li {
        .checked {
          font-size: 25px;
          &::before {
            width: 8px;
            height: 8px;
          }
        }
        .unchecked {
          font-size: 25px;
        }
      }
    }
  }
  .portfolio-products-load-more {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    line-height: 140%;
    font-style: normal;
    font-weight: inherit;
    font-size: 15px;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-family: 'Avenir-Roman-12', sans-serif;
    border-bottom: 3px solid #3797ef;

    &:hover {
      animation: pulse 0.8s linear infinite;
      -webkit-animation: pulse 0.8s linear infinite;
      color: #0077e4;
    }
  }
  .margin {
    margin: auto 6rem;

    @media screen and (max-width: 911px) {
      margin: auto 4rem;
    }
  }
  .projects {
    justify-content: space-between;
    .portfolio-column {
      &.loading {
        border: 2px solid #f6f6f6;
      }
      padding: 1.5rem 1.5rem 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      flex: 0 0 57%;
      max-width: 57%;
      &:hover {
        background-position: center;
      }
      .portfolio-work-app {
        position: absolute;
        top: 0;
        height: 100%;
        right: 0;
        @media screen and (max-width: 1400px) {
          padding: 10px 0;
        }
        @media screen and (max-width: 1300px) {
          padding: 20px 0;
        }
        @media screen and (max-width: 1250px) {
          padding: 35px 0;
        }
        @media screen and (max-width: 1200px) {
          padding: 40px 0;
        }
        &.desktop {
          display: block;
        }
        &.mobile {
          display: none;
        }
      }
      &:nth-child(4n),
      &:nth-child(4n - 3) {
        background-position: right;
        flex: 0 0 40%;
        max-width: 40%;
        &:hover {
          background-position: left;
        }
      }
    }
    .portfolio-work-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .portfolio-work-logo {
      height: 100px;
      width: 100px;
    }
    .portfolio-work-text {
      margin: 30px 0 10px;
      color: #fff;
      width: 50%;
      font-size: 18px;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
    }
    .portfolio-work-types {
      color: #fff;
      width: 58%;
      font-size: 10px;
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        li {
          padding: 5px 15px;
          border: 1px solid #fff;
          border-radius: 30px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width: 1600px) {
      .portfolio-column {
        &:nth-child(4n),
        &:nth-child(4n - 3) {
          .portfolio-work-app {
            &.desktop {
              display: none;
            }
            &.mobile {
              display: block;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1300px) {
      .portfolio-work-types {
        ul {
          font-size: 14px;
          li {
            padding: 0;
            border: 0;
            margin: 0;
            margin-right: 5px;
            &:after {
              content: ' / ';
            }
            &:last-child {
              &:after {
                content: '';
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1100px) {
      .portfolio-column {
        background-size: cover;

        &:nth-child(4n),
        &:nth-child(4n - 3) {
          background-position: initial;

          &:hover {
            background-position: center;
          }
        }
        .portfolio-work-app {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: none;
          }
        }
        &:nth-child(4n),
        &:nth-child(4n - 3) {
          .portfolio-work-app {
            &.desktop {
              display: none;
            }
            &.mobile {
              display: none;
            }
          }
        }
      }
      .portfolio-work-logo {
        height: 60px;
        width: 60px;
      }

      .portfolio-work-text {
        font-size: 14px;
        margin-top: 20px;
        width: 80%;
      }
    }
    @media screen and (max-width: 575px) {
      .portfolio-column {
        background-position: right;

        &:hover {
          background-position: left;
        }

        &:nth-child(4n),
        &:nth-child(4n - 3) {
          background-position: right;

          &:hover {
            background-position: left;
          }
        }
      }
    }
  }
  .portfolio-column {
    border-radius: 15px;
    height: 28rem;
    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 2000px) {
    .portfolio-column {
      height: 35rem;
    }
    .description {
      font-size: 2rem;
    }
    .position {
      margin: 5rem 10rem 0 0;
    }
    .title {
      font-size: 17rem;
      line-height: 14rem;
    }
  }

  @media screen and (min-width: 2300px) {
    .portfolio-column {
      height: 40rem;
    }
    .projects {
      .portfolio-work-text {
        font-size: 28px;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    .projects {
      .portfolio-column {
        padding: 2vw;
      }
      .portfolio-work-text {
        font-size: 1.1vw;
        margin-top: 2vw;
      }
      .portfolio-work-types {
        font-size: 0.6vw;
        ul {
          li {
            padding: 0.3vw 0.7vw;
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1700px) {
    .portfolio-column {
      height: 25rem;
    }
  }

  @media screen and (max-width: 1500px) {
    .portfolio-column {
      height: 25rem;
    }
  }
  @media screen and (max-width: 1100px) {
    .portfolio-column {
      height: 20rem;
    }
  }

  @media screen and (max-width: 991px) {
    .projects {
      .portfolio-column {
        height: 25rem;
        &:nth-child(even) {
          flex: 100%;
          max-width: 100%;
        }
        &:nth-child(odd) {
          flex: 100%;
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .projects {
      .portfolio-column {
        height: 18rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .margin {
      margin: auto 2.9rem;
    }
  }

  @media screen and (max-width: 575px) {
    .margin {
      margin: auto 1.5rem;
    }
    // .portfolio-title {
    //   font-size: 3rem;
    //   line-height: 4rem;
    // }
    ul.ks-cboxtags {
      li label {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.7rem 1.3rem;
      }
    }
    .projects {
      .portfolio-column {
        height: calc(100vw - 2rem);
      }
    }
  }

  @media screen and (max-width: 475px) {
  }

  @media screen and (max-width: 376px) {
    ul.ks-cboxtags li label {
      font-size: 0.8rem;
      line-height: 1rem;
      padding: 0.7rem 0.8rem;
    }
    ul.ks-cboxtags li {
      margin-right: 0.7rem;
    }
    .portfolio-column {
      height: calc(100vw - 2rem);
    }
  }
}
.work_projects {
  display: grid;
  // width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 60px !important;
  grid-gap: 30px;
  .portfolio-column {
    border-radius: 15px;

    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr;
    // .project_name {
    //   font-size: 16px;
    // }
    // .project_text {
    //   font-size: 18px;
    // }
    // .project_tags {
    //   font-size: 12px;
    // }
  }
  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
  }
}
.loadMoreBox {
  margin-top: 60px !important;
  display: flex;
  justify-content: center;
  & > div {
    position: relative;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-weight: inherit;
    font-size: 1.1vw;
    line-height: 140%;
    color: var(--title-text-color);
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -2px;
      left: 0;
      background: #3095f2;
      transform-origin: bottom center;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom center;
    }
  }
  @media screen and (min-width: 2560px) {
    & > div {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 1600px) {
    & > div {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 1024px) {
    & > div {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .portfolio-subtitle {
    br {
      display: none;
    }
  }
}
@media screen and (max-width: 475px) {
  // .portfolio-title {
  //   font-weight: 900 !important;
  //   font-size: 30px !important;
  //   line-height: 42px !important;
  //   letter-spacing: 0.01em !important;
  // }
  // .portfolio-subtitle {
  //   font-weight: 500 !important;
  //   font-size: 14px !important;
  //   line-height: 24px !important;
  // }
  .ks-cboxtags {
    // gap: 1.5rem 2.1rem !important;

    li {
      margin-right: 2.1rem !important;
      .checked {
        font-size: 16px !important;
      }
      .unchecked {
        font-size: 16px !important;
      }
    }
  }
  .project_text {
    font-size: 16px !important;
  }
  .project_tags {
    font-size: 12px !important;
  }
}

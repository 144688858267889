.image-slider-wrapper {
  transform: rotate(180deg);
  .image-slider-content-desktop {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    gap: 4rem;
    .image-slider-content-image-up,
    .image-slider-content-image-down {
      max-width: 565px;
      min-width: 565px;
      width: 565px;
      max-height: 540px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      .slide-enter {
        transform: translateY(100%);
      }
      .slide-enter-active {
        transform: translateY(0%);
        transition: transform 300ms ease-in-out;
      }
      .slide-exit {
        transform: translateY(0%);
      }
      .slide-exit-active {
        transform: translateY(-100%);
        transition: transform 300ms ease-in-out;
      }

      & > div > .lazyload-wrapper {
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;

        img {
          max-height: 100%;
          max-width: 100%;
          object-fit: contain;
          border-radius: 6px;
        }
      }
    }
    .image-slider-content-image-down {
      .slide-enter {
        transform: translateY(-100%);
      }
      .slide-enter-active {
        transform: translateY(0%);
        transition: transform 300ms ease-in-out;
      }
      .slide-exit {
        transform: translateY(0%);
      }
      .slide-exit-active {
        transform: translateY(100%);
        transition: transform 300ms ease-in-out;
      }
    }
    .image-slider-content-items {
      max-width: 565px;

      .image-slider-content-item {
        display: flex;
        min-height: 180px;
        gap: 20px;
        padding: 30px;
        cursor: pointer;
        border-bottom: 1px solid #d7d7d7;

        &:last-child {
          border-bottom: 1px solid transparent;
        }
        .lazyload-wrapper {
          height: fit-content;
          padding: 8px;
          border-radius: 6px;
        }
        .image-slider-content-item__text {
          h3 {
            margin-bottom: 7px;
          }
        }

        &:last-child {
          border-top: 1px solid #d7d7d7;
        }
      }
    }
  }
  .image-slider-content-mobile {
    display: none;
  }
  .image-slider-titleBox {
    margin-bottom: 3.75rem;
  }
  @media screen and (max-width: 768px) {
    .image-slider-titleBox {
      margin-bottom: 2.5rem;
    }
  }
  @media screen and (max-width: 1300px) {
    .image-slider-content-desktop {
      display: none;
    }
    .image-slider-content-mobile {
      margin-top: 2.5rem;
      display: block;
      .image-slider-content-items {
        display: flex;
        flex-direction: column;

        .image-slider-content-item {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
          transition: all 0.3s ease-in-out;
          border-bottom: 1px solid #d7d7d7;

          &:last-child {
            border-bottom: 1px solid transparent;
          }
          & > div > .item-logo-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .lazyload-wrapper {
              width: fit-content;
              padding: 6px;
              border-radius: 6px;
            }
            .item-logo-box__arrow-active {
              transform: rotate(180deg);
              transition-duration: 0.5s;
            }
            .item-logo-box__arrow-inactive {
              transform: rotate(0deg);
              transition-duration: 0.5s;
            }
          }
          .image-slider-content-item__text {
            h3 {
              margin-bottom: 7px;
            }
          }
        }
        .image-slider-content-mobile__hide-image-box {
          .hideImage {
            overflow: hidden;
            max-height: 0;
            transition-duration: 0.5s;
            img {
              width: 100%;
            }
          }
          .showImage {
            transition-duration: 0.5s;
            margin-top: 1rem;

            max-height: 1000px;
            .lazyload-wrapper {
              img {
                width: 100%;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
}

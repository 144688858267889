.service-head-image-container {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  flex-wrap: nowrap;
  justify-content: space-between;

  img {
    height: auto;
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  .main_content_box > p {
    max-width: 670px;
  }
}

.service-head-image {
  width: 100%;
  max-width: 504px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
    max-height: 50vh;
  }
}

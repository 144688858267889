.team-augmentation {
  .support-block img {
    @media screen and (max-width: 768px) {
      max-width: 75vw;
    }
  }

  .fullCycle-tools-box {
    margin: 8rem auto;

    @media screen and (max-width: 1024px) {
      margin: 6rem auto;
    }
  }
}

.meest-wrapper {
  .meest-product-main-mobile {
    font-family: 'Avenir-Roman-12', sans-serif;
    position: relative;
    padding: 5rem 0 0;
    color: #000000;
    background: #ffffff;
    text-align: center;
    border-bottom-left-radius: 10rem;

    .meest-main-row-mobile {
      margin: 0 2rem;

      > .row {
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-left: 0;
        margin-right: 0;

        .col-6 {
          padding: 0;
        }
      }
    }

    .meest-download-btn {
      width: 100%;
      margin-right: 0;
    }

    .meest-description-mobile {
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
    }

    .meest-main-title-mobile {
      margin-bottom: 2rem;
      width: 180px;
    }

    .meest-main-row-mobile {
      justify-content: space-between;
    }

    .meest-main-img {
      width: 100%;
      border-radius: 1.5rem;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
    }

    .meest-main-col-mobile {
      padding: 0;
      flex: 0 0 47%;
      max-width: 47%;
      margin-top: 3rem;
      margin-bottom: -15rem;

      &:last-child {
        img {
          margin-top: 7rem;
        }
      }
    }

    @media screen and (max-width: 445px) {
      .meest-main-col-mobile {
        margin-bottom: -12rem;
        .meest-main-img {
          border-radius: 0.5rem;
        }
        &:last-child {
          img {
            margin-top: 5rem;
          }
        }
      }
    }
  }
}

.getInTouchBtn button {
  background-color: #3095f2;
  margin-top: 40px;
  padding: 12px 38px;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Avenir-Medium-09', sans-serif;
  border: none;
}
.service-head-container {
  display: flex;
  flex-direction: column;

  &.limit-content-width p {
    max-width: 48rem;
  }

  @media screen and (max-width: 575px) {
    align-items: center;
    .main {
      .main_content_box {
        text-align: center;
      }
    }
  }
}

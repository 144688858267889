.meest-packages-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0 7rem 5rem 4rem;
  margin-left: 2rem;

  .meest-packages-row-wrapper {
    border-right: 2px dashed #0061af99;
    border-bottom: 2px dashed #0061af99;
    border-bottom-right-radius: 4rem;
    padding: 8rem 2rem 8rem 0;
    align-items: center;
    position: relative;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      right: -10px;
      background-color: #ffffff;
      border: 2px solid #0061af;
      top: 50.5%;
      border-radius: 50%;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      width: 48px;
      height: 80px;
      left: -2.65rem;
      border-left: 2px dashed #0061af;
      border-top-left-radius: 4rem;
      bottom: -5rem;
    }
  }

  .meest-packages-title {
    font-weight: inherit;
    font-size: 50px;
    line-height: 140%;
    color: #232832;
  }

  .meest-packages-text {
    font-size: 18px;
    line-height: 160%;
    color: #656875;
  }
  .meest-packages-col {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .meest-packages-row {
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (min-width: 1800px) {
    padding: 0 7rem 5rem 8rem;

    .meest-packages-row-wrapper {
      padding: 8rem 5rem 8rem 0;
    }
    .meest-packages-col {
      flex: 0 0 27%;
      max-width: 27%;
    }
    .meest-packages-title {
      font-size: 4rem;
    }

    .meest-packages-text {
      font-size: 2.1rem;
    }
  }
  @media screen and (max-width: 911px) {
    padding: 0 5.35rem 5rem 4.3rem;
    margin-left: 1.85rem;
  }
}

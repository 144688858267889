.aob-product-screens {
  justify-content: space-between;
  padding: 3rem 0 5rem;
  flex-wrap: nowrap;
  align-items: center;
  background: url('https://perpetiostorage1.b-cdn.net/resources/images/aob/screens-bg.jpg'),
    radial-gradient(
      56.5% 56.5% at 50% 49.95%,
      rgba(243, 243, 243, 0.6) 0%,
      rgba(247, 247, 247, 0.8) 100%
    );
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  & > div {
    width: unset;
  }

  div {
    &:first-child {
      margin-top: 15rem;
    }
    &:nth-child(2) {
      margin-top: 8rem;
    }
    &:nth-child(4) {
      margin-top: 18rem;
    }

    @media screen and (max-width: 1300px) {
      img {
        height: 30rem;
      }

      img.phone {
        height: 35rem;
      }
    }
    @media screen and (max-width: 1200px) {
      img {
        height: 25rem;
      }

      img.phone {
        height: 30rem;
      }
    }
    @media screen and (min-width: 1800px) {
      img {
        height: 40rem;
      }

      img.phone {
        height: 45rem;
      }
    }
    @media screen and (min-width: 2200px) {
      img {
        height: 50rem;
      }

      img.phone {
        height: 55rem;
      }
    }
  }
}

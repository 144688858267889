.home_head_container {
  background: linear-gradient(
    134.07deg,
    #f3f2ff 9.9%,
    #e8fffe 68.31%,
    #ffffff 100.53%
  );
  .badges {
    margin-top: 3rem;
    .badges_group {
      display: flex;
      // grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      align-items: center;
      gap: 1rem;
      justify-content: center;
      max-width: 100vw;
      flex-wrap: wrap;
      & > a {
        display: flex;
        justify-content: center;
      }
      .clutch_badge {
        & > div > img {
          width: 104px;
          height: 112px;
        }
      }
      .goodfirm_badge {
        & > div > img {
          height: 111px;
          width: 128px;
        }
      }
      .appdevelopment_badge {
        & > div > img {
          width: 126px;
          height: 126px;
        }
      }
      .top_rated_mobile_badge {
        & > div > img {
          width: 118px;
          height: 118px;
        }
      }
      .top_firms_badge {
        & > div > img {
          width: 155px;
          height: 155px;
        }
      }
      .top_lviv_badge {
        & > div > img {
          width: 80px;
          height: 112px;
        }
      }
      .app_futura_badge {
        & > div > img {
          width: 126px;
          height: 113.47px;
        }
      }
      .revenuecat_badge {
        & > div > img {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
  @media screen and (max-width: 1340px) {
    .badges {
      .badges_group {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
        gap: 1.5rem;
        justify-content: center;
        & > a {
          display: flex;
          justify-content: center;
          //   width: fit-content;
        }
        .clutch_badge {
          & > div > img {
            width: 74.55px;
            height: 80px;
          }
        }
        .goodfirm_badge {
          & > div > img {
            height: 80px;
            width: 92px;
          }
        }
        .appdevelopment_badge {
          & > div > img {
            width: 90px;
            height: 90px;
          }
        }
        .top_rated_mobile_badge {
          & > div > img {
            width: 84px;
            height: 84px;
          }
        }
        .top_firms_badge {
          & > div > img {
            width: 111.7px;
            height: 112px;
          }
        }
        .top_lviv_badge {
          & > div > img {
            width: 58.7px;
            height: 82px;
          }
        }
        .app_futura_badge {
          & > div > img {
            width: 105px;
            height: 95px;
          }
        }
        .revenuecat_badge {
          & > div > img {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .badges {
      .badges_group {
        grid-template-columns: auto auto auto;
      }
    }
  }
}

.header {
  --white-hamburger-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27white%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
  --dark-hamburger-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27black%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');

  .main-navbar {
    width: 100%;
  }

  .white-char {
    fill: #ffffff;
  }
  .black-char {
    fill: #000000;
  }
  .blue-char {
    fill: #3095f2;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .navbar-close-button {
    display: none;
    --bs-btn-active-bg: #add7ff;

    &:focus,
    &:active {
      outline: none;
      background-color: #add7ff;

      svg {
        transform: rotate(180deg);
        transition: all 0.5s ease-out;
      }
    }
  }
  .navbar-hamburger-brand {
    display: none;
    max-width: 106px;
  }
  .socialLinks {
    display: none;
  }
  .hamburger-submenu-tablet,
  .hamburger-submenu-mobile {
    display: none;
  }
  .navbar {
    font-family: 'Avenir-Medium-09', sans-serif;
    margin: 3rem 0;
    @media screen and (max-width: 768px) {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 70px;

    &.scrolled {
      box-shadow: 0px 4px 35px rgba(0, 87, 167, 0.15);
      background: #fff;
      transition-property: background-color;
      transition-duration: 1s;
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      transition: all 0.5s ease-out;
    }

    @media screen and (min-width: 1800px) {
      padding-left: 9rem;
      padding-right: 9rem;
    }
    @media screen and (min-width: 2560px) {
      padding-left: 0;
      padding-right: 0;
    }
    .nav-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 2560px;
      margin-right: auto;
      margin-left: auto;
      @media screen and (min-width: 2560px) {
        padding-left: 9rem;
        padding-right: 9rem;
      }
    }
    .dropdown-menu {
      .dropdown-item.active,
      .dropdown-item:active,
      .dropdown-item:hover {
        background-color: transparent;
      }
    }

    .navbar-collapse {
      justify-content: flex-end;
      margin-right: 1.5rem;
    }

    .custom-nav {
      align-items: center;
      text-align: center;
    }

    .nav-item-special {
      a {
        opacity: 1;
      }
      display: none;
    }

    a,
    .nav-link {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.8rem;
      color: var(--title-text-color);
      padding: 0.8rem;
      margin: 0 0.5rem;

      &:hover {
        text-decoration: underline;
        color: #3095f2;
      }
      &.special {
        border-radius: 6px;
        background-color: #3095f2;
        border: 2px solid #3095f2;
        color: #fff;
        padding: 0.7rem 2rem;
        margin-right: 0;
        box-sizing: border-box;

        &.special-white {
          color: #ffffff;
          border: 2px solid transparent;
        }
        &:hover {
          background: #2273bd;
          border-color: #2273bd;
        }
      }

      &.white {
        color: #ffffff !important;
      }

      &.black {
        color: #000000;
        &:hover {
          color: #3095f2;
          text-decoration: none;
        }
      }

      &.active {
        opacity: 1;
        letter-spacing: -1px;
        color: #000000;
        font-family: 'Avenir-Heavy-05', sans-serif;

        &.special {
          opacity: 1;
          color: #fff;
          font-family: 'Avenir-Medium-09', sans-serif;
        }

        @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          letter-spacing: 0;
        }
      }
    }

    .navbar-toggler {
      color: transparent;
      border-color: unset;
      padding: 0.375rem 0.75rem;
      z-index: 2;
      &:focus,
      &:active {
        outline: none;
        background-color: #add7ff;

        .navbar-toggler-icon {
          transform: rotate(-90deg);
          transition: all 0.3s ease-out;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .navbar {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @media screen and (max-width: 700px) {
    .navbar {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .navbar-close-button.navbar-close-button {
      right: 2.2rem;
    }
  }

  @media screen and (max-width: 575px) {
    .navbar {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      .navbar-close-button {
        right: 1.6em;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .navbar {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .navbar-collapse {
        .custom-nav {
          a,
          button {
            &.special {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}

.dark-header {
  .navbar.scrolled {
    --bs-navbar-toggler-icon-bg: var(--dark-hamburger-icon);
  }
  .navbar {
    --bs-navbar-toggler-icon-bg: var(--white-hamburger-icon);
  }

  @media screen and (max-width: 1024px) {
    .navbar .navbar-collapse .custom-nav .nav-link.black {
      color: #6b6f70;
    }
  }
}

.header .contactUsBtn {
  border-radius: 6px;
  background-color: #3095f2;
  border: 2px solid #3095f2;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 0;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.8rem;
  max-width: 164px;
  min-width: 136px;
  max-height: 3.125rem;
  font-family: 'Avenir-Heavy-05', sans-serif !important;

  display: flex;
  align-items: center;
  justify-content: center;

  .nav-link.nav-link {
    color: #fff;
    padding: 0;
    margin: 0;
    &:hover {
      color: #fff;
    }
  }

  &.special-white {
    color: #ffffff;
    border: 2px solid transparent;
  }
  &:hover {
    background: #2273bd;
    border-color: #2273bd;
  }
  .active {
    color: #ffffff;
    font-family: 'Avenir-Heavy-05', sans-serif !important;
  }
  @media screen and (max-width: 1150px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 840px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

@media screen and (max-width: 1150px) {
  .nav-link .optional {
    display: none;
  }
}

.customDropDown {
  margin-left: -4rem;
  max-width: 90vw;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;

  .customDropDownItem.customDropDownItem {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    letter-spacing: 0 !important;
  }

  .customDropDownItem__Container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .Container__head {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--title-text-color);

    .textBlock__title {
      margin-left: 0.75rem;
      font-family: 'Avenir-Medium-09', sans-serif;
      font-size: 1.125rem;
      line-height: 140%;
    }

    &:hover {
      color: #439ff3;
    }

    img {
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.customDropDown.one-col-dropdown {
  margin-left: -5rem;
  min-width: 240px;
}
.customDropDown.navbar-services-dropdown {
  margin-left: -8rem;
  @media screen and (min-width: 1357px) {
    margin-left: -20rem;
  }
}

.customDropDown.navbar-company-dropdown {
  margin-left: -5rem;
}

.customDropDown .one-col-dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.customDropDown .few-col-dropdown {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;

  .few-col-dropdown-item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.active {
  opacity: 1;
  letter-spacing: -1px;
  color: #000000;

  &.special {
    opacity: 1;
    color: #fff;
  }

  .Container__head .textBlock__title {
    font-family: 'Avenir-Heavy-05', sans-serif;
  }

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 991px) {
  .header .navbar-collapse {
    .custom-nav {
      a,
      button {
        margin: 0;
        padding: 1rem;
        &.special {
          padding: 0.7rem 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 910px) {
  .header {
    padding-left: 4rem;
    padding-right: 4rem;
    .navbar-collapse {
      .custom-nav {
        a,
        button {
          font-size: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .header .navbar-collapse {
    .custom-nav {
      a,
      button {
        white-space: nowrap;
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 1356px) {
  .header {
    .navbar-collapse {
      .custom-nav {
        a,
        button {
          font-size: 1rem;
          margin: 0 0.3rem;
          padding: 0.5rem;
          line-height: 1.2;

          &.special {
            padding: 0.7rem 1rem;
          }
        }
      }
    }

    .customDropDown .few-col-dropdown {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;

      .few-col-dropdown-item {
        gap: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .navbar-collapse .customDropDown a.customDropDownItem {
      padding: 0.5rem 1rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header .navbar-close-button {
    display: block;
    background: none;
    border: none;
    position: absolute;
    right: 3.9rem;
    top: 2rem;
    z-index: 3;
  }

  .hamburger-menu-padding {
    height: 7.5rem;
    max-height: 15vh;
  }

  .hamburger-menu-container {
    display: flex;
    flex-direction: column;
    padding: 0 5rem 5rem;
    width: 800px;
    max-width: 80vw;
    height: 100vh;
    overflow: auto;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .header .socialLinks {
    display: flex;
    align-self: center;
    margin: 0 5rem;
    gap: 1.5rem;

    .linkBox {
      margin-right: 0;
    }

    .linkBox a {
      padding: 0;
    }

    .linkBox > a > img {
      height: 40px;
    }
  }

  .header .navbar {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .navbar-collapse {
      margin-right: 0;
    }

    .custom-nav {
      align-items: flex-start;
      gap: 2.5rem;
      width: 100%;

      a,
      .nav-link,
      button {
        color: #333;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin: 0;
        padding: 0;
        text-align: left;

        &.black.active {
          text-decoration: none;
          font-weight: initial;
          font-family: 'Avenir-Heavy-05', sans-serif;
        }

        &.special {
          color: #fff;
          width: 100%;
          margin-top: 2.5rem;
          background-color: transparent;
          color: #3095f2;
          border-radius: 0;
          border: none;
          padding: 0;

          &.special-white,
          &.special-black {
            color: #000;
            border: none;
          }
        }
      }

      .nav-item-special {
        display: flex;
        min-width: 205px;
      }
    }
  }

  .main-navbar {
    .navbar-toggler {
      display: block;
    }

    .collapse:not(.show) {
      display: none !important;
    }

    .navbar-collapse {
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      background: rgba(0, 0, 0, 0.3);
      z-index: 2;
      margin-right: 0;

      &.collapsing {
        height: unset !important;
      }

      &.entering {
        height: 100vh !important;

        .navbar-close-button {
          display: none;
        }
      }

      &.exiting {
        height: 100vh !important;
        background: none;

        .navbar-close-button {
          display: none;
        }
      }

      .hamburger-menu-container {
        transition: all 0.3s ease-in-out;
        margin-right: -100%;
      }

      &.entering .hamburger-menu-container {
        margin-right: 0%;
      }

      &.entered .hamburger-menu-container {
        margin-right: 0;
      }
    }

    .navbar-nav {
      flex-direction: column;
    }
  }

  .header .contactUsBtn {
    display: none;
  }

  .hamburger-active .nav-link {
    color: #3095f2 !important;
    font-weight: inherit;
    font-family: 'Avenir-Medium-09', sans-serif;
  }

  .hamburger-item .customDropDown {
    display: none;
  }

  .hamburger-current-page {
    .nav-link {
      font-family: 'Avenir-Heavy-05', sans-serif;
    }
  }

  .hamburger-submenu {
    .dropdown-toggle {
      display: none;
    }
    .customDropDown {
      position: fixed !important;
      right: 0;
      transform: none !important;
    }
  }

  .hamburger-menu-container-tablet {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }

  .nav-link svg {
    display: none;
  }

  .header .hamburger-submenu-tablet {
    display: block;
    padding-top: 2.5rem;

    .customDropDown {
      position: relative;
      padding: 2rem;
      border-radius: 0.5rem;
      background-color: #fafafa;
      box-shadow: none;
      max-width: 24rem;
      min-width: 35vw;

      .one-col-dropdown-content {
        display: flex;
        flex-direction: column;
      }

      a.customDropDownItem.customDropDownItem {
        padding: 0;
      }

      .few-col-dropdown,
      .few-col-dropdown-item,
      .one-col-dropdown-content {
        gap: 2rem;
      }
    }
  }

  .customDropDown .Container__head {
    align-items: flex-start;

    .textBlock__title {
      font-size: 1.125rem;
      font-weight: 500;
      font-family: 'Avenir-Medium-09', sans-serif;
      margin-left: 0.5rem;
      word-wrap: normal;
      text-wrap: pretty;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }

  .customDropDown .active .Container__head .textBlock__title {
    font-family: 'Avenir-Heavy-05', sans-serif;
  }
}

@media screen and (max-width: 768px) {
  .header {
    .hamburger-menu-container-tablet {
      flex-direction: column;
    }

    .hamburger-menu-container {
      max-width: 100vw;
      padding: 0 4.25rem 2.5rem 1.5rem;
    }

    .navbar-hamburger-brand.navbar-hamburger-brand {
      display: block;
      padding: 0;
      margin-top: 2rem;
      margin-left: 0;
    }

    .hamburger-menu-padding {
      height: 2.5rem;
      max-height: 5vh;
    }

    .hamburger-submenu-mobile {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }

    .hamburger-active .hamburger-submenu-mobile {
      max-height: 100vh;
    }

    .socialLinks {
      max-width: 90vw;
      gap: 0.35rem;
      margin: 0;
      margin-bottom: 3rem;
      flex-wrap: wrap;
      justify-content: center;

      .linkBox > a > img {
        height: 22px;
      }
    }

    .customDropDown .Container__head {
      img {
        margin-top: 0.5rem;
      }
    }

    .navbar {
      .custom-nav {
        gap: 1.5rem;
        width: 100%;

        a,
        .nav-link,
        button {
          color: #333;
          font-size: 1.5rem;
        }

        .nav-item-special {
          display: flex;
          min-width: 130px;
        }
      }

      .textBlock__title {
        padding-top: 0.22rem;
      }
    }

    .hamburger-submenu-tablet {
      display: none;
    }

    .hamburger-item:not(.hamburger-active) {
      .customDropDown {
        display: none;
      }
    }

    .hamburger-item {
      width: 100%;

      .nav-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .nav-link svg {
        display: block;
        transform: rotate(0deg);
        transition: all 0.5s ease-out;
        color: #3095f2;
      }

      .customDropDown {
        display: block;
        box-shadow: none;
        padding: 0;
        margin-top: 1.5rem;
        margin-left: 0;

        a.customDropDownItem {
          padding: 0;
        }
      }

      .few-col-dropdown,
      .few-col-dropdown-item,
      .one-col-dropdown-content {
        gap: 1.5rem;
      }
    }

    .hamburger-item:not(.hamburger-active) {
      .nav-link svg {
        transform: rotate(180deg);
        color: #333333;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .hamburger-menu-container {
      padding-right: 3rem;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    .hamburger-menu-container {
      padding-right: 2.2rem;
    }
  }
}

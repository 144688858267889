.multidisc-box {
  margin-top: -2.5vw;
  display: flex;
  font-family: "Avenir-Roman-12", sans-serif;
  .article-box {
    position: relative;
    width: 100%;
    .multidisc-box-article {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25vw;
      line-height: 189%;
      color: #7c7c87;
    }
    .article-margin-top {
      margin-top: 44px;
    }
    .multidisc-image {
      position: absolute;
      top: 0;
      right: 0;
      @media screen and (min-width:1800px) {
        width: 50%;
      }

      @media screen and (max-width: 1024px) {
        top: auto;
        right: auto;
        position: relative;
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 2560px){
    .article-box {
      .multidisc-box-article {
        font-size: 32px;
      }
    }
  }
  @media screen and (max-width:1024px) {
    .article-box {
      .multidisc-box-article {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width:575px) {
    br{
      display: none;
    }
  }
}

.meest-wrapper {
  .meest-product-main {
    font-family: 'Avenir-Roman-12', sans-serif;
    position: relative;
    padding: 10rem 7rem 0;
    margin-bottom: -10rem;
    color: #000000;
    background: #ffffff;
    text-align: center;
    border-bottom-left-radius: 10rem;

    .meest-description {
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 140%;
      text-align: center;
    }

    .meest-main-title {
      margin-bottom: 2rem;
    }

    .meest-main-row {
      align-items: center;
      justify-content: center;
    }

    .meest-download-btn {
      margin-right: 1rem;
    }

    .meest-main-col {
      margin-bottom: -15rem;
      &:nth-child(2) {
        margin-bottom: -25rem;
      }
      &:nth-child(4) {
        margin-bottom: -35rem;
      }

      img,
      .video {
        border-radius: 1.5rem;
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
      }
      img {
        width: 85%;
        height: 100%;
      }
      .video {
        max-width: 85%;
      }
    }
    @media screen and (min-width: 1800px) {
      .meest-download-btn {
        width: 12rem;
      }
      .meest-description {
        font-size: 30px;
        line-height: 150%;
      }
      .meest-main-title {
        width: 300px;
      }
      .meest-main-col {
        flex: 0 0 26%;
        max-width: 26%;
      }
    }
    @media screen and (max-width: 991px) {
      padding: 10rem 6rem;

      .meest-download-btn {
        width: 8rem;
      }
      .meest-main-col {
        padding-left: 8px;
        padding-right: 8px;

        .video {
          border-radius: 1rem;
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 7rem 4rem 0rem;
      .meest-main-col {
        margin-bottom: -5rem;
        &:nth-child(2) {
          margin-bottom: -10rem;
        }
        &:nth-child(4) {
          margin-bottom: -15rem;
        }
        .video {
          border-radius: 1rem;
        }
      }
    }
  }

  .meest-download-btn {
    margin-right: 1.69rem;
  }
}

p {
  margin: 0;
}

.block-header {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 140%;
  color: var(--title-text-color);
  margin-bottom: 0;

  span,
  b {
    color: #3095f2;
    @media screen and (max-width: 1100px) {
      & > br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    & > br {
      display: none;
    }
  }
}

h2.block-header {
  font-size: 32px;
  text-align: center;
  @media screen and (max-width: 910px) {
    font-size: 22px;
  }
}

h3.block-header {
  font-size: 22px;
  @media screen and (max-width: 910px) {
    font-size: 18px;
  }
}

h4.block-header {
  font-size: 20px;
  @media screen and (max-width: 910px) {
    font-size: 18px;
  }
}

.heavy24px {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 0;
  @media screen and (max-width: 910px) {
    font-size: 20px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 28px;
  }
}
.heavy20px {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  @media screen and (max-width: 910px) {
    font-size: 16px;
  }
}
.heavy16px {
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  @media screen and (max-width: 910px) {
    font-weight: 500;
    line-height: 140%;
  }
  @media screen and (min-width: 1800px) {
    font-size: 18px;
  }
}
.medium12px {
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  @media screen and (max-width: 910px) {
    font-size: 16px;
    line-height: 140%;
  }
  @media screen and (min-width: 1800px) {
    font-size: 16px;
  }
}
.medium24px {
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  @media screen and (max-width: 1300px) {
    & > br {
      display: none;
    }
  }
  @media screen and (max-width: 910px) {
    font-size: 16px;
    line-height: 26px;
  }
}
.medium52px {
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 60px;
  @media screen and (max-width: 910px) {
    font-size: 42px;
    line-height: 57px;
  }
}
.medium18px {
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  @media screen and (max-width: 910px) {
    font-size: 16px;
    // line-height: 26px;
  }
}

.medium16px {
  font-family: 'Avenir-Medium-09', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 910px) {
    & > br {
      display: none;
    }
  }
}
.mixed13px {
  font-family: 'Avenir-Roman-12', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  @media screen and (max-width: 910px) {
  }
}
.blue-uppercase {
  font-family: 'Avenir-Medium-09', sans-serif;
  color: #3095f2;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 1.2rem;
}

.big-ticket-wrapper {
  .product-description {
    font-family: 'Avenir-Roman-12', sans-serif;
    padding: 0;
    .margin {
      margin: 5rem;
    }
    .description {
      font-size: 20px;
      line-height: 140%;
    }

    .wireframes {
      background: #ee9329;
      padding: 5rem 0;
      margin: 0;
      justify-content: flex-end;
      position: relative;

      .big-ticket-screens {
        margin-bottom: -20rem;
      }

      .product-description-content {
        position: absolute;
        top: 10%;
        left: 7.5%;
        width: 32.7rem;
      }

      .big-ticket-product-col {
        padding: 0;

        &:first-child {
          margin-top: 20rem;
        }
        &:last-child {
          margin-top: 15rem;
        }

        .product-description-img-big-ticket {
          width: 100%;
        }
      }

      .description,
      .title {
        color: #ffffff;
      }
    }

    .title {
      color: var(--title-text-color);
      font-size: 38px;
      line-height: 50px;
      font-weight: inherit;
    }

    @media screen and (min-width: 1800px) {
      .margin {
        margin: 7rem 5rem;
      }
      .description {
        font-size: 1.7rem;
      }
      .wireframes {
        padding: 7rem 0;

        .product-description-content {
          width: 44.5rem;
        }

        .big-ticket-product-col {
          .product-description-img-big-ticket {
            width: 90%;
          }
        }
      }
      .title {
        font-size: 4rem;
      }
    }
    @media screen and (min-width: 2000px) {
      .description {
        font-size: 2rem;
      }

      .margin {
        margin: 10rem 5rem;
      }
      .wireframes {
        padding: 7rem 0;

        .product-description-content {
          width: 54rem;
        }

        .big-ticket-product-col {
          &:first-child {
            margin-top: 30rem;
          }
          &:last-child {
            margin-top: 25rem;
          }

          .product-description-img-big-ticket {
            width: 90%;
          }
        }
      }
      .title {
        font-size: 4rem;
      }
    }

    @media screen and (max-width: 1250px) {
      .wireframes {
        .big-ticket-screens {
          flex: 0 0 65%;
          max-width: 65%;
          margin-bottom: -15rem;
        }
        .big-ticket-product-col {
          &:first-child {
            margin-top: 15rem;
          }
          &:last-child {
            margin-top: 10rem;
          }
        }
      }
    }
    @media screen and (max-width: 1100px) {
      .wireframes {
        .big-ticket-screens {
          margin-bottom: -12rem;
        }
        .product-description-content {
          width: 25rem;

          .description {
            font-size: 0.95rem;
            line-height: 1.45rem;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .wireframes {
        padding: 3rem 0;

        .product-description-content {
          width: 21rem;
          .description {
            font-size: 0.8rem;
            line-height: 1.3rem;
          }
        }
      }
    }
    @media screen and (max-width: 700px) {
      .margin {
        margin: auto 3rem;
      }
      .wireframes {
        justify-content: center;

        .product-description-content {
          margin: 0 2rem;
          position: relative;
          width: auto;
          left: 0;
          top: 0;

          .description {
            font-size: 20px;
            line-height: 140%;
          }
        }
        .big-ticket-screens {
          flex: 0 0 95%;
          max-width: 95%;
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 575px) {
      margin: 0 auto;

      .margin {
        margin: auto 2rem;
      }

      .wireframes {
        .big-ticket-screens {
          .big-ticket-product-col {
            margin-top: 0 !important;

            &:first-child {
              margin-top: 8rem !important;
            }
          }
        }
        .product-description-content {
          .description {
            font-size: 1rem;
            line-height: 1.6rem;
          }
        }
      }

      .description {
        font-size: 1rem;
        line-height: 1.6rem;
      }
    }
  }
}

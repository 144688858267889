.mobile-startups {
  .sliders-wrapper {
    background: none;
    margin-bottom: 7.5rem;

    .image-slider-content-item {
      border-radius: 0.375rem;
    }
  }

  .tech-stack {
    margin-top: 3.75rem;
  }

  @media screen and (max-width: 768px) {
    .sliders-wrapper {
      margin-bottom: 5rem;
    }
  }
}

.shrink-wrapper {
  .product-result {
    font-family: 'Avenir-Roman-12', sans-serif;
    padding: 0;
    background: linear-gradient(236.12deg, #ff802b 0%, #ff541d 100%);

    .product-result-row {
      justify-content: center;
    }
    .product-result-title {
      font-family: 'Avenir-Light-07', sans-serif;
      font-weight: bold;
      font-size: 30px;
      color: #fff;
    }
    .product-result-row-btns {
      display: flex;
      justify-content: space-between;
      width: 23rem;
      gap: 1.5rem;

      .product-result-download-btn {
        width: 11rem;
      }
    }
    .product-result-description {
      font-size: 18px;
      line-height: 160%;
      color: #fff;
      margin-bottom: 3rem;
      font-family: 'Avenir-Light-07', sans-serif;
    }
    hr.hLine {
      border-top: 2px solid #e0e0e0;
    }

    @media screen and (min-width: 2300px) {
      .product-result-description {
        font-size: 2rem;
      }
      .product-result-title {
        font-size: 4rem;
      }
    }
    @media screen and (max-width: 575px) {
      .margin {
        margin: auto 2rem;
      }
    }
    @media screen and (max-width: 445px) {
      .product-result-row-btns {
        width: auto;
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: row;

        .product-result-download-btn {
          width: 9rem;
          max-width: 40vw;
        }
      }
    }
  }
}

.full-cycle-development {
  display: flex;
  flex-direction: column;
  // gap: 4rem;
}
.full-cycle-development__subtitle {
  margin-top: 1.5rem;
}

.full-cycle-development-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 4rem;
  row-gap: 2rem;
  margin-top: 4rem;
}

.full-cycle-development-item {
  background-color: #f1f7fc;
  font-family: 'Avenir-Heavy-05', sans-serif;
  font-style: normal;
  font-weight: inherit;
  font-size: 22px;
  padding: 2rem;
  color: #3095f2;
  flex: 1 1 40%;
  min-width: 20rem;

  display: flex;
  gap: 1.5rem;
  align-items: center;

  & > * {
    color: #3095f2;
  }
}
@media screen and (max-width: 575px) {
  .full-cycle-development-wrapper {
    margin-top: 2.5rem;
    gap: 12px;
  }
}

.achivments_container {
  background: #3095f2;
  padding: 50px 0;

  .achivments_content_box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .item_box {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      position: relative;
      &::after {
        content: "";
        width: 57px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        transform: rotate(90deg);
        position: absolute;
        top: 50%;
        right: -28px;
      }
      &:last-child::after {
        display: none;
      }
      .achivment_mobile_divider {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 100px 0;
    .achivments_content_box {
      grid-template-columns: repeat( 2, 1fr);
      gap: 32px;

      .item_box {
        &:nth-child(2):after {
          display: none;
        }
        // .achivment_mobile_divider {
        //   display: block;
        //   width: 57px;
        //   border: 1px solid rgba(255, 255, 255, 0.4);
        //   margin-top: 20px;
        // }

      }
    }
  }
  @media screen and (max-width: 575px) {
    padding: 100px 0;
    .achivments_content_box {
      grid-template-columns: repeat( 1, 1fr);
      gap: 32px;

      .item_box {
        &::after {
          display: none;
        }
        .achivment_mobile_divider {
          display: block;
          width: 57px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-top: 20px;
        }
        &:last-child {
          .achivment_mobile_divider {
            display: none;
          }
        }
      }
    }
  }
}

.item {
  width: 100%;
  min-height: 305px;
  position: relative;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 0;
  }
  img {
    margin-bottom: 34px;
    width: 96px;
    height: 96px;
    @media screen and (min-width: 2560px) {
      width: 171.5px;
      height: auto;
    }
  }
  h4 {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 16px;
    span {
      color: #3095f2;
    }
    @media screen and (min-width: 2500px) {
      font-size: 38.4px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
  }
  p {
    width: 325px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Avenir-Medium-09', sans-serif;
    color: var(--p-text-color);
    font-weight: 500;
    @media screen and (min-width: 2500px) {
      font-size: 25.6px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .item_readmore_btn {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #3095f2;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .hover-underline-animation-off {
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      background-color: #3095f2;
      transform-origin: bottom;
      transition: transform 0.25s ease-out;
      margin-top: -5px;
    }

    .hover-underline-animation-on {
      transform: scaleX(1);
      transform-origin: bottom;
      background-color: #3095f2;
      transform-origin: bottom;
      transition: transform 0.25s ease-out;
      width: 100%;
      margin-top: -5px;

      height: 1px;
    }

    @media screen and (max-width: 1050px) {
      display: none;
    }
  }
}

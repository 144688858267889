.react-custom-select__indicator-separator {
  display: none;
}
.select_form_group {
  .react-custom-select-container {
    font-family: "Avenir-Book-01", sans-serif;
    font-weight: 500;
    color: black;
    min-width: 109px;
    .react-custom-select__control {
      height: 50px;
      // border: none;
      // box-shadow: none;
      border-radius: 8px;
      &:focus {
        outline: none;
      }
      .react-custom-select__value-container {
        padding-left: 16px;
        .react-custom-select__single-value {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #97979d;
        }
      }
    }
    .react-custom-select__menu {
      min-width: 400px;

      .react-custom-select__menu-list {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 910px) {
    .react-custom-select-container {
      .react-custom-select__menu {
        min-width: 100%;
      }
    }
  }
}

.arrow_box {
  transform: rotate(90deg);
  margin-right: 16px;
}

.react-custom-select__placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #97979d !important;
  &::after {
    content: "*";
    color: red;
  }
}

.react-custom-select__value-container {
  .react-custom-select__single-value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: black !important;
  }
}

.error_box {
  font-size: 12px !important;
  color: red;
  font-size: 16px;
  line-height: 28px;
}

.commit-onboarding-slider {
  margin-bottom: 5rem;
  .commit-onboarding-title {
    margin: auto 6rem 5.7rem;
    font-family: 'Avenir-Roman-12';
    font-weight: inherit;
    font-size: 80px;
    color: #e7e7e7;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .commit-onboarding-slider-img {
    height: 35vw;
  }
  @media screen and (min-width: 1800px) {
    .commit-onboarding-title {
      margin: auto 9rem 5.7rem;
    }
  }
  @media screen and (min-width: 2000px) {
    .commit-onboarding-title {
      font-size: 120px;
    }
  }

  @media screen and (max-width: 911px) {
    .commit-onboarding-title {
      margin: auto 4rem 5.7rem;
    }
  }

  @media screen and (max-width: 768px) {
    .commit-onboarding-title {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 575px) {
    .commit-onboarding-title {
      margin: auto 1rem 4rem;
      font-size: 40px;
    }
    .commit-onboarding-margin {
      margin: 0;
    }
    .commit-onboarding-slider-img {
      height: unset !important;
      width: 90%;
    }
  }
}

.career {
  &
    > .justify-content-between
    > .positions
    > .max-width-container
    > .responsive-container {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
  & > div:first-child {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1800px) {
    margin: 10rem 9.5rem;
  }
  @media screen and (max-width: 1799px) {
    margin: 10rem 6rem;
  }
  @media screen and (max-width: 910px) {
    margin: 10rem 4rem;
  }
  @media screen and (max-width: 700px) {
    margin: 8rem 2.9rem;
  }
  @media screen and (max-width: 575px) {
    margin: 8rem 1.5rem;
  }
}

.form_container {
  background: linear-gradient(
    78.12deg,
    #f3f2ff 10.96%,
    #e8fffe 64.07%,
    #f5f6ff 104.31%
  );
  transform: rotate(-180deg);
  .form_content_box {
    display: flex;
    transform: rotate(-180deg);
    align-items: center;
    flex-direction: column;
    gap: 60px;

    .form_box {
      width: 100%;
      max-width: 630px;

      .form_inputs_box {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .form_user_info {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;

          .phone_box {
            display: grid;
            grid-template-columns: auto auto;
            gap: 24px;
          }
        }

        .submitting_text {
          color: #7c7c87;
          margin-left: 40px;
          & > a {
            color: #3095f2;
            text-decoration: underline;
          }
        }
      }

      .submitBtn_box {
        display: flex;
        justify-content: center;

        & > button {
          border-radius: 6px;
          background-color: #3095f2;
          border: 2px solid #3095f2;
          color: #fff;
          padding-top: 12px;
          padding-bottom: 12px;
          margin-right: 0;
          box-sizing: border-box;
          font-size: 18px;
          line-height: 1.8rem;
          min-width: 164px;
          font-family: 'Avenir-Heavy-05', sans-serif !important;
        }
      }
    }
  }

  @media screen and (max-width: 910px) {
    .form_content_box {
      .form_box {
        .form_inputs_box {
          .form_user_info {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}

.form_container-dark {
  background: #020220;

  p {
    color: var(--p-text-color);
  }

  p.submitting_text.mixed13px.submitting_text.mixed13px {
    color: var(--p-text-color);
    opacity: 0.6;
  }
}

.loading-icon {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.recaptcha-box {
  display: flex;
  justify-content: center;
}

.headContainer {
  position: relative;
  padding: 10rem 0 0;
  margin-bottom: 5rem;
  color: #000000;
  background: #ffffff;
  text-align: center;

  .headFadeUp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    .headlineBox {
      max-width: 518px;
      font-family: 'Avenir-Roman-12', sans-serif;
      font-size: 24px;
      color: #000000;
      font-weight: 400;
      font-style: normal;
      margin-top: 1.5rem;
    }

    .playStoreBox {
      margin-top: 2.5rem;
      max-width: 365px;
      flex-wrap: nowrap;
      justify-content: center;
    }
  }
  .fadeImagesBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imagesBox {
      margin-top: 5rem;
      & > .col {
        border-radius: 50px;
      }
      .imagesBox__images {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 6rem;
        padding-right: 6rem;
        gap: 30px;
        justify-content: space-between;
        & > div > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .aboutProjContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .aboutProjContainer_content {
      width: 1170px;
      display: flex;
      gap: 6rem;
      .aboutProjFade {
        .aboutProjFade_center {
          flex-direction: column;
          .theClientBox {
            margin-top: 6rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 1.5rem;
            & > span {
              font-family: 'Avenir-Heavy-05', sans-serif;
              color: #0a0a0a;
              font-style: normal;
              font-size: 32px;
              line-height: 140%;
            }

            .appDescBox {
              text-align: start;
              padding-left: 1.5rem;
              & > span {
                font-family: 'Avenir-Heavy-05', sans-serif;
                font-style: normal;
                font-size: 24px;
                line-height: 140%;
                color: #0a0a0a;
              }
              & > div {
                font-family: 'Avenir-Medium-09', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 140%;
                color: #0a0a0a;
                max-width: 370px;
              }
            }
          }

          .overviewBox {
            margin-top: 6rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 1.5rem;
            width: auto;
            & > span {
              font-family: 'Avenir-Heavy-05', sans-serif;
              color: #0a0a0a;
              font-style: normal;
              font-size: 32px;
              line-height: 140%;
            }
            .overviewBox_list {
              display: flex;
              flex-direction: column;
              gap: 0.1rem;
              text-align: start;
              & > span {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                font-family: 'Avenir-Roman-12', sans-serif;
                color: #0a0a0a;
                & > b {
                  font-family: 'Avenir-Heavy-05', sans-serif;
                }
              }
            }
          }

          .projectTeam {
            margin-top: 4rem;
            justify-content: start;
            display: flex;
            gap: 5rem;
            // padding-left: 0;
            .projectTeam__nameBox {
              display: flex;
              flex-direction: column;
              & > img {
                height: 56px;
              }
              & > span {
                font-family: 'Avenir-Heavy-05', sans-serif;
                font-style: normal;
                font-size: 16px;
                line-height: 128.91%;
                text-align: center;
                color: #0a0a0a;
              }
            }
          }

          .servicesBox {
            margin-top: 6rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 1.5rem;
            width: auto;
            & > span {
              font-family: 'Avenir-Heavy-05', sans-serif;
              color: #0a0a0a;
              font-style: normal;
              font-size: 32px;
              line-height: 140%;
            }
            .servicesBox_list {
              & > span {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                font-family: 'Avenir-Roman-12', sans-serif;
                color: #0a0a0a;
                & > b {
                  font-family: 'Avenir-Heavy-05', sans-serif;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .imagesBox {
    margin-left: 1rem !important;
    margin-right: 1rem !important;

    & > .col {
      padding: 0 !important;
      background-repeat: round;
    }
    .imagesBox__images {
      padding: 0 !important;
      margin: 2rem !important;
      height: fit-content !important;
      gap: 1rem !important;

      & > div {
        padding: 0 !important;
      }
      & > div > img {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
  }
  .aboutProjContainer_content {
    width: auto !important;
    align-items: center;
  }
}

@media screen and (max-width: 750px) {
  .headContainer {
    padding-top: 6rem !important;
    .headlineBox {
      font-size: 1rem !important;
      margin-top: 1rem !important;
      line-height: 140%;
    }
    .playStoreBox {
      margin-top: 1.5rem;
      max-width: 88vw;
      gap: 1rem;
      & > .col {
        padding: 0;
      }
      &.row-cols-2 > * {
        flex: 1 0 50%;
        max-width: 50%;
      }
    }

    .imagesBox {
      margin-top: 4rem !important;
      & > .col {
        border-radius: 15px !important;
      }
    }
    .aboutProjContainer {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
      width: auto;
      justify-content: flex-start !important;
      .aboutProjContainer_content {
        flex-direction: column;
        width: 100% !important;

        align-items: flex-start;
        gap: 0 !important;
        .aboutProjFade {
          width: 100%;
          .aboutProjFade_center {
            gap: 0;
            .theClientBox {
              margin-top: 4rem !important;
              & > span {
                font-size: 24px !important;
              }
              .appDescBox > span {
                font-size: 18px !important;
              }
              .appDescBox > div {
                font-size: 14px !important;
              }
            }
            .overviewBox {
              margin-top: 2.5rem !important;
              gap: 1rem !important;
              & > span {
                font-size: 24px !important;
              }
              & > .overviewBox_list > span {
                font-size: 1rem !important;
              }
            }
            .servicesBox {
              margin-top: 1.5rem !important;
              gap: 1rem !important;
              & > span {
                font-size: 24px !important;
              }
              & > .servicesBox_list > span {
                font-size: 1rem !important;
              }
            }
            .projectTeam {
              margin-top: 3rem !important;
              justify-content: center !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .headContainer
    .aboutProjContainer
    .aboutProjContainer_content
    .aboutProjFade
    .aboutProjFade_center
    .projectTeam {
    gap: 0 !important;
    justify-content: space-between !important;
  }
  .headContainer {
    .headFadeUp {
      .playStoreBox {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

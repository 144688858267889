.our-scope {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
}
.our-scope__title-box {
  margin-bottom: 4rem;
}
.our-scope-item {
  display: flex;
  gap: 2.5rem;
  flex-direction: row;
  line-height: 1.4;

  h3 {
    color: var(--title-text-color);
    font-size: 1.5rem;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-weight: inherit;
    line-height: 140%;
    margin-bottom: 1.5rem;
  }

  .our-scope-image {
    img {
      width: 565px;
      max-width: 565px;
      height: 100%;
      max-height: 60vh;
      object-fit: contain;
      border-radius: 20px;
    }
  }
  .show-more-text {
    font-family: 'Avenir-Roman-12', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: var(--p-text-color);

    h3 {
      color: var(--title-text-color);
    }
  }

  ul {
    list-style: none;
    padding-left: 10px;
    padding-top: 1rem;
  }

  li {
    display: flex;
    align-items: center;
    line-height: 1.2;
    padding-bottom: 1rem;
  }

  ul li::before {
    content: '\2022';
    color: #3095f2;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: -10px;
    font-size: 24px;
    line-height: 1;
  }

  &:nth-child(n + 2) {
    flex-direction: row-reverse;
  }
  &:first-child {
    ul {
      columns: 2;
    }
  }
}
.all-cases_box {
  margin-top: 3.75rem;
  display: flex;
  justify-content: center;
  .all-cases-btn {
    color: var(--title-text-color);
    font-size: 1.5rem;
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    line-height: 140%;
  }
}
@media screen and (max-width: 1300px) {
  .our-scope-item {
    &:first-child {
      ul {
        columns: 1;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .our-scope {
    gap: 2.5rem;
  }

  .our-scope-item {
    flex-direction: column;

    &:nth-child(n + 2) {
      flex-direction: column;
    }
    .our-scope-image {
      img {
        width: 100%;
      }
    }
  }
}

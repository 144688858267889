.ai-advantages {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    h2 {
      margin-bottom: 15px;
      span {
        color: var(--main--blue-color);
      }
    }

    p {
      max-width: 1100px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.5rem;

    &__item {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      justify-content: start;
      padding: 1rem 1.5rem;
      // max-width: 500px;

      p {
        text-align: start;
      }

      &__check-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        &__checkmark-svg {
          min-width: 24px;
          .checkmark-path {
            stroke-dasharray: 24;
            stroke-dashoffset: 24;
            animation: drawCheckmark 0.4s ease 0.5s forwards;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    gap: 2rem;
    &__list {
      grid-template-columns: 1fr;
      gap: 1.5rem;

      &__item {
        padding: 1rem 0;

        &__check-container {
          gap: 1rem;
        }
      }
    }
  }
}

// .navbar {
//   --bs-navbar-toggler-icon-bg: var(--white-hamburger-icon);
// }
// .scrolled.navbar {
//   --bs-navbar-toggler-icon-bg: var(--dark-theme-bg-color)!important;
// }

@keyframes drawCheckmark {
  0% {
    stroke-dashoffset: 24;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.platforms {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;

  &__title {
    color: var(--title-text-color);
  }

  &__services_items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
  }

  &__services_item {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 300px;

    & > img {
      max-width: 100%;
      height: auto;
    }

    &__text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &__title {
        color: var(--title-text-color);
        span {
          color: var(--main--blue-color);
        }
      }

      &__text {
        font-size: 14px;
        color: var(--medium-gray-color);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 4rem;

    &__title {
      text-align: center;
    }

    &__services_items {
      grid-template-columns: 1fr;
      justify-items: start;
      gap: 1.5rem;
    }
    &__services_item {
      padding: 1.5rem;
      text-align: start;
      max-width: 400px;
      align-items: start;

      & > img {
        width: 70px;
        height: 70px;
      }

      &__text_wrapper {
        br {
          display: none;
        }
      }
    }


  }
}

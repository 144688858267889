.achievements {
  .achievements-block-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10rem;
    // margin-top: 5rem;
    align-items: center;
    .achievements-block {
        display: flex;
          justify-content: center;
      & > img {
        width: 70%;
        filter: grayscale(100%) ;
       
        transition: 0.5s;
        &:hover{
          filter: grayscale(0) ;
          transition: 0.5s;
         
        }
      }
    }
  }
  @media screen and (min-width: 2000px) {
    .achievements-block-container {
      .achievements-block {
        & > img {
          width: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .achievements-block-container {
      gap: 4rem;
    }
    //     .achievements-block {
    //       & > img {
    //         width: 100%;
    //       }
    //     }
    //   }
  }
  @media screen and (max-width: 1024px) {
    .achievements-block-container {
        grid-template-columns: 1fr 1fr;
      .achievements-block {
          display: flex;
          justify-content: center;
        & > img {
          width: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .achievements-block-container {
        
      .achievements-block {
        & > img {
          width: 70%;
        }
      }
    }
  }
}

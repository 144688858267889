.form-error {
  // font-family: "Avenir", sans-serif;
  color: #D11F1F;
  font-size: 12px;
  margin-top: 0.3rem;
  position: absolute;
  // left: 1,1rem;

  @media screen and (max-width: 1000px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.7rem;
  }
  
  @media screen and (max-width: 320px) {
    margin-top: 0.1rem;
  }
}

.shrink-wrapper {
  .product-features-block {
    .product-features-block-row {
      &.margin {
        margin: 5rem 5rem;
        @media screen and (max-width: 575px) {
          margin: 0 calc(1rem - 15px);
          margin-bottom: 50px;
        }
        @media screen and (min-width: 1400px) {
          margin: 5rem auto;
          max-width: 1220px;
        }
        @media screen and (min-width: 2000px) {
          max-width: 80%;
        }
      }
      .product-features-block-col {
        .product-features-title {
          font-weight: inherit;
          font-size: 1.9rem;
          margin-bottom: 2rem;
          color: #5c5c5c;
        }
        .product-features-text {
          font-size: 1.1rem;
          line-height: 160%;
        }
        .product-features-img {
          width: 100%;
          @media screen and (min-width: 1400px) {
            width: 90%;
          }
        }
        @media screen and (min-width: 2000px) {
          .product-features-title {
            font-size: 4rem;
          }
          .product-features-text {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.our-values-container {
  background: linear-gradient(
    110deg,
    #f3f2ff 12.17%,
    #e8fffe 62.49%,
    #f5f6ff 103.31%
  );
  transform: rotate(-180deg) !important;
  width: 100%;

  .our-values {
    display: flex;
    transform: rotate(-180deg);
    .title-box {
      flex-basis: 50%;
    }
    .list-box {
      flex-basis: 50%;

      .list-item-box {
        .item-head {
          width: 100%;
          margin-bottom: 1.5rem;
          cursor: pointer;
          display: flex;
          border-bottom: 1px solid #c9d1e3;

          .list-item-number {
            font-family: 'Avenir-Black-03', sans-serif;
            font-size: 1.5vw;
            color: #7c7c87;
            line-height: 150%;
          }

          .list-item-mark {
            right: 0;
            position: absolute;
            color: #3095f2;
            font-size: 1.5vw;
          }
          .item-text-box {
            margin-bottom: 1.5rem;
            position: relative;
            padding-right: 2rem;
            margin-left: 2.5rem;
            width: 100%;
            .list-item-title {
              line-height: 150%;
            }
            .active {
              color: #3095f2;
            }
            & > p {
              margin-top: 1rem;
              font-size: 1.1vw;
              line-height: 150%;
              color: #7c7c87;
            }
            .hidden {
              width: 100%;
              -webkit-transition: max-height 0.4s;
              -moz-transition: max-height 0.4s;
              -ms-transition: max-height 0.4s;
              -o-transition: max-height 0.4s;
              transition: max-height 0.4s;
              max-height: 0;
              overflow: hidden;
              margin: 0;
            }
            .slide {
              margin: 1rem 0 0 0;
              transition: max-height 0.8s;
              max-height: 500px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 2560px) {
    .our-values {
      .list-box {
        .list-item-box {
          .item-head {
            .list-item-number {
              font-size: 38.4px;
            }
            .item-text-box {
              .list-item-title {
                font-size: 38.4px;
              }
              & > p {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .our-values {
      display: block;
      .list-box {
        .list-item-box {
          .item-head {
            .list-item-number {
              font-size: 16px;
            }
            .list-item-mark {
              font-size: 16px;
            }
            .item-text-box {
              margin-left: 1.4rem;
              .list-item-title {
                font-size: 16px;
              }
              & > p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.react-select__indicator-separator {
  display: none;
}
.react-select-container {
  font-family: 'Avenir-Book-01', sans-serif;
  font-weight: 500;
  color: black;
  min-width: 109px;
  .react-select__control {
    height: 50px;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    &:focus {
      outline: none;
    }
    .react-select__value-container {
      padding-left: 16px;
      .react-select__single-value {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #97979d;
      }
    }
  }
  .react-select__menu {
    min-width: 400px;

    .react-select__menu-list {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (max-width: 910px) {
    .react-select__menu {
      min-width: 300px;
    }
  }
}

.arrow_box {
  transform: rotate(90deg);
  margin-right: 16px;
}
.phone_option {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  font-size: 16px;
  padding: 8px;
  cursor: default;

  .phone_option_name_box {
    display: flex;
    gap: 8px;
  }

  &:hover {
    background: #f1f1f1;
  }
  .code_box {
    color: var(--footer-link-color);
  }
}

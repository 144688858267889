.work_projects__project {
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  // width: 370px;
  padding: 20px 0 0 0;
  &:hover {
    cursor: pointer;
  }

  .work_projects__project__head {
    padding: 0 26px 0 26px;
    display: flex;
    flex-direction: column;
    .project_name {
      font-family: 'Avenir-Book-01', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.15vw;
      line-height: 140%;
      color: #ffffff;
    }
    .project_text {
      margin-top: 10px;
      font-family: 'Avenir-Medium-09', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.1vw;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: #ffffff;
    }
    .project_tags {
      font-family: 'Avenir-Book-01', sans-serif;
      margin-top: 14px;
      font-style: normal;
      font-weight: 500;
      font-size: 0.8vw;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: #fff;
      opacity: 0.7;
      & > span {
        &::after {
          content: '/';
        }
      }
      & > span {
        &:last-child {
          &::after {
            content: '';
          }
        }
      }
    }
  }

  .project_imageBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    & > img {
      width: 100%;
    }
  }

  @media screen and (min-width: 2560px) {
    .work_projects__project__head {
      .project_name {
        font-size: 26px;
      }
      .project_text {
        font-size: 28px;
      }
      .project_tags {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .work_projects__project__head {
      .project_name {
        font-size: 16px;
      }
      .project_text {
        font-size: 16px;
      }
      .project_tags {
        font-size: 12px;
      }
    }
  }
}
.workProductCase {
  height: 100%;
}

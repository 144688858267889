.shrink-wrapper {
  .product-slider {
    margin: 5rem auto;
    .product-slider-img {
      height: 30vw;
      @media screen and (max-width: 575px) {
          height: unset;
          width: 90%;
      }
    }
  }
}
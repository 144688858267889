.BlogDropDownMenu {
  width: 595px !important;
  margin-left: -400px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.16) !important;
  border-radius: 6px !important;
  border: none !important;
  padding: 24px !important;

  .menuTagsBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -14px !important;
    // gap: 14px;
    & > a {
      margin: 14px 14px 0 0 !important;
      font-family: 'Avenir-Light-07', sans-serif !important;
      // margin: 0 !important;
      padding: 3px 8px !important;
      font-style: normal !important;
      text-transform: capitalize;
      background: #eaf4fe !important;
      border-radius: 2px !important;
      font-weight: 900 !important;
      font-size: 14px !important;
      color: #3095f2 !important;
      letter-spacing: 0.01em !important;
      line-height: 140% !important;
    }
  }

  .menuPostsBox {
    width: 100%;

    .menuPostsBox__head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > span {
        font-family: 'Avenir-Heavy-05', sans-serif !important;
        font-style: normal;
        font-size: 24px;
        line-height: 33px;
      }
      & > a {
        font-family: 'Avenir-Light-07', sans-serif !important;
        padding: 0 !important;
        margin: 0 !important;
        font-style: normal !important;
        text-transform: capitalize;
        border-radius: 2px !important;
        font-weight: 800 !important;
        font-size: 18px !important;
        color: #3095f2 !important;
        letter-spacing: 0.01em !important;
        line-height: 25px !important;
      }
    }
    .menuPostsBox__posts {
      .post {
        display: flex;
        margin-top: 24px;
        &:hover {
          cursor: pointer;
        }
        .post__imageBox {
          width: 30%;
          height: 109px;
          max-height: 109px;
          & > img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 6px;
          }
        }
        .post__infoBox {
          width: 70%;
          margin-left: 30px;
          .post__infoBox__tags {
            margin-bottom: 12px;
            display: flex;
            gap: 14px;

            & > span {
              font-family: 'Avenir-Light-07', sans-serif !important;
              margin: 0 !important;
              padding: 3px 8px !important;
              font-style: normal !important;
              text-transform: capitalize;
              background: #eaf4fe !important;
              border-radius: 2px !important;
              font-weight: 800 !important;
              font-size: 14px !important;
              color: #3095f2 !important;
              letter-spacing: 0.01em !important;
              line-height: 140% !important;
            }
          }
          .post__infoBox_title {
            font-family: 'Avenir-Heavy-05', sans-serif !important;
            margin-bottom: 12px;
            font-style: normal;
            font-size: 18px;
            line-height: 25.2px;
            color: var(--title-text-color);
          }

          .post__infoBox_timeBox {
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            color: var(--title-text-color);
            .dot {
              color: #3095f2;
              padding: 0 0.5vw;
            }
          }
        }
      }
    }
  }
}

.head-slider-block {
  padding-bottom: 3.5rem;

  .head-slider-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .head-slider-image img {
    max-height: 360px;
    width: 95%;
    max-width: 360px;
    border-radius: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .head-slider-block {
    .slick-track {
      margin-left: 0;
      gap: 0;
    }
    .slick-track img {
      margin: 0;
    }

    .slick-slide {
      margin: 0 1rem;
    }

    .slick-slide > div {
      width: 100%;
      height: 100%;
    }

    .lazyload-wrapper {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 765px) {
  .head-slider-block {
    padding-bottom: 1rem;

    .head-slider-image {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .head-slider-image img {
      width: 11.594rem;
      height: 9.75rem;
      border-radius: 12px;
      object-fit: contain;
    }
  }
}

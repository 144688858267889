.career-main-wrapper {
  font-family: 'Avenir-Light-07', sans-serif;
  color: var(--title-text-color);
  position: relative;
  margin-bottom: 8rem;

  .careers-mobile-bg {
    display: none;
  }

  .career-main-nav {
    font-family: 'Avenir-Medium-09', sans-serif;
    display: flex;
    padding: 0.3rem 0;
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;

    li {
      &:first-child {
        a {
          color: var(--title-text-color);
        }

        &::after {
          content: '|';
          padding: 0 0.5rem;
        }
      }
      &:last-child {
        color: #0085ff;
      }
    }
  }

  .career-main-title {
    font-family: 'Avenir-Heavy-05', sans-serif;
    font-style: normal;
    line-height: 140%;
    font-size: 4.3vw;
    padding: 0;
    & > span {
      color: #3095f2;
    }
  }

  .position-description {
    font-family: 'Avenir-Heavy-05', sans-serif;
    background: #ffffff;
    border: none;
    color: var(--title-text-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.2rem;
    line-height: 27px;
    font-weight: inherit;
    font-size: 16px;
  }
  @media screen and (min-width: 1800px) {
    .career-main-nav {
      font-size: 22px;
    }
    .position-description {
      font-size: 23px;
      line-height: 30px;
    }
  }
  @media screen and (min-width: 2000px) {
    .career-main-nav {
      font-size: 26px;
    }

    .position-description {
      font-size: 27px;
      line-height: 34px;
    }
  }
  @media screen and (max-width: 1350px) {
    .career-main-title {
      font-size: 52px;
      line-height: 70px;
    }
    .position-description {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media screen and (max-width: 1100px) {
    .career-main-title {
      font-size: 52px;
      line-height: 70px;
    }
    .position-description {
      font-size: 18px;
      line-height: 25px;
      padding: 0.5rem 1rem;
    }
  }
  @media screen and (max-width: 850px) {
    .career-main-title {
      font-size: 48px;
      line-height: 45px;
    }
  }
  @media screen and (max-width: 700px) {
    .career-main-title {
      font-size: 2.7rem;
      line-height: 3.7rem;
    }
    .position-description {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 4rem;
    margin-top: 3rem;
    .careers-mobile-bg {
      position: absolute;
      z-index: -1;
      width: 120%;
      left: -2rem;
      display: block;
    }
    .career-main-title {
      font-size: 32px;
      line-height: 42px;
    }
    .career-main-nav {
      display: none;
    }
  }
  @media screen and (max-width: 350px) {
    .position-description {
      font-size: 14px;
    }
  }
}
.d-flex {
  gap: 1.5rem;
  @media screen and (max-width: 700px) {
    gap: 1rem;
  }
  .mr-2 {
    margin-right: 8px;
  }
}

.aob-wrapper {
  .product-result {
    padding: 5rem;
    background: linear-gradient(247.81deg, #29A3C1 12.38%, #0D5472 85.16%);

    .product-result-row {
      justify-content: center;
    }
    .product-result-title {
      font-family: "Avenir-Heavy-05", sans-serif;
      font-weight: bold;
      font-size: 30px;
      color: #fff;
    }
    .product-result-description {
      font-family: "Avenir-Book-01", sans-serif;
      font-size: 18px;
      line-height: 160%;
      color: #fff;
      margin-bottom: 3rem;
    }
    hr.hLine {
      border-top: 2px solid #E0E0E0;
    }
    @media screen and (min-width: 1800px) {
      .product-result-description {
        font-size: 28px;
      }
      .product-result-title {
        font-size: 45px;
      }
    }
    @media screen and (min-width: 2000px) {
      padding: 8rem;
      .product-result-description {
        font-size: 2rem;
      }
      .product-result-title {
        font-size: 4rem;
      }
    }
    @media screen and (max-width: 575px) {
        padding: 5rem 0;
      .margin {
        margin: auto 1rem;
      }
    }
  }
}
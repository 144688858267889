.solutions-container {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  h2 {
    color: var(--white-text-color);
  }
}

.solutions-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;
  width: 100%;
}

.wrapper-dark .solutions-item .solutions-text {
  opacity: 0.8;
}

.solutions-item {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;

  .solutions-image {
    border-radius: 5px;
    border: 0.5px solid var(--dark-gray-color);
    padding: 2rem;

  }

  & > .solutions-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .solutions-text {
      color: var(--white-text-color);
      opacity: 0.8;
    }
  }

  h3 {
    color: var(--white-text-color);
    span {
      color: #3095f2;
    }
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .solutions-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 700px) {
  .solutions-wrapper .solutions-item > .solutions-image {
    padding: 0.5rem;
  }
}

.contactUs-title.contactUs-title {
  padding-bottom: 0;
}

.contactUs-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  background-size: 90vw;
  position: relative;
  color: #000000;
  .contactUs-bg-1 {
    position: absolute;
    z-index: 0;
  }
  .contactUs-bg {
    position: absolute;
    z-index: -1;
    right: 0;
    top: -10rem;
  }

  .contactUs-margin {
    display: flex;
    align-items: center;
    margin: auto 5rem 8rem;
    justify-content: space-between;
  }
  .contactUs-title {
    font-family: 'Avenir-Black-03', sans-serif;
    font-size: 4.3vw;
    line-height: 7rem;
    color: var(--title-text-color);
    & > span {
      color: #3095f2;
    }
  }
  .contactUs-text {
    font-family: 'Avenir-Roman-12', sans-serif;
    font-size: 1.2vw;
    margin-bottom: 2.3rem;
    line-height: 189%;
    color: #7c7c87;
    @media screen and (max-width: 460px) {
      br {
        display: none;
      }
    }
  }
  .contactUs-Form-adress-container {
    display: flex;
    .address-container {
      padding-left: 5.5rem;
      .address-box-office {
        display: grid;
        gap: 2.5rem;
        grid-template-columns: 1fr 1fr;
        flex-wrap: wrap;
        @media screen and (max-width: 1150px) {
          gap: 0;
          grid-template-columns: 1fr;
        }
      }
      .address-box {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;

        .address-box-title {
          font-family: 'Avenir-Black-03', sans-serif;
          color: var(--title-text-color);
          font-size: 1.375rem;
        }
        .address-box-text {
          white-space: nowrap;
          color: #7c7c87;
          line-height: 189%;
          font-size: 1.125rem;
        }
        .address-box-email {
          color: #3095f2;
          text-decoration: underline;
          font-size: 1.125rem;
        }
      }
    }
    .contact-form {
      width: 580px;

      .contact-form-margin {
        margin-top: 30px;

        position: relative;
      }
      .contact-form-textarea {
        margin: 30px 0;
      }
      .contact-form-input {
        background: #ffffff;
        border: none;
        box-shadow: none;
        padding: 0;
        color: #000000;
        text-indent: 5px;
        min-height: 50px;
        border-radius: 6px;
        padding-left: 14px;

        &::placeholder {
          font-family: 'Avenir-Roman-12', sans-serif;
          color: #97979d;
          font-size: 16px;
          // margin-left: 14px;
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }

    @media screen and (min-width: 1800px) {
      .address-container {
        .address-box {
          .address-box-title {
            font-size: 28px;
          }
          .address-box-text {
            font-size: 24px;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      .address-container {
        .address-box {
          .address-box-title {
            font-size: 22px;
          }
          .address-box-text {
            font-size: 18px;
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      .address-container {
        padding-left: 0;
      }
    }

    @media screen and (max-width: 575px) {
      .address-container {
        margin-top: 12px;
      }
      .contact-form {
        // width: auto;
        text-align: center;

        .contact-form-margin {
          margin-top: 18px;
        }

        .contact-btn {
          width: 100%;
        }
      }
    }

    & > .form_container {
      background: transparent;
      & > .max-width-container {
        max-width: 100% !important;
        & > .responsive-container {
          margin: 0;
        }
        & > .responsive-page-top_bottom-gap {
          padding: 0;
          & > .form_content_box {
            gap: 0;
            & > div > h2 {
              display: none;
            }
          }
        }
      }
    }
  }

  .column-contact-btn {
    padding: 0;
  }
  .contact-btn {
    font-size: 18px;
    color: #ffffff;
    background: #3095f2;
    border: none;
    border-radius: 6px;
    padding: 0.8rem 3.3rem;
    width: auto;

    .responseIcon {
      width: 25px;
      height: 25px;
    }

    &:hover {
      background: #1f7cd1;
    }

    &:focus {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
  .send-approved {
    margin: 0;
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #209b25;
  }
  .send-dismissed {
    margin: 0;
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #bd3232;
  }
  .contactUs-socials-list {
    margin-top: 10rem;
    list-style-type: none;
    line-height: 3rem;
  }

  @media screen and (min-width: 1800px) {
  }
  @media screen and (max-width: 1200px) {
    .contactUs-title {
      // font-size: 5rem;
      line-height: 5rem;
    }
    .contactUs-text {
      font-size: 14px;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 1000px) {
    .contactUs-bg-1 {
      display: none;
    }
  }
  @media screen and (max-width: 910px) {
    .contactUs-margin {
      margin: auto 2rem 0rem;
    }
  }
  @media screen and (max-width: 671px) {
  }
  @media screen and (max-width: 1024px) {
    .contactUs-title {
      // font-size: 4rem;
      line-height: 4rem;
    }
    .mobile-contactUs-bg {
      position: absolute;
      top: -9.5rem;
      right: 0;
    }
  }
  @media screen and (max-width: 575px) {
    .contactUs-big-opacity-text {
      width: 100%;
      left: -2rem;
      top: 0;
      font-size: 18rem;
      line-height: 16rem;
    }
    .contactUs-margin {
      margin: 0rem 0.5rem 4rem;
      text-align: left;
    }
    .mobile-contactUs-bg {
      position: absolute;
      top: -6rem;
      right: 0;
    }
    .contactUs-title {
      font-size: 30px;
      line-height: 3rem;
      margin-bottom: 1.5rem;
    }
    .contactUs-text {
      // font-size: 1.3rem;
      margin-bottom: 2.5rem;
      line-height: 171%;
    }
    .contact-form {
      // width: auto;
      text-align: center;

      .contact-form-margin {
        margin-top: 1.1rem;
      }

      .contact-btn {
        width: 100%;
      }
    }
  }
}

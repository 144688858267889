.meest-screens-wrapper {
  .meest-screens-container {
    font-family: 'Avenir-Roman-12', sans-serif;

    .meest-screens-row {
      padding: 8rem 5rem;
      padding-bottom: 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: 1.95rem;
        background-color: #ffffff;
        border: 2px solid #0061af;
        top: 9.7rem;
        border-radius: 50%;
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100px;
        height: 152px;
        left: 2.45rem;
        border-left: 2px dashed #0061af99;
        top: 0;
      }
    }

    .meest-screens-title {
      font-weight: inherit;
      font-size: 50px;
      line-height: 140%;
      color: #232832;
    }

    .meest-screens-text {
      font-size: 18px;
      line-height: 160%;
      color: #656875;
      margin-bottom: 3rem;
    }
    @media screen and (min-width: 1800px) {
      .meest-screens-row {
        padding: 8rem 8rem 5rem;

        &:before {
          left: 5.95rem;
        }
        &:after {
          left: 6.45rem;
        }
      }
      .meest-screens-title {
        font-size: 4rem;
      }

      .meest-screens-text {
        font-size: 2.1rem;
      }
    }
    @media screen and (max-width: 911px) {
      .meest-screens-row {
        &:before {
          left: 2.05rem;
        }
        &:after {
          left: 2.55rem;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .meest-screens-row {
        margin: 0;
        padding: 0;
        &:before,
        &:after {
          display: none;
        }
      }
      .meest-screens-title {
        font-size: 30px;
        line-height: 140%;
      }

      .meest-screens-text {
        font-size: 16px;
        line-height: 160%;
      }
    }
  }

  .meest-screens-images-wrapper {
    background: linear-gradient(
      163.59deg,
      rgba(196, 196, 196, 0) 12.18%,
      rgba(196, 196, 196, 0.24) 25.68%,
      rgba(237, 24, 68, 0.13) 48.79%,
      rgba(196, 196, 196, 0) 88.09%
    );
    height: 60vw;
    width: 130vw;
    overflow: hidden;

    .meest-screens-images {
      align-items: center;
      transform: translateX(32vw) translateY(-60vw) rotate(-20deg);

      .col-2 {
        animation: slide 30s linear infinite;

        img {
          margin-bottom: 1rem;
          // width: 100%;
          height: 35vw;
        }
      }
      .col-2:nth-child(2) {
        top: 10vw;
      }
      .col-2:nth-child(4) {
        top: 5vw;
      }
    }
  }

  @media screen and (max-width: 575px) {
    margin: 5rem 0 0;
    .meest-screens-images-wrapper {
      height: 100vw;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc((175vw + 5rem) * -1));
  }
}

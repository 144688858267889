.projects-completed {
  width: 100%;
  background-color: #3095f2;
  padding: 52px;

  .projects-completed-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
    color: #ffffff;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;
      min-width: 100px;
      position: relative;

      &:not(:last-child)::after {
        content: '';
        width: 1px;
        height: 57px;
        background-color: #ffffff66;
        position: absolute;
        right: 0;
      }
    }

    h4 {
      font-family: 'Avenir-Medium-09', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 52px;
      line-height: 60px;
    }

    .projects-completed-item__title {
      font-family: 'Avenir-Heavy-05', sans-serif;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }
    .projects-completed-item__subtitle_box {
      margin-top: 1rem;
    }
    .number_with_after_before_world span {
      padding-top: 1px;
    }
  }
}

.wrapper-dark .projects-completed {
  background-color: transparent;
}

@media screen and (max-width: 1024px) {
  .projects-completed {
    padding: 100px 30px;

    .projects-completed-container {
      gap: 40px 0;
      & > div {
        width: 50%;
        min-width: 0;
        padding: 0 20px;
        // margin-bottom: 40px;

        &::after {
          display: none;
        }

        &:nth-child(odd)::after {
          content: '';
          display: block;
          width: 1px;
          height: 57px;
          background-color: #ffffff66;
          position: absolute;
          right: 0;
        }
      }
      h4 {
        font-size: 42px;
        line-height: normal;
      }
      .projects-completed-item__title {
        font-size: 16px;
        line-height: 26px;
      }
      .projects-completed-item__subtitle_box {
        margin-top: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .projects-completed .projects-completed-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;

    & > div:nth-child(odd)::after,
    & > div:nth-child(2)::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2rem;
      width: 57px;
      height: 1px;
      right: 50%;
      transform: translateX(50%);
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .number_with_after_world {
    align-items: center;
    gap: 0px !important;
    flex-direction: column;
  }
  .number_with_separator_world {
    align-items: center;
  }
  .sm-2-columns .projects-completed-container {
    flex-direction: row;
  }
  .projects-completed-item__subtitle_box p br {
    display: none;
  }
  .projects-completed-container
    .projects-completed-item.projects-completed-item {
    width: 80vw;
    max-width: 350px;
  }
}

.meest-shipment-wrapper {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0 3.4rem;
  background: #0061af;
  color: #ffffff;
  border-bottom-right-radius: 10rem;

  .meest-shipment-row-wrapper {
    border-left: 2px dashed #ffffff;
    padding: 8rem 2.5rem;
    align-items: center;
    position: relative;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      left: -10px;
      background-color: #0061af;
      border: 2px solid #ffffff;
      top: 55.2%;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .meest-shipment-title {
    font-weight: inherit;
    font-size: 50px;
    line-height: 140%;
  }

  .meest-shipment-text {
    font-size: 18px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.8);
  }

  .meest-shipment-col {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .meest-shipment-row {
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (min-width: 1800px) {
    padding: 0 7.5rem;

    .meest-shipment-col {
      flex: 0 0 27%;
      max-width: 27%;
    }

    .meest-shipment-title {
      font-size: 4rem;
    }

    .meest-shipment-text {
      font-size: 2.1rem;
    }
  }
  @media screen and (max-width: 911px) {
    padding: 0 3.5rem;
  }
}

.product-features-block {
    .product-features-block-row {
        &.margin {
            margin: 0 5rem;
            @media screen and (max-width: 575px) {
                margin: 0 1rem;
            }
            @media screen and (min-width: 1400px) {
                margin: 0 auto;
                max-width: 1220px;
            }
            @media screen and (min-width: 2000px) {
                max-width: 80%;
            }
        }
        .product-features-block-col {            
            .product-features-title {
                font-family: "Avenir-Roman-12";
                font-size: 1.9rem;
                margin-bottom: 2rem;
            }
            .product-features-text {
                font-family: "Avenir-Roman-12";
                font-size: 1.1rem;
                line-height: 160%;
            }
            .product-features-img {
                width: 100%;
                @media screen and (min-width: 1400px) {
                    width: 90%;
                }
            }
            @media screen and (min-width: 2000px) {
                .product-features-title {
                    font-size: 4rem;
                }
                .product-features-text {
                    font-size: 2rem;
                }
            }
            @media screen and (max-width: 575px) {
                margin-bottom: 40px;
            }
        }
    }
}
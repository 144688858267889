.technicalDetailsContainer {
  margin-top: 8rem;

  .technicalDetails {
    display: flex;
    justify-content: center;
    .techicalDetails_content {
      width: 1170px;
      .techicalDetailsTitle {
        font-style: normal;
        font-size: 30px;
        line-height: 140%;
        font-family: 'Avenir-Heavy-05', sans-serif;
        color: #0a0a0a;
      }
      .technicalDetailsRow {
        margin-top: 2rem;
        gap: 6rem;
        display: flex;
        justify-content: space-between;
        .technicalDetailsCol {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 220%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          color: var(--title-text-color);
          font-family: 'Avenir-Book-01', sans-serif;
          & > div {
            line-height: 190%;
          }

          & > div > b {
            font-family: 'Avenir-Heavy-05', sans-serif;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .technicalDetailsContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 750px) {
  .technicalDetailsContainer {
    margin-top: 5rem;

    .technicalDetails {
      .techicalDetails_content {
        .techicalDetailsTitle {
          font-size: 24px;
          line-height: 140%;
        }
        .technicalDetailsRow {
          flex-direction: column;
          gap: 2.5rem;
          .technicalDetailsCol {
            font-size: 16px;
            line-height: 160%;
          }
        }
      }
    }
  }
}

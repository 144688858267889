.meest-wrapper {
  .product-description {
    font-family: 'Avenir-Roman-12', sans-serif;
    .description {
      font-size: 1.4rem;
    }

    .product-description-row {
      flex-direction: column;
      width: 60%;
      margin: 10rem auto 0;
      padding: 0 5rem 5rem;
      border-left: 2px dashed #0061af99;
      border-bottom: 2px dashed #0061af99;
      border-bottom-left-radius: 4rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: -10px;
        background-color: #ffffff;
        border: 2px solid #0061af;
        top: 0;
        border-radius: 50%;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: -10px;
        background-color: #ffffff;
        border: 2px solid #0061af;
        top: 46.3%;
        border-radius: 50%;
        z-index: 1;
      }

      .product-description-col-1 {
        margin-top: -2rem;
      }
      .product-description-col-2 {
        margin-top: 3rem;
      }
    }

    .title {
      font-weight: inherit;
      font-size: 50px;
      line-height: 140%;
      color: #232832;
    }

    @media screen and (min-width: 1800px) {
      .description {
        font-size: 2rem;
      }
      .wireframes {
        padding: 7rem 0;
      }
      .title {
        font-size: 4rem;
      }
      .product-description-row {
        .product-description-col-2 {
          margin-top: 6rem;
        }
      }
    }
    @media screen and (max-width: 1550px) {
      .description {
        font-size: 1.3rem;
      }
    }

    @media screen and (max-width: 1300px) {
      .product-description-row {
        padding: 0 0 4rem 4rem;
        width: 60%;
      }
    }

    @media screen and (max-width: 1080px) {
      .description {
        font-size: 1.1rem;
      }
    }

    @media screen and (max-width: 911px) {
      .product-description-row {
        width: 75%;
      }
    }

    @media screen and (max-width: 700px) {
      .product-description-row {
        padding: 0 0 3rem 3rem;
        width: 71%;
      }
    }

    @media screen and (max-width: 575px) {
      margin: 0 auto;
      .margin {
        margin: auto 2rem;
      }
      .product-description-row {
        padding: 0 2rem;
        width: 100%;
        margin: 5rem 0;
        border: none;

        &:before,
        &:after {
          display: none;
        }
      }
      .title {
        font-size: 30px;
        line-height: 140%;
      }

      .description {
        font-size: 16px;
        line-height: 160%;
      }
    }
  }
}

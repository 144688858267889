.breadcrumbs_box {
  padding: 0;
  display: flex;
  font-family: 'Avenir-Medium-09', sans-serif;
}
.nav_box {
  display: flex;
  align-items: center;
  margin-bottom: -2rem;
}
.link {
  padding: 0;
  //   margin-right: 21px;
  text-transform: capitalize;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 34px;
}

.separator_box {
  margin: 0 8px 0 8px;
  & > svg {
    vertical-align: unset;
  }
}
@media screen and (max-width: 700px) {
  .link {
    font-size: 12px;
    line-height: 1.2;
  }
  .separator_box {
    & > svg {
      width: 4px;
      height: 10px;
    }
  }
}

.notFound_box {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    78.12deg,
    #f3f2ff 10.96%,
    #e8fffe 64.07%,
    #f5f6ff 104.31%
  );
  transform: rotate(-180deg);

  .notFound_content {
    height: 100vh;
    transform: rotate(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text404 {
      font-family: 'Avenir-Black-03', sans-serif;
      font-weight: inherit;
      font-size: 160px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #3095f2;
    }
    .text {
      max-width: 570px;
      font-family: 'Avenir-Medium-09', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      color: #7c7c87;
      margin-bottom: 2rem;
    }

    & > a {
      font-style: normal;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      background: #3095f2;
      border-radius: 6px;
      font-family: 'Avenir-Heavy-05', sans-serif;
      padding: 13px 35px;
    }
  }

  @media screen and (max-width: 570px) {
    padding: 0 15px;
    .notFound_content {
      .text {
        line-height: 25px;
        font-size: 20px;
      }
    }
  }
}

.product-main {
  font-family: 'Avenir-Roman-12', sans-serif;
  position: relative;
  margin-top: -3rem;
  padding: 15rem 0;
  color: #000000;
  background: linear-gradient(249.2deg, #c064f5 0%, #449beb 99.52%);

  .product-main-row {
    justify-content: center;
  }

  .product-main-bg {
    position: absolute;
    left: 1rem;
    top: 10rem;
  }
  .description {
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }

  .title {
    text-align: center;
    color: #ffffff;
    font-family: 'Product Sans Regular', sans-serif;
    font-weight: bold;
    font-size: 6rem;
    line-height: 6rem;
    margin-bottom: 2rem;
  }

  .platforms {
    justify-content: center;
    margin-top: 5rem;
    & > a {
      width: auto;
    }

    &.row {
      flex-wrap: nowrap;
      gap: 1rem;
    }
  }

  .product-result-download-btn {
    margin-right: 0;
  }

  .product-result-download-btn {
    margin-right: 1rem;
  }

  .product-result-download-btn {
    margin-right: 1rem;
  }

  @media screen and (min-width: 2000px) {
    padding: 25rem 0;
    .description {
      font-size: 2rem;
    }
    .product-result-download-btn {
      width: 17rem;
    }

    .title {
      font-size: 10rem;
      line-height: 9rem;
    }
  }
  @media screen and (max-width: 1440px) {
    .title {
      font-size: 5rem;
      line-height: 5rem;
    }
  }

  @media screen and (max-width: 650px) {
    .description {
      font-size: 1.2rem;
    }

    .title {
      font-size: 4rem;
      line-height: 3rem;
    }
  }
  @media screen and (max-width: 575px) {
    margin-top: 0;
    padding: 10rem 0;

    .product-result-download-btn {
      width: 10rem;
    }

    .product-main-bg {
      left: 0;
      top: 0;
    }

    .title {
      font-size: 66px;
      line-height: 80px;
    }

    .description {
      font-size: 15px;
      line-height: 140%;
    }
  }
  @media screen and (max-width: 475px) {
    padding: 8rem 0;
    .product-result-download-btn {
      width: 8.5rem;
    }
  }
  @media screen and (max-width: 350px) {
    .product-result-download-btn {
      width: 7.8rem;
    }
  }
  @media screen and (max-width: 325px) {
    .product-result-download-btn {
      width: 7.5rem;
    }
  }
}

.aob-wrapper {
  .product-features-block-aob {
    font-family: 'Avenir-Book-01', sans-serif;
    .product-features-block-row {
      &.margin {
        margin: 4.2rem;
        @media screen and (max-width: 575px) {
          margin: 0 calc(1rem - 15px);
          margin-bottom: 50px;
        }
        @media screen and (min-width: 1800px) {
          margin: 10rem 8rem;
        }
        @media screen and (max-width: 911px) {
          margin: 5rem 2.2rem;
        }
        @media screen and (max-width: 700px) {
          margin: 5rem 0.2rem;
        }
        @media screen and (max-width: 575px) {
          margin: 5rem 0;
        }
      }
      .product-features-block-col {
        .product-features-title {
          font-family: 'Avenir-Heavy-05', sans-serif;
          font-weight: inherit;
          font-size: 30px;
          line-height: 140%;
          margin-bottom: 2rem;
          color: var(--title-text-color);
        }
        .product-features-text {
          font-size: 18px;
          line-height: 160%;
          color: var(--title-text-color);
        }
        .product-features-img-aob {
          @media screen and (min-width: 1400px) {
            width: 75%;
          }
          @media screen and (max-width: 1000px) {
            width: 90%;
          }
          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
        @media screen and (min-width: 1800px) {
          .product-features-title {
            font-size: 45px;
          }
          .product-features-text {
            font-size: 28px;
          }
        }
        @media screen and (min-width: 2000px) {
          .product-features-title {
            font-size: 4rem;
          }
          .product-features-text {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.commit-main {
  font-family: 'Avenir-Roman-12', sans-serif;
  padding: 0;
  margin-top: -7rem;
  background: url('https://perpetiostorage1.b-cdn.net/resources/images/commit/commit-main-background.png'),
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  .commit-main-row {
    margin: 0 10rem;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .commit-main-col {
      width: fit-content;
      text-align: center;

      .commit-main-title {
        width: 100%;
        margin-top: 13rem;
      }
      .commit-main-text {
        margin-top: 1rem;
        text-align: right;
        font-size: 25px;
        line-height: 34px;
      }
      .commit-main-phone {
        margin-top: -11rem;
        width: 60%;
      }
      .commit-main-description {
        text-align: start;
        width: 20rem;
        margin-top: -7rem;
        font-weight: inherit;
        font-size: 18px;
        line-height: 191.24%;

        .green {
          color: #478f39;
        }
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .commit-main-row {
      .commit-main-col {
        .commit-main-title {
          margin-top: 20rem;
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 2200px) {
    .commit-main-row {
      .commit-main-col {
        .commit-main-title {
          margin-top: 25rem;
          width: 100rem;
        }
        .commit-main-phone {
          margin-top: -15rem;
        }
        .commit-main-description {
          width: 35rem;
          font-size: 22px;
          margin-top: -10rem;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .commit-main-row {
      margin: 0 5rem;
      .commit-main-col {
        .commit-main-description {
          font-size: 14px;
          width: 21rem;
        }
        .commit-main-text {
          margin-top: 0.5rem;
          font-size: 18px;
        }
        .commit-main-phone {
          margin-top: -9rem;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .commit-main-row {
      .commit-main-col {
        .commit-main-description {
          font-size: 13px;
          width: 20rem;
          margin-top: -5rem;
        }
        .commit-main-text {
          margin-top: 0.5rem;
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 911px) {
    .commit-main-row {
      margin: 0 3rem;
    }
  }

  @media screen and (max-width: 771px) {
    background: url('https://perpetiostorage1.b-cdn.net/resources/images/commit/mobile-commit-main-background.png'),
      linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .commit-main-row {
      .commit-main-col {
        .commit-main-title {
          width: 100%;
        }
        .commit-main-phone {
          margin-top: 0rem;
          width: 100%;
        }
        .commit-main-text {
          font-size: 16px;
          line-height: 22px;
        }

        .commit-main-description {
          width: 100%;
          font-size: 20px;
          margin-top: 3rem;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .commit-main-row {
      margin: 0 1rem;
    }
  }

  @media screen and (max-width: 375px) {
    .commit-main-row {
      .commit-main-col {
        .commit-main-description {
          font-size: 14px;
        }
      }
    }
  }
}

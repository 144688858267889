
.loading-wrapper {
   display: flex;
   width: 100%;
   flex-direction: column;
   height: 100%;
}

.column {
  width: 100%;
}

.column.two {
 height: 70%;
}
.column.one {
  display: flex;
  flex-direction: column;
}

.column.one .image {
  // margin-bottom: 20px;
}

.column.two .image {
  height: 100%;
  width: 100%;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

.image {
  height: 100px;
  width: 100px;
  @extend .animated-background;
}

.text {
  margin-bottom: 20px;
}

.text-line {
  height: 15px;
  width: 100%;
  margin: 10px 0;
  @extend .animated-background;
}

@media screen and (max-width: 991px) {
    
}
.commit-description {
  margin-top: 10rem;
  font-family: 'Avenir-Roman-12', sans-serif;
  display: flex;
  flex-direction: column;

  .commit-description-row {
    justify-content: center;
    margin: 0;
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .commit-description-icon {
      align-self: start;
      max-width: 5rem;
      padding-left: 0;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
    }

    .commit-description-col {
      .commit-description-title {
        color: #478f39;
        font-weight: inherit;
        font-size: 30px;
        line-height: 140%;
        margin-bottom: 0.5rem;
      }

      .commit-description-text {
        color: var(--title-text-color);
        font-size: 18px;
        line-height: 160%;
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .commit-description-row {
      .commit-description-icon {
        height: 7rem;
      }
      .commit-description-col {
        .commit-description-title {
          font-size: 36px;
          line-height: 140%;
        }

        .commit-description-text {
          font-size: 24px;
          line-height: 160%;
        }
      }
    }
  }
  @media screen and (max-width: 771px) {
    .commit-description-row {
      margin: 0 3rem;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 4rem;
    .commit-description-row {
      margin: 0 2rem;
      margin-bottom: 4rem;

      .commit-description-col {
        .commit-description-text {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .commit-description-row {
      margin: 0 1rem;
    }
  }
}
